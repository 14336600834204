import { RECEIVED_TRADESDASHBOARD_DATA } from "constants/action-types";

import { LOADING_TRADESDASHBOARD_DATA } from "constants/action-types";

const initialState = {
  trades: {portfolios: {56: [{incorrect_hours: [], missing_hours: [], time: 946684800}],
    82: [{incorrect_hours: [], missing_hours: [], time: 946684800}],
    95: [{incorrect_hours: [], missing_hours: [], time: 946684800}]}
  },
  loading: true,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_TRADESDASHBOARD_DATA) {
    return {
      ...state,
      ...initialState,
    };
  } else if (action.type === RECEIVED_TRADESDASHBOARD_DATA) {
    return {
      ...state,
      trades: action.dashboard,
      loading: false,
    };
  }

  return state;
};
