import { RECEIVED_MARKET_SCORE } from "constants/action-types";

import { LOADING_MARKET_SCORE } from "constants/action-types";


const initialState = {
  scores: [
    {
      count: 0,
      delay: 0,
      var: 1,
    },
    {
      count: 25,
      delay: 50,
      var: 2,
    },
  ],
  loading: true,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_MARKET_SCORE) {
    return { ...state, ...initialState };
  } else if (action.type === RECEIVED_MARKET_SCORE){
    return {
      ...state,
      scores: action.dashboard,
      loading: false,
    };
  }

  return state;
};
