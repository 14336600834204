import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Info from "components/Typography/Info.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

const style = {
  typo: {
    paddingLeft: "28%",
    marginBottom: "40px",
    position: "relative",
    textAlign: "left"
  },
  warnings: {
    paddingLeft: "0%",
    marginBottom: "40px",
    position: "relative",
    textAlign: "left"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "25%"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
function JobLog(props) {
  const { classes } = props;
  if (props.location.state.data === undefined) {
    return( <div /> );
  } else {
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Job Log</h4>
        <p className={classes.cardCategoryWhite}>
          Last Run: 2018-12-25
        </p>
      </CardHeader>
      <CardBody>
        {props.location.state.data.map( (log, index) => {
          var data = log.indexOf('-', 0);
          if(data > -1){
            data = log.indexOf('-', data + 2)
            data = log.indexOf('-', data + 2)
            data = log.indexOf('-', data + 2)
            data = log.indexOf('-', data + 2)
          } else{
            data = -1
          }

          if (log.indexOf('INFO') !== -1) {
            return(
              <div className={classes.typo} key={'log_data' + index}>
                <div className={classes.note}>{log.substring(0, data - 1)}</div>
                <Info>
                  {log.substring(data+1)}
                </Info>
              </div>
              )
          } else if (data === -1 || props.location.state.type === 'warnings'  || props.location.state.type === 'error') {
            return (
              <div className={classes.warnings} key={'log_data' + index}>
                <Danger>
                  {log}
                </Danger>
              </div>
              )
          } else {
            return (
              <div className={classes.typo} key={'log_data' + index}>
                <div className={classes.note}>{log.substring(0, data - 1)}</div>
                <Danger>
                  {log.substring(data+1)}
                </Danger>
              </div>
              )            
          }
        })}
      </CardBody>
    </Card>
  );};
}

export default withStyles(style)(JobLog);
