import { useState, useMemo } from 'react';

import localForage from 'localforage';
import moment from 'moment';

import { buildAPIRequest, security_fetch_params } from 'actions/index';
import { DATE_FORMAT } from 'constants/general';
import {logout} from 'utils/auth';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

const hoursDay = 24;
const minArr = ['00', '15', '30', '45'];
const minToArr = ['15', '30', '45', '00'];
const auctionCountAnHour = minArr.length;

export const useFrrData = (dateFrom, dateTo, address, apiLabel) => {
  const [loading, setLoading] = useState(false);
  const [afrrData, setAfrrData] = useState([]);

  const prodLabelArr = useMemo(() => {
    const selectArr = [];
    for (let i = 0; i < hoursDay; i++) {
      for (let j = 0; j < auctionCountAnHour; j++) {
        const iStringFrom = i.toString().length === 1 ? '0' + i : i;
        const jStringFrom = minArr[j];

        //because we need 00:45-01:00, not 00:45-00:00
        let iStringTo;
        if (j !== auctionCountAnHour - 1)
          iStringTo = i.toString().length === 1 ? '0' + i : i;
        else {
          const indTo = i + 1;
          iStringTo = indTo.toString().length === 1 ? '0' + indTo : indTo;
        }
        const jStringTo = minToArr[j];
        selectArr.push(
          `- ${iStringFrom}:${jStringFrom}-${iStringTo}:${jStringTo}`
        );
      }
    }
    return selectArr;
  }, []);

  const updateAfrrData = async (date) => {
    const deliveryTime = [];

    for (let k = 0; k < date.length; k++) {
      for (let i = 0; i < hoursDay; i++) {
        for (let j = 0; j < 4; j++) {
          const hourString = i.toString().length === 1 ? `0${i}` : i;
          deliveryTime.push(`${date[k]}${hourString}${minArr[j]}`);
        }
      }
    }

    // Make request for aFrr data for each auction
    async function downloadAfrrDataPart(dateTime, i) {
      const [url, headers] = buildAPIRequest(
        `/api/markets/curve_data/19/${address}/${dateTime}`
      );
      const response = await fetch(url, { method: 'GET', headers, ...security_fetch_params });
      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        const msg = `An error has occurred: ${response.status}`;
        alertify.error(msg, 5);
        return;
      }
      const data = await response.json();
      if(data.error) {
        alertify.error("Response error");
        console.log(data.error);
        return
      }
      if (!data?.data?.length) {
        const msg = `No data for ${dateTime}`;
        alertify.error(msg, 5);
        console.log(msg);
        return;
      }
      //change format of day
      const day = `${dateTime.substring(6, 8)}.${dateTime.substring(
        4,
        6
      )}.${dateTime.substring(0, 4)}`;
      const dateTimeHour = dateTime.substring(8, 10);
      const dateTimeMin = dateTime.substring(10, 12);
      const indProd =
        dateTimeHour * auctionCountAnHour +
        dateTimeMin / (60 / auctionCountAnHour) +
        1;

      //prepare needed format of data
      const rez =
        data.data &&
        data.data.map((el) => {
          const prodName =
            el[0].substring(2, 3) === 'N'
              ? `NEG_${indProd} ${prodLabelArr[indProd - 1]}`
              : `POS_${indProd} ${prodLabelArr[indProd - 1]}`;
          return [day, prodName, el[1], el[2]];
        });
      return rez;
    }

    // Make request and save aFrr data
    async function storeAfrrData(dateTime, data) {
      if(data){
        data.updated_at = moment().unix();
        // Save data for future usage
        await localForage.setItem(`${address}frr_data_${dateTime}_${apiLabel}`, data, (err) => {
          err && console.log(err);
        });
      }
      return data;
    }

    // Request or restore from cache aFrr data for each auction
    async function downloadOrRestoreAfrrData(dateTime) {
      try {
        const cachedData = await localForage.getItem(`${address}frr_data_${dateTime}_${apiLabel}`);
        // If data was previously loaded in the last hour (in milliseconds)
        const INTERVAL = 3600;
        if (!cachedData || cachedData?.updated_at < moment().unix() - INTERVAL) {
          const data = await downloadAfrrDataPart(dateTime);
          await storeAfrrData(dateTime, data);
          return data;
        }
        return cachedData;
      } catch (err) {
        console.log(err);
      }
    }

    alertify.success(`Start loading ${address}Frr data`, 5);

    const totalData = await Promise.all(
      deliveryTime.map((dateTime) => downloadOrRestoreAfrrData(dateTime))
    );
    const totData = [];
    totalData.forEach((el) => {
      el && totData.push(...el);
    });

    //Prepare object of dates of afrr data
    const objAfrr = {};
    totData.forEach((el) => {
      const keyDate = el[0];
      const keyProduct = el[1];
      const price = el[2];
      const vol = el[3];

      if (!objAfrr.hasOwnProperty(keyDate)) {
        if (!objAfrr[keyDate]?.hasOwnProperty(keyProduct)) {
          objAfrr[keyDate] = {};
          objAfrr[keyDate][keyProduct] = [[price, vol]];
        } else {
          objAfrr[keyDate][keyProduct].push([price, vol]);
        }
      } else {
        if (!objAfrr[keyDate].hasOwnProperty(keyProduct)) {
          objAfrr[keyDate][keyProduct] = [[price, vol]];
        } else {
          objAfrr[keyDate][keyProduct].push([price, vol]);
        }
      }
    });

    //Sort object and calculate ACCUMULATED ALLOCATED CAPACITY
    const dates = Object.keys(objAfrr);
    dates.forEach((date) => {
      Object.keys(objAfrr[date]).forEach((prod) => {
        objAfrr[date][prod].sort((a, b) => a[0] - b[0]);
      });
    });
    dates.forEach((date) => {
      Object.keys(objAfrr[date]).forEach((prod) => {
        let exSum = 0;
        objAfrr[date][prod].forEach((ar, i) => {
          if (i) {
            const val = ar[1] + exSum;
            ar.push(val);
            exSum = val;
          } else {
            ar.push(ar[1]);
            exSum = ar[1];
          }
        });
      });
    });

    if (dates.length) {
      //date and product name last loaded
      const lastLoadedData =
      dates[dates.length - 1] +
      ' ' +
      Object.keys(objAfrr[dates[dates.length - 1]])[
        Object.keys(objAfrr[dates[dates.length - 1]]).length - 1
      ];

      //Prepare fill of select dates
      alertify.success(`Finished. Last loading data ${lastLoadedData}`, 10);
      setLoading(false);
      setAfrrData(objAfrr);
    }
  };

  const getAfrrData = () => {
    setLoading(true);
    const dayBetween = moment(dateTo.format('DD.MM.YYYY'),'DD.MM.YYYY')
      .diff(moment(dateFrom.format('DD.MM.YYYY'),'DD.MM.YYYY'), 'days');
    const dateArr = [];
    for (let i = 0; i < dayBetween + 1; i++) {
      const day = dateFrom.clone().add(`+${i}`, 'days').format(DATE_FORMAT);
      dateArr.push(day);
    }
    
    updateAfrrData(dateArr);
  };

  return [loading, afrrData, prodLabelArr, getAfrrData];
};
