const HighchartsExportVisiblePeriod = (Highcharts) => {
  Highcharts.wrap(
    Highcharts.Chart.prototype,
    'getDataRows',
    // Don't use arrow function here. The context is needed.
    function(proceed, multiLevelHeaders) {
      let rows = proceed.call(this, multiLevelHeaders);
      const xMin = this.xAxis[0].min;
      const xMax = this.xAxis[0].max;

      return rows.filter((row) => (
        // Include the first row as it doesn't contain 'x' value,
        // but contains only table header with labels
        !Object.keys(row).includes('x') || (row.x >= xMin && row.x <= xMax)
      ));
  });
};

export default HighchartsExportVisiblePeriod;
