import React, { useState } from "react";
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { IconButton } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx";
import Checkbox from '@mui/material/Checkbox';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import Flag from 'react-world-flags';

import europe from 'assets/img/countries/Europe.png'
import norway from 'assets/img/countries/Norway.png';
import belgium from 'assets/img/countries/Belgium.png';
import france from 'assets/img/countries/France.png';
import germany from 'assets/img/countries/Germany.png';
import austria from 'assets/img/countries/Austria.png';
import united_kingdom from 'assets/img/countries/United_Kingdom.png';
import netherlands from 'assets/img/countries/Netherlands.png';
import poland from 'assets/img/countries/Poland.png';
import italy from 'assets/img/countries/Italy.png';
import romania from 'assets/img/countries/Romania.png';
import bulgaria from 'assets/img/countries/Bulgaria.png';
import greece from 'assets/img/countries/Greece.png';
import portugal from 'assets/img/countries/Portugal.png';
import slovenia from 'assets/img/countries/Slovenia.png';
import spain from 'assets/img/countries/Spain.png';
import croatia from 'assets/img/countries/Croatia.png';
import hungary from 'assets/img/countries/Hungary.png';
import slovakia from 'assets/img/countries/Slovakia.png';
import czechia from 'assets/img/countries/Czechia.png';
import serbia from 'assets/img/countries/Serbia.png';
import denmark from 'assets/img/countries/Denmark.png';
import switzerland from 'assets/img/countries/Switzerland.png';
import turkey from 'assets/img/countries/Turkey.png';
import estonia from 'assets/img/countries/Estonia.png';
import lithuania from 'assets/img/countries/Lithuania.png';
import latvia from 'assets/img/countries/Latvia.png';
import finland from 'assets/img/countries/Finland.png';
import sweden from 'assets/img/countries/Sweden.png';
import albania from 'assets/img/countries/Albania.png';
import bosnia from 'assets/img/countries/Bosnia.png';
import montenegro from 'assets/img/countries/Montenegro.png';
import north_macedonia from 'assets/img/countries/North_Macedonia.png';
import ireland from 'assets/img/countries/Ireland.png'

const styles = {
  ...checkboxAdnRadioStyle,
  cardTitleWhite: {
      color: '#FFFFFF',
      marginTop: '0px',
      minHeight: 'auto',
      fontWeight: '300',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: '3px',
      textDecoration: 'none',
  },
  sticky: {
      position: 'sticky',
      top: '0.2rem',
      zIndex: "99",
  },
  visible: {
      visibility: "visible",
  },
  hidden: {
      visibility: "hidden",
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'center',
  },
  country: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    columnGap: '10px',
  },
  selector: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
  }
}
const imgObj = {
  norway,
  france,
  germany,
  belgium,
  austria,
  united_kingdom,
  netherlands,
  poland,
  italy,
  it: italy,
  romania,
  bulgaria,
  greece,
  portugal,
  slovenia,
  spain,
  croatia,
  hungary,
  slovakia,
  czechia,
  czech: czechia,
  serbia,
  denmark,
  switzerland,
  turkey,
  estonia,
  lithuania,
  latvia,
  finland,
  sweden,
  albania,
  bosnia,
  montenegro,
  north_macedonia,
  ireland,
};

const ParkSelector = ({
  classes, 
  country_code, 
  country_name, 
  options, 
  selected, 
  handleChange, 
  capacity, 
  nonActiveParks, 
  handleChangeNonActiveParks
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const imgSrc = selected ? imgObj[selected.market.toLowerCase().split('-')[0].replace(' ', '_')] : europe;

  return (
      <Grid item xs={12} sm={12} className={classes.sticky}>
          <Card profile className={!isOpen ? classes.hidden : ""}>
            <CardHeader
              color="primary"
              className={!isOpen ? classes.visible : ""}
            >
              <h4
                style={{ position: "relative" }}
                className={classes.cardTitleWhite}
              >
                <span className={classes.hidden}>Title</span>
                <IconButton
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    display: "inline-flex",
                    position: "absolute",
                    right: "0",
                  }}
                  onClick={ () => setIsOpen(!isOpen) }
                >
                  {isOpen ? (
                    <KeyboardArrowUpIcon style={{ fill: "white" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fill: "white" }} />
                  )}
                </IconButton>
              </h4>
            </CardHeader>
            {isOpen && (
                <CardBody profile className={classes.cardBody}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center" style={{columnGap: '10px'}}>
                    <Grid item>
                      <CardAvatar profile>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img src={imgSrc ? imgSrc : europe} alt="..." />
                        </a>
                      </CardAvatar>
                    </Grid>
                    {(country_code || country_name) && (
                      <div className={classes.country}>
                        <h6 className={classes.cardCategory}>
                          {country_name}
                        </h6>
                        <Flag code={country_code} height="24" />
                      </div>
                    )}
                    <Grid item xs={12} md={6} lg={6} xl={4} className={classes.selector}>
                      <div style={{width: '100%'}}>
                        <Grid container justifyContent='center'>
                          <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{ width: '100%' }}>
                              <ParkAutocomplete
                                id="portfolio"
                                options={options}
                                selectedOption={selected}
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <p className={classes.description}>Capacity: {capacity}</p>
                      </div>
                    </Grid>
                    {handleChangeNonActiveParks && (
                      <Grid item xs={12} md={6} xl={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={nonActiveParks}
                              onChange={handleChangeNonActiveParks}
                              name="nonActiveParks"
                              className={classes.checkboxAdnRadioStyle}
                            />
                          }
                          label="Show non-active parks"
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardBody>
            )}
          </Card>
        </Grid>
  )
}

export default withStyles(styles)(ParkSelector);