import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Icon from "@material-ui/core/Icon";
import LocalOffer from "@material-ui/icons/LocalOffer";
import moment from 'moment';
import 'moment-timezone';

class OptimisationResult extends Component {
  render() {
    const { classes } = this.props;
    const missingHours = this.props.trades.missing_hours.map((hour) =>
      <li key={hour.toString()} style={{color:'black'}}>
        {moment.unix(hour).tz("UTC").format('YYYY-MM-DD HH:mm:SS')} GMT
      </li>
    );
    const incorrectHours = this.props.trades.incorrect_hours.map((hour) =>
      <li key={hour.toString()} style={{color:'black'}}>
        {moment.unix(hour).tz("UTC").format('YYYY-MM-DD HH:mm:SS')} GMT
      </li>
    );

    return (
                <Grid item xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color={ (this.props.trades.missing_hours.length === 0 && this.props.trades.incorrect_hours.length === 0) ? "success" : "danger"} stats icon>
                      <CardIcon color={ (this.props.trades.missing_hours.length === 0 && this.props.trades.incorrect_hours.length === 0) ? "success" : "danger"}>
                        <Icon>info_outline</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory} style={{color:'black'}}>Trades Execution status {this.props.portfolio}</p>
                      <p className={classes.cardCategory} style={{color:'black'}}>Time: {moment.unix(this.props.trades.time).tz("UTC").format('YYYY-MM-DD HH:mm:SS')} GMT</p>
                      <h3 style={{color:'black', textAlign: 'center'}}>{this.props.trades.missing_hours.length > 0 ? "Missing hours" : "No missing hours"}</h3>
                      {this.props.trades.missing_hours.length > 0 ? <ul style={{marginBottom: '0px'}}>{missingHours}</ul> : ""}
                      <h3 style={{color:'black', textAlign: 'center'}}>{this.props.trades.incorrect_hours.length > 0 ? "Incorrect hours" : "No incorrect hours"}</h3>
                      {this.props.trades.incorrect_hours.length > 0 ? <ul style={{marginBottom: '0px'}}>{incorrectHours}</ul> : ""}
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <LocalOffer />
                        { (this.props.trades.missing_hours.length === 0 && this.props.trades.incorrect_hours.length === 0) ? "Data is up to date" : "Problem with recent updates"}
                      </div>
                    </CardFooter>
                  </Card>
                </Grid>
        )
  }
}


OptimisationResult.defaultProps = {
  tableHeaderColor: "primary"
};

OptimisationResult.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
};

export default withStyles(tableStyle)(OptimisationResult);
