import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import MaterialTable from '@material-table/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Chip, Tooltip } from '@mui/material';
import alertify from 'alertifyjs';
import classNames from 'classnames';
import localforage from 'localforage';
import moment from 'moment';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import LoginPage from 'views/Login/Oops.jsx';
import { security_fetch_params } from 'actions/index';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import { logout } from "utils/auth";
import { DATE_FORMAT_DASH } from 'constants/general';
import Button from 'components/CustomButtons/Button.jsx';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
  };
};

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  fullWidth: {
    width: '100%',
  },
  marginBottomL: {
    marginBottom: '2rem',
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  grouped: {
    left: 0,
  },
  hidden: {
    visibility: "hidden",
  },
  wrapper: {
    display: 'inline-block',
    padding: '2px',
    boxSizing: 'border-box',
  },
};

const colors = {
  event_received: 'success',
  created: 'error',
  validated: 'success',
};

const AuditDashboard = ({ classes, isLoggedIn }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const getAuditDashboardData = async () => {
      const response = await fetch(
        'https://kube.sama.energy/gateway/api/audit/dashboard',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          ...security_fetch_params,
        }
      );

      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        alertify.error('Response error');
        console.log(response.statusText);
        return;
      }

      const data = await response.json();
      data.updated_at = moment().unix();
      if (data.error) {
        alertify.error("Response error");
        console.log(data.error);
        return;
      }
      
      setDashboardData(data);
  };

  useEffect(() => {
    getAuditDashboardData();
  }, []);

  const handleRefresh = () => {
    setColumns(null);
    getAuditDashboardData();
  };

  useEffect(() => {
    if (dashboardData) {
      const data = [...dashboardData].sort((a, b) => a.instance.name > b.instance.name);
      let arr = [];
      let datesArr = [];
      const date = moment().format(DATE_FORMAT_DASH);
      for (let i = 0; i <= 7; i++) {
        datesArr.push(moment(date).add([-i], 'days').format(DATE_FORMAT_DASH));
      }
      let columns = [
        {
          title: 'Instance',
          field: 'instance',
          headerStyle: {
            textAlign: 'center',
            zIndex: 3,
            backgroundColor: 'white'
          },
          defaultGroupOrder: 0,
        },
        {
          title: 'Park Name',
          field: 'park_name',
          headerStyle: {
            textAlign: 'center',
            zIndex: 3,
            backgroundColor: 'white'
          },
          draggable: false,
        },
        ...datesArr.map(el => ({ 
          title: el, 
          field: el, 
          headerStyle: {
            textAlign: 'center',
            zIndex: 3,
            backgroundColor: 'white'
          },
          draggable: false, 
        }))
      ];

      const renderTooltip = (entrie) => {
        const data = {...entrie};
        delete data.type;
        return (
          <>
            {Object.keys(data).filter(key => data[key]).map((key, i) => {
              const entrieKey = `${key.charAt(0).toUpperCase()}${key.slice(1).replace('_', ' ')}`;
              return (
                <div style={{fontSize: '14px'}} key={key}>{entrieKey}: {data[key]}</div>
              )
            })}
          </>
        )
      }

      data.forEach((data, i) => {
        const { instance, parks } = data;
        parks.forEach((park, i) => {
          let dates = {};
          datesArr.forEach(el => dates[el] = null);
          park.dates.forEach((el, idx) => {
            dates[el.date] = el.entries.map((entrie, key) => {
              const upToDate = moment.utc(entrie.review_timestamp).unix() >= moment().utc().unix();
              return (
                <div
                  key={key}
                  className={classes.wrapper}
                >
                  <Tooltip
                    arrow
                    disableInteractive
                    title={renderTooltip(entrie)}
                  >
                    <Chip
                      label={entrie.type}
                      color={colors[entrie.status]}
                      variant={entrie.status === 'event_received' || 
                        (entrie.status === 'created' && upToDate) ? 'outlined': 'filled'}
                      sx={{
                        fontSize: '14px',
                      }}
                    />
                  </Tooltip>
                </div>
              );
            });
          });
          arr.push({
            id: `${instance.id}_${park.park.park_id}`,
            instance: instance.name,
            park_name: park.park.park_name,
            ...dates,
          });
        });
      });

      setColumns(columns);
      setRows(arr);
    }
  }, [dashboardData]);

  if (isLoggedIn) {
    return columns && rows ? (
      <Card>
        <CardHeader color='primary'>
        <h4 className={classes.cardTitleWhite}>Customer Delivery Dashboard</h4>
        </CardHeader>
        <CardBody>
          <Button color="primary" onClick={() => handleRefresh()}>
            Refresh Data
          </Button>
          <div className={classNames(classes.marginBottomL, classes.fullWidth)}>
            <MaterialTable
              className={classes.headerMatTable}
              columns={columns}
              data={rows}
              title={''}
              onRowsPerPageChange={setPageSize}
              options={{
                maxBodyHeight: window.innerHeight - 180,
                headerStyle: {
                  position: 'sticky',
                  top: 0,
                  color: primaryColor,
                  zIndex: 3
                },
                pageSizeOptions: [10, 25, 50, 100],
                pageSize: pageSize,
                grouping: true,
                defaultExpanded: true,
              }}
              components={{
                Row: (({data}) => {
                  const rowData = {...data};
                  delete rowData.id;
                  delete rowData.tableData;
                  return (
                    <TableRow key={data.tableData.id}>
                      {Object.keys(rowData).map((key, i) => {
                        return (
                          <TableCell key={i} style={{textAlign: 'center', position: 'relative'}}>
                            {rowData[key]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              }}
            />
          </div>
        </CardBody>
      </Card>
    ) : (
      <div className={classes.loadingContainer}>
        <div className="loader" alt="Loading report..." />
      </div>
    );
  } else {
    return <LoginPage />;
  }
};

const ConnectedAuditDashboard = connect(mapStateToProps)(AuditDashboard);
export default withStyles(styles)(ConnectedAuditDashboard);
