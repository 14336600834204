import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormControl, Grid } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { Checkbox, TextField } from '@mui/material';
import alertify from 'alertifyjs';
import classNames from 'classnames';
import ReactJsonView from 'react-json-view';

import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import LoginPage from 'views/Login/Oops.jsx';
import { security_fetch_params } from 'actions/index';
import { logout } from "utils/auth";

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
  };
};

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  fullWidth: {
    width: '100%',
  },
  marginBottomL: {
    marginBottom: '2rem',
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  grouped: {
    left: 0,
  },
  hidden: {
    visibility: "hidden",
  },
  wrapper: {
    display: 'inline-block',
    padding: '2px',
    boxSizing: 'border-box',
  },
};

const AuditDashboardSettings = ({ classes, isLoggedIn }) => {
  const [instances, setInstances] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [parks, setParks] = useState([]);

  const getAuditInstances = async () => {
    const response = await fetch('https://sama-k8s-dev.sama.energy/gateway/api/audit_instances', {
      method: 'GET',
      headers: {
        "Accept": 'application/json',
      },
      ...security_fetch_params
    })

    if (response.ok) {
      const data = await response.json();
      setInstances(data);
    } else if (response.status === 401) {
      logout();
      return
    } else {
      alertify.error('Error getting instances', 5);
    }
  }

  const updateInstance = async (instance_id, body) => {
    const response = await fetch(`https://sama-k8s-dev.sama.energy/gateway/api/audit_instances/${instance_id}`, {
      method: 'PUT',
      headers: {
        "Accept": 'application/json',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(body),
      ...security_fetch_params
    });

    if (response.ok) {
      const data = await response.json();

      const newInstances = [...instances.filter(el => el.id !== data.id), data];
      setInstances(newInstances);
    } else if (response.status === 401) {
      logout();
      return
    } else {
      alertify.error('Error updating instance', 5);
    }
  }

  const getInstanceParks = async (instance_id) => {
    const response = await fetch(`https://sama-k8s-dev.sama.energy/gateway/api/audit_instances/${instance_id}/parks`, {
      method: 'GET',
      headers: {
        "Accept": 'application/json'
      },
      ...security_fetch_params
    })

    if (response.ok) {
      const data = await response.json();
      setParks(data);
    } else if (response.status === 401) {
      logout();
      return
    } else {
      alertify.error("Error geting instance parks", 5);
    }
  }

  const updatePark = async (park_id, body) => {
    const response = await fetch(`https://sama-k8s-dev.sama.energy/gateway/api/audit_parks/${park_id}`, {
      method: 'PUT',
      headers: {
        "Accept": 'application/json',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(body),
      ...security_fetch_params
    });

    if (response.ok) {
      const data = await response.json();
      const newParks = [...parks.filter(el => el.id !== data.id), data];
      setParks(newParks);
    } else if (response.status === 401) {
      logout();
      return
    } else {
      alertify.error("Error updating park!", 5);
    }
  }
  
  useEffect(() => {
    getAuditInstances()
  }, [])

  useEffect(() => {
    if (selectedInstance) {
      getInstanceParks(selectedInstance.id)
    }
  }, [selectedInstance])

  const instancesColumns = [
    {
      title: 'Instance',
      field: 'name',
      editable: 'never',
    }, 
    {
      title: 'Config',
      field: 'config',
      render: rowData => {
        return (
          <ReactJsonView 
            src={rowData.config} 
            displayDataTypes={false} 
            name={false}
            collapsed={0} 
          />
        )
      },
      editComponent: props => {
        const configString = JSON.stringify(props.rowData.config);
        return (
          <TextField 
            defaultValue={configString}
            multiline
            style={{width: '100%'}}
            onChange={(event) => props.onChange(event.target.value)}
            variant='standard'
          />
        )
      },
    },
    {
      title: 'Enabled',
      field: 'enabled',
      render: rowData =>  (
        <Checkbox
          checked={rowData.enabled}
          name="enabled"
          className={classes.checkboxAdnRadioStyle}
          onChange={(_, value) => {
            const { tableData, ...data } = rowData;
            const newData = { ...data, enabled: value };
            const newInstances = [...instances.filter(el => el.id !== newData.id), newData];
            
            setInstances(newInstances);
            updateInstance(newData.id, newData);
          }}
        />
      ),
      editComponent: props => (
        <Checkbox
          checked={!!props.value}
          onChange={(event) => props.onChange(event.target.checked)}
          name="enabled"
          className={classes.checkboxAdnRadioStyle}
        />
      ),
    }
  ]

  const parksColumns = [
    {
      title: 'Park',
      field: 'park_name',
      editable: 'never',
    }, 
    {
      title: 'Config',
      field: 'config',
      render: rowData => {
        return (
          <ReactJsonView 
            src={rowData.config} 
            displayDataTypes={false} 
            name={false}
            collapsed={0} 
          />
        )
      },
      editComponent: props => {
        const configString = JSON.stringify(props.rowData.config);
        return (
          <TextField 
            defaultValue={configString}
            multiline
            style={{width: '100%'}}
            onChange={(event) => props.onChange(event.target.value)}
            variant='standard'
          />
        )
      },
    },
    {
      title: 'Enabled',
      field: 'enabled',
      render: rowData =>  (
        <Checkbox
          checked={rowData.enabled}
          name="enabled"
          className={classes.checkboxAdnRadioStyle}
          onChange={(_, value) => {
            const { tableData, ...data } = rowData;
            const newData = { ...data, enabled: value };            
            const newParks = [...parks.filter(el => el.id !== newData.id), newData];
            setParks(newParks);
            
            updatePark(newData.id, { 
              park_name: newData.park_name,
              enabled: newData.enabled,
              config: newData.config, 
            });
          }}
        />
      ),
      editComponent: props => (
        <Checkbox
          checked={!!props.value}
          onChange={(event) => props.onChange(event.target.checked)}
          name="enabled"
          className={classes.checkboxAdnRadioStyle}
        />
      ),
    }
  ]

  if (isLoggedIn) {
    return instances.length ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>Instance settings</h4>
            </CardHeader>
            <CardBody>
              <div className={classNames(classes.fullWidth)}>
                <MaterialTable
                  className={classes.headerMatTable}
                  columns={instancesColumns}
                  data={instances.sort((a, b) => a.name > b.name)}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          if (newData.config && typeof newData.config === 'string') {
                            try {
                              // Replacing all single quotes with double quotes, trimming leading/trailing whitespace,
                              // and removing any trailing commas before closing brackets in in objects or arrays
                              const configString = newData.config.replaceAll("'", '"').trim().replace(/,\s*([}\]])/g, '$1');
                              newData.config = JSON.parse(configString);
                              delete newData.tableData;
                              updateInstance(newData.id, newData);
                            } catch (err) {
                              alertify.error("Config is not valid JSON string!", 5);
                              reject();
                            }
                          } else {
                            newData.config = {}
                            delete newData.tableData;
                            updateInstance(newData.id, newData);
                          }

                          resolve();
                        }, 1000)
                      }),
                  }}
                  onRowsPerPageChange={setPageSize}
                  options={{ 
                    maxBodyHeight: window.innerHeight - 200,
                    emptyRowsWhenPaging: false,
                    showTitle: false,
                    pageSizeOptions: [10, 25, 50],
                    pageSize: pageSize,
                    actionsColumnIndex: -1, 
                    actionsCellStyle: {
                      padding: '0 20px'
                    },
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={classes.cardTitleWhite}>Parks settings</h4>
            </CardHeader>
            <CardBody>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    className={classNames(
                      classes.formControl,
                      classes.fullWidth,
                      classes.marginBottom
                    )}
                  >
                    <ParkAutocomplete
                      id="instance"
                      label="Select instance:"
                      options={instances}
                      value={selectedInstance}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => setSelectedInstance(v)}
                      limitTags={1}
                      disableClearable
                    />
                  </FormControl>
                </Grid>
                {selectedInstance && (
                  <Grid item xs={12}>
                    <div className={classNames(classes.fullWidth)}>
                      <MaterialTable
                        className={classes.headerMatTable}
                        columns={parksColumns}
                        data={parks.sort((a, b) => a.park_name > b.park_name)}
                        editable={{
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                if (newData.config && typeof newData.config === 'string') {
                                  try {
                                    // Replacing all single quotes with double quotes, trimming leading/trailing whitespace,
                                    // and removing any trailing commas before closing brackets in in objects or arrays
                                    const configString = newData.config.replaceAll("'", '"').trim().replace(/,\s*([}\]])/g, '$1');

                                    newData.config = JSON.parse(configString);
                                    delete newData.tableData;
                                    updatePark(newData.id, { 
                                      park_name: newData.park_name,
                                      enabled: newData.enabled,
                                      config: newData.config, 
                                    });
                                  } catch (err) {
                                    alertify.error("Config is not valid JSON string!", 5);
                                    reject();
                                  }
                                } else {
                                  newData.config = {}
                                  delete newData.tableData;
                                  updatePark(newData.id, { 
                                    park_name: newData.park_name,
                                    enabled: newData.enabled,
                                    config: newData.config, 
                                  });
                                }
                                
                                resolve();
                              }, 1000)
                            }),
                        }}
                        onRowsPerPageChange={setPageSize}
                        options={{ 
                          maxBodyHeight: window.innerHeight - 200,
                          emptyRowsWhenPaging: false,
                          showTitle: false,
                          pageSizeOptions: [10, 25, 50],
                          pageSize: pageSize,
                          actionsColumnIndex: -1, 
                          actionsCellStyle: {
                            padding: '0 20px'
                          },
                        }}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    ) : (
      <div className={classes.loadingContainer}>
        <div className="loader" alt="Loading report..." />
      </div>
    );
  } else {
    return <LoginPage />;
  }
};

const ConnectedAuditDashboardSettings = connect(mapStateToProps)(AuditDashboardSettings);
export default withStyles(styles)(ConnectedAuditDashboardSettings);
