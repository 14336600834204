import {
  RECEIVED_MARKETS_DATA,
  LOADING_MARKETS_DATA,
} from "constants/action-types";


const initialState = {
  markets: [],
  loading: false,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MARKETS_DATA:
      return {
        ...state,
        ...initialState,
      };
    case RECEIVED_MARKETS_DATA:
  	  return {
        ...state,
        markets: action.markets,
        loading: false,
      };
    default:
      return state;
  }
};
