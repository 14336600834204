import { RECEIVED_NOTIFICATIONS_DATA } from "constants/action-types";

import { LOADING_NOTIFICATIONS_DATA } from "constants/action-types";

const initialState = {
  data: [],
  loading: false,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_NOTIFICATIONS_DATA) {
     return { ...state, ...initialState };
  }
  else if (action.type === RECEIVED_NOTIFICATIONS_DATA) {
  	 return {
       ...state,
       data: action.notifications,
       loading: false,
     };
  }

  return state;
};
