import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody';
import Store from '@material-ui/icons/Store';

import Button from 'components/CustomButtons/Button';

import { buildAPIRequest, security_fetch_params } from 'actions/index';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

const mapStateToProps = state => {
  return {
    conn: state.conn,
  };
};

const StopTrading = ({conn}) => {

  const [loadingStatus, setLoadingStatus] = useState(true);
  const [idTrading, setIdTrading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const checkIdTradingStatus = () => {
    const [url, headers] = buildAPIRequest(
      `/api/v1/ts_auth_proxy?channel=${conn.stopTradingChannel}`
    );
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if  (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              setLoadingStatus(false)
              return
            }
            if (data.data[0] === 1) {
              setIdTrading(true);
              setLoadingStatus(false);
            } else if (data.data[0] === 2) {
              setIdTrading(false);
              setLoadingStatus(false);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alertify.error(error, 5);
      });
  };

  const stopStartTrading = () => {
    const [url, headers] = buildAPIRequest(
      `/api/v1/ts_auth_proxy?channel=${conn.stopTradingChannel}`
    );
    fetch(url, {
      method: 'POST',
      headers: headers,
      ...security_fetch_params,
      body: JSON.stringify({ state: idTrading ? 'pause' : 'resume' }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data['success']) {
              if (idTrading) {
                alertify.success('ID trading stopped', 5);
              } else {
                alertify.success('ID trading started', 5);
              }
              setIdTrading(!idTrading);
              setOpenDialog(false);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alertify.error(error, 5);
      });
  };

  useEffect(() => {
    checkIdTradingStatus();
  });

  let cardColor = 'gray';
  let labelButton = 'Pending Status';
  let colorButton = 'info';
  if (!loadingStatus) {
    cardColor = idTrading ? 'success' : 'danger';
    labelButton = idTrading ? 'STOP' : 'START';
    colorButton = idTrading ? 'danger' : 'success';
  }

  if(!conn.stopTradingChannel) return <></>;
  return (
    <div>
      <Grid item>
        <Card>
          <CardIcon color={cardColor}>
            <Store />
          </CardIcon>
          <CardBody>
            <h3>ID Trading</h3>
          </CardBody>
        </Card>
      </Grid>

      <Button
        color={colorButton}
        round
        onClick={() => {
            if (!loadingStatus) setOpenDialog(true);
        }}
      >
        {labelButton}
      </Button>

      <Dialog open={openDialog}>
        <DialogTitle>
          {' '}
          <Typography variant="h4">Stop ID trading</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            {idTrading
              ? 'Are you sure you want to Stop ID trading?'
              : 'Are you sure you want to Start ID trading?'}
          </Typography>
          <Typography variant="subtitle2">
            You can't undo this operation
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpenDialog(false)}>
            No
          </Button>
          <Button
            variant="contained"
            color={colorButton}
            onClick={stopStartTrading}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const StopTradingConnect = connect(mapStateToProps)(StopTrading);
export default StopTradingConnect;

