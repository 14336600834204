import React, { Component } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Grid from '@material-ui/core/Grid';
import Jobs from 'components/Jobs/Jobs';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoginPage from "views/Login/Oops.jsx";
import { get_jobs_data } from "actions/index";

const mapStateToProps = (state) => {
  return {
    jobs: state.jobs,
    tableHead: state.jobs.tableHead,
    tableData: state.jobs.tableData,
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    loading: state.jobs.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_jobs_data: data => dispatch(get_jobs_data(data))
});

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class JobsReport extends Component {
  componentDidMount(){
    if(this.props.isLoggedIn){
      this.props.get_jobs_data();
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props?.isLoggedIn) {
      if (this.props.loading) {
        return (
          <div className="loader" alt="Loading report..."/>
        );
      }

      return (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardHeader color="primary">
                 <h4 className={classes.cardTitleWhite}>Jobs list</h4>
              </CardHeader>
              <CardBody >
                 <Jobs
                  tableHead={ this.props.tableHead}
                  tableData={ this.props.tableData}
                  jobs={this.props.jobs}
                  winHeight={window.innerHeight - 230}
                 />
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      );
    } else {
      return (<LoginPage />);
    }
  }
}

const ConnectedJobs = connect(mapStateToProps, mapDispatchToProps)(JobsReport);
export default withStyles(styles)(ConnectedJobs);
