import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";
import PropTypes from "prop-types";
import HourlyResult from 'components/TradesDashboard/HourlyResult';
import Grid from '@material-ui/core/Grid';

class PortfolioResult extends Component {
  render() {
    var that = this;
    const hours = this.props.hours.map( function(hour, index) {
      return(<HourlyResult key={index} portfolio={that.props.portfolio} trades={hour} />)
    });

    return (
            <Grid container spacing={4}>
              {hours}
            </Grid>
        )
    }
}

PortfolioResult.defaultProps = {
  tableHeaderColor: "primary",
};

PortfolioResult.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
};

export default withStyles(tableStyle)(PortfolioResult);
