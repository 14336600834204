import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Sessions from 'components/Optimisation/Sessions';
import ParkSelector from 'components/ParkSelector/ParkSelector';
import localForage from 'localforage';
import moment from 'moment';
import 'moment-timezone';
import {logout} from 'utils/auth';
import { downloadParks } from 'utils/getDataMethods';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import LoginPage from 'views/Login/Oops.jsx';
import {
  buildAPIRequest,
  security_fetch_params,
  getDefaultPark,
  set_active_park,
  set_current_page,
  getModelsData,
  set_optimisation_type,
  set_show_optimisation_non_active
} from 'actions/index';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    loading: state.jobs.loading,
    activePark: state.conn.activePark,
    curPage: state.conn.curPage,
    optType: state.optimisation.opt_type,
    showNonActive: state.optimisation.showNonActive
  };
};

const mapDispatchToProps = (dispatch) => ({
  set_active_park: (activePark) => dispatch(set_active_park(activePark)),
  set_current_page: (activePark) => dispatch(set_current_page(activePark)),
  getModelsData: () => dispatch(getModelsData()),
  set_optimisation_type_: (type) => dispatch(set_optimisation_type(type)),
  set_show_non_active: (data) => dispatch(set_show_optimisation_non_active(data)),
});

const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

class Optimisation extends Component {
  constructor(props) {
    super(props);
    const park = getDefaultPark() || { id: -1, capacity: null };
    this.state = {
      selected: [],
      production_model: 'forecast',
      price_model: 'stochastic',
      maximum_power: 200,
      price_time_offset: 15,
      max_afternoon_horizon: 24,
      calc_offset: 30,
      trades_limit: 200,
      start_hour:
        moment().tz('Europe/Paris').add(1, 'hours').format('HH') + ':00',
      intraday_treatment: 'opt-use',
      delivery_date: moment().format('Y-MM-DD'),
      windpark: park,
      portfolio: park.id,
      after_logged_in: false,
      country_name: '',
      optTotalItems: 0,
      parks: [],
      showNonActive: !!this.props.showNonActive,
    };

    this.handleChange = this.handleChange.bind(this);
    this.start_da_optimisation = this.start_da_optimisation.bind(this);
    this.start_id_optimisation = this.start_id_optimisation.bind(this);
    this.build_parameters = this.build_parameters.bind(this);
    this.send_optimisation = this.send_optimisation.bind(this);
    this.get_windpark_data = this.get_windpark_data.bind(this);
    this.update_windpark = this.update_windpark.bind(this);

    this.update_optimisations = this.update_optimisations.bind(this);
    this.get_optimisations = this.get_optimisations.bind(this);

    this.start_recalculate = this.start_recalculate.bind(this);
    this.recalculate = this.recalculate.bind(this);
    this.set_selected = this.set_selected.bind(this);
  }

  build_parameters(type = true) {
    return {
      afternoon_run: type,
      alpha: 0.95,
      beta: 0,
      bidding_mode: 'single',
      calc_offset: this.state.calc_offset,
      date: this.state.delivery_date,
      dt: 1,
      enable_shorts: false,
      fix_rng_seed: 0,
      forecast_error_variance: null,
      french_intraday_hour: parseInt(this.state.start_hour.substring(0, 3)),
      intraday_treatment: this.state.intraday_treatment,
      market_model_days: 180,
      market_start_hour: 0,
      max_afternoon_horizon: this.state.max_afternoon_horizon,
      mode: this.state.production_model,
      n_da_am_wind_scenarios: 10,
      n_ib_price_scenarios: 100,
      n_lambdaD_scenarios: 100,
      n_MAvsMD_scenarios: 100,
      n_redc_da_am_wind_scenarios: 3,
      n_redc_ib_price_scenarios: 3,
      n_redc_lambdaD_scenarios: 4,
      n_redc_MAvsMD_scenarios: 4,
      n_redc_wind_scenarios: 3,
      n_wind_scenarios: 10,
      Pmax: this.state.maximum_power,
      price_mode: this.state.price_model,
      price_time_offset: this.state.price_time_offset,
      price_variances: null,
      production_model_days: 30,
      refit_market: true,
      refit_weather: true,
      time_span: 24,
      trades_limit: this.state.trades_limit,
      use_da_optimisation: false,
      use_predicated_value: false,
      use_volume_distribution: false,
      country_name: '',
      country_code: '',
    };
  }

  get_parks = async () => {
    const parks = await downloadParks(null, null, this.props.showNonActive);
    this.setState({ parks: parks.data });
  }

  set_selected(newSelected) {
    this.setState({ selected: newSelected });
  }

  start_recalculate() {
    for (let i = 0; i < this.state.selected.length; i++) {
      this.recalculate(this.state.selected[i]);
    }
    this.set_selected([]);
  }

  recalculate(id) {
    // eslint-disable-next-line
    const [url, headers] = buildAPIRequest(
      `/api/reports/${id}/calculate_profit`
    );
    fetch(url, {
      method: 'POST',
      ...security_fetch_params,
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.data === 'OK') {
              this.update_optimisations();
              alertify.notify('Finished calculations', 'success', 5);
            } else {
              alertify.error('Error during calculations', 'error', 5);
            }
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  update_optimisations() {
    const key = `optimisations_${this.state.portfolio}${
        this.props.optType &&
        this.props.optType.items.length &&
        this.props.optType.items[0].value
          ? `_${this.props.optType.items[0].value}`
          : ''
      }_${this.props.curPage + 1}_${this.props.conn.label}`;

    const params = this.props.optType && 
      this.props.optType.items.length && 
      this.props.optType.items[0].value 
        ? `?optimization_type=${this.props.optType.items[0].value}` 
        : '';
    const [url, headers] = buildAPIRequest(
      `/api/reports/${this.state.portfolio}/${this.props.curPage + 1}/100${params}`
    );
    fetch(url, { method: 'GET', headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            data.updated_at = moment().unix();
            localForage.setItem(key, data, (err) => {
              if (err) {
                console.log(err, key, data);
              }
            });
            this.setState({ 
              optimisations: data.data,
              optTotalItems: data.total_items
            });
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  get_optimisations() {
    localForage.getItem(
      `optimisations_${this.state.portfolio}${
        this.props.optType && this.props.optType.items.length && this.props.optType.items[0].value
          ? `_${this.props.optType.items[0].value}`
          : ''
      }_${this.props.curPage + 1}_${this.props.conn.label}`,
      (err, value) => {
        if (value) {
          if (value.updated_at < moment().unix() - 100) {
            this.update_optimisations();
          }
          if (!value.data) {
            return;
          }
          this.setState({
            optimisations: value.data,
            optTotalItems: value.total_items,
          });
        } else {
          this.update_optimisations();
        }
      }
    );
  };

  update_windpark(windpark) {
    const [url, headers] = buildAPIRequest(`/api/v1/parks/${windpark}`);
    fetch(url, { method: 'GET', headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            data.updated_at = moment().unix();
            localForage.setItem(`windpark_data_${windpark}_${this.props.conn.label}`, data, (err) => {
              // if err is non-null, we got an error
              if (err) {
                console.log(err);
              }
            });
            if (!data.data) {
              return;
            }
            this.setState({
              portfolio: data.data.id,
              windpark: data.data,
              production_model: data.data.optimization_job?.mode ?? null,
              price_model: data.data.optimization_job?.price_mode ?? null,
              maximum_power: data.data.optimization_job?.Pmax ?? null,
              price_time_offset:
                data.data.optimization_job?.price_time_offset ?? null,
              max_afternoon_horizon:
                data.data.optimization_job?.max_afternoon_horizon ?? null,
              calc_offset: data.data.optimization_job?.calc_offset ?? null,
              trades_limit: data.data.optimization_job?.trades_limit ?? null,
              intraday_treatment:
                data.data.optimization_job?.intraday_treatment ?? null,
              country_name: data.data.location?.country_name,
              country_code: data.data.location?.country_iso3166,
            });
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          production_model: null,
          price_model: null,
          maximum_power: null,
          price_time_offset: null,
          max_afternoon_horizon: null,
          calc_offset: null,
          trades_limit: null,
          intraday_treatment: null,
        });
      });
  }

  get_windpark_data(windpark) {
    localForage.getItem(`windpark_data_${windpark}_${this.props.conn.label}`, (err, value) => {
      if (value) {
        if (value.updated_at < moment().unix() - 100) {
          this.update_windpark(windpark);
        }
        if (!value.data) {
          return;
        }
        this.setState({
          portfolio: value.data.id,
          windpark: value.data,
          production_model: value.data.optimization_job?.mode ?? null,
          price_model: value.data.optimization_job?.price_mode ?? null,
          maximum_power: value.data.optimization_job?.Pmax ?? null,
          price_time_offset:
            value.data.optimization_job?.price_time_offset ?? null,
          max_afternoon_horizon:
            value.data.optimization_job?.max_afternoon_horizon ?? null,
          calc_offset: value.data.optimization_job?.calc_offset ?? null,
          trades_limit: value.data.optimization_job?.trades_limit ?? null,
          intraday_treatment:
            value.data.optimization_job?.intraday_treatment ?? null,
          country_name: value.data.location?.country_name,
          country_code: value.data.location?.country_iso3166,
        });
      } else {
        this.update_windpark(windpark);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isLoggedIn) {
      if (!this.state.after_logged_in || this.props.curPage !== prevProps.curPage) {
        this.get_windpark_data(this.state.portfolio);
        setTimeout(() => {
          this.get_optimisations();
        }, 100);
        this.setState({ loading: true, after_logged_in: true });
      }
    }
    if (this.props.showNonActive !== prevProps.showNonActive) {
      this.get_parks(null, null, this.props.showNonActive);
      this.setState({showNonActive: !!this.props.showNonActive});
    }
    if (this.props.conn.activePark !== prevProps.conn.activePark) {
      sessionStorage.setItem('conn', JSON.stringify(this.props.conn));
    }
    if (
      prevProps.optType && 
      this.props.optType && 
      prevProps.optType.items !== this.props.optType.items && 
      (
        (this.props.optType.items[0] && this.props.optType.items[0].value === 'DA') || 
        (this.props.optType.items[0] && this.props.optType.items[0].value === 'ID') || 
        this.props.optType.items.length === 0 ||
        !this.props.optType.items[0].value
      )
    ) {
      this.get_optimisations();
    }
  }

  async componentDidMount() {
    if (this.props.isLoggedIn) {
      this.get_parks(null, null, this.props.showNonActive);
      this.get_windpark_data(this.state.portfolio);
      this.props.getModelsData();
    }
  }

  send_optimisation(parameters) {
    const [url, headers] = buildAPIRequest(
      `/api/windparks/${this.state.portfolio}/start_optimization`
    );
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(parameters), ...security_fetch_params,
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            alertify.notify('Optimisation job submitted', 'success', 5);
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  start_da_optimisation() {
    const parameters = this.build_parameters(false);
    this.send_optimisation(parameters);
  }

  start_id_optimisation() {
    const parameters = this.build_parameters(true);
    this.send_optimisation(parameters);
  }

  handleChange(_, value) {
    if (!value) return;

    if (!this.props.activePark || value.id !== this.props?.activePark.id) {
      this.props.set_active_park(value);
      this.props.set_current_page(0);
      this.setState({ page: 0 });
    }

    this.get_windpark_data(value.id);
    setTimeout(() => {
      this.get_optimisations();
    }, 100);

    this.setState({ portfolio: value.id });
  }

  handleChangeNonActiveParks = (_, value) => {
    this.props.set_show_non_active(value);
  }

  render() {
    const { classes } = this.props;
    const { windpark } = this.state;

    // handle "Capacity" null value
    const capacity =
      windpark.capacity === null
        ? 'N/A'
        : (windpark.capacity).toFixed(1);
    let activePark;
    if (this.props?.activePark) {
      activePark = this.props.activePark;
    } else {
      activePark = this.props.conn.parks.filter(
        (item) => item.id === this.state.portfolio
      )[0];
      if(activePark) {
        this.props.set_active_park(activePark);
      }
    }

    if (this.props?.isLoggedIn) {
      return (
        <Grid container spacing={4}>
          <ParkSelector 
            country_code={this.state.country_code} 
            country_name={this.state.country_name} 
            options={this.state.parks} 
            selected={activePark}
            handleChange={this.handleChange}
            capacity={capacity}
            nonActiveParks={this.state.showNonActive}
            handleChangeNonActiveParks={this.handleChangeNonActiveParks}
          />
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Existing Optimisations
                </h4>
              </CardHeader>
              <CardBody>
                <Sessions
                  park={this.state.windpark}
                  selected={this.state.selected}
                  page={this.props.curPage}
                  setCurPage={this.props.set_current_page}
                  set_selected={this.set_selected}
                  optTotalItems = {this.state.optTotalItems}
                  optimisations={this.state.optimisations}
                  winHeight={window.innerHeight - 250}
                  handleOptTypeChange={this.props.set_optimisation_type_}
                />
              </CardBody>
              <CardFooter>
                <Grid container spacing={4} style={{ width: '100%' }}>
                  <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'left' }}>
                    <Button color="primary" onClick={this.start_recalculate}>
                      Recalculate profit for selected reports
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </Card>
          </Grid>
        </Grid>
      );
    }
    return <LoginPage />;
  }
}

const ConnectedOptimisation = connect(
  mapStateToProps,
  mapDispatchToProps
)(Optimisation);
export default withStyles(styles)(ConnectedOptimisation);
