import {
  GET_GENERATION_DATA,
  RECEIVED_GENERATION_DATA,
  LOADING_GENERATION_DATA,
  LOADING_CHUNKS_GENERATION_DATA,
  RECEIVED_GENERATION_DATA_CHUNK,
  RECEIVED_GENERATION_DATA_ALL,
  RESET_GENERATION_DATA_ALL_LOADED,
} from "constants/action-types";
import localForage from 'localforage';
import moment from 'moment';

const initialState = {
  forecast: [],
  latest_forecast: [],
  power: [],
  report_productive: [],
  loading: true,
  loadingChunks: false,
  allDataLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_GENERATION_DATA:
      return { ...state, loading: true };
    case LOADING_CHUNKS_GENERATION_DATA:
      return {
        ...state,
        loading: true,
        loadingChunks: true,
        allDataLoaded: false,
      };
    case GET_GENERATION_DATA:
      return { ...state, loading: true };
    case RECEIVED_GENERATION_DATA: {
      const { data, report_productive } = action.generation;

      return (!data)
        ? {
          ...state,
          forecast: null,
          latest_forecast: null,
          power: null,
          report_productive: report_productive ?? null,
          loading: false,
        } : {
          ...state,
          forecast: data.forecast,
          latest_forecast: data.latest_forecast,
          power: data.power,
          report_productive,
          loading: false,
        };
    }
    case RECEIVED_GENERATION_DATA_CHUNK: {
      const { data, report_productive } = action.generation;

      return {
        ...state,
        forecast: [
          ...(data?.forecast ? data.forecast : []),
          ...state.forecast,
        ],
        latest_forecast: [
          ...(data?.latest_forecast ? data.latest_forecast : []),
          ...state.latest_forecast,
        ],
        power: [
          ...(data?.power ? data.power : []),
          ...state.power,
        ],
        report_productive: [
          ...(report_productive ? report_productive : []),
          ...state.report_productive,
        ],
        loading: false,
      };
    }
    case RECEIVED_GENERATION_DATA_ALL: {
      const { data } = action.generation;
      let { report_productive } = action.generation;

      const forecast = [
        ...data.forecast,
        ...state.forecast,
      ];
      const latest_forecast = [
        ...data.latest_forecast,
        ...state.latest_forecast,
      ];
      const power = [
        ...data.power,
        ...state.power,
      ];
      report_productive = [
        ...report_productive,
        ...state.report_productive,
      ];
      const generation = data ? {
          forecast,
          latest_forecast,
          power,
          report_productive,
        } : {};

      // Save all data to local storage
      localForage.setItem(
        action.key,
        {
          data: { forecast, latest_forecast, power },
          report_productive,
          updated_at: moment().unix(),
        },
        (err) => {
          if (err !== null) {
            console.log(err);
          }
          return true;
        });

      return {
        ...state,
        ...generation,
        loading: false,
        loadingChunks: false,
        allDataLoaded: true,
      };
    }
    case RESET_GENERATION_DATA_ALL_LOADED: {
      return {
        ...state,
        allDataLoaded: false,
      };
    }
    default:
      return state;
  }
};
