import React, { Component } from 'react';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom'

import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";
import PropTypes from "prop-types";

import { Input } from '@material-ui/icons'

import SaveIcon from '@material-ui/icons/Save';

import {buildAPIRequest} from "../../actions/index"

class Sessions extends Component {
  render() {   
    var sessions = this.props.sessions;
    const { classes, tableHead, tableData, tableHeaderColor } = this.props;
    return (
        <Paper >
          <Table>
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    style={{textAlign: 'center'}}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

            <TableBody>
              {tableData.map( (row, index) => {
                return (
                  <TableRow key={row[0]}>
                    <TableCell component="th" scope="row">
                      {row[1]}
                    </TableCell>
                    <TableCell align="right">{row[2]}</TableCell>
                    <TableCell align="right">{row[3]}</TableCell>

                    <TableCell>
                        <a href={buildAPIRequest('/api/btsessions/' + row[0] + '/optres_zip')[0]}>
                          <SaveIcon />
                        </a>


                      <Link to={{ pathname: '/session_results', state: {data: sessions[index]}}}> <Input />
                      </Link>
                    </TableCell>

                    <TableCell align="right">{row[6]}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
    );
  }
}

Sessions.defaultProps = {
  tableHeaderColor: "primary"
};

Sessions.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};

export default withStyles(tableStyle)(Sessions);

