import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// core components
import Grid from '@material-ui/core/Grid';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import LoginPage from "views/Login/Oops.jsx";
import {connect} from "react-redux";


import {get_structured_alerts} from "actions/index";
import {get_compl_excs} from "actions/index";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import CardBody from "components/Card/CardBody";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.loggedIn,
  loading: state.structured_alerts.loading,
  alerts: state.structured_alerts.alerts,
  compl_excs: state.structured_alerts.compl_excs,
});

const mapDispatchToProps = (dispatch) => ({
  get_structured_alerts: () => dispatch(get_structured_alerts()),
  get_compl_excs: () => dispatch(get_compl_excs())
});

class StructuredAlerts extends React.Component {
  componentDidMount() {
    if (this.props.isLoggedIn === false) {
      //  this.props.history.push('/login');
    } else {
      this.props.get_structured_alerts();
      this.props.get_compl_excs();
    }
  }

  render() {
    const {classes} = this.props;

    const reasons_translation = {
      defect: 'Defect',
      coverage_score_bool: 'Less data than expected',
      freshness_score_local_bool: 'No successful execution',
      freshness_score_source_bool: 'No new data',
      absence_h: 'No new data on default threshold'
    };
    if (this.props?.isLoggedIn) {
      if (this.props.loading) {
        return (
          <div className="loader" alt="Loading report..."/>
        )
      } else {
        return (
          <Grid container spacings={4}>
            {
              this.props.alerts.length ? this.props.alerts.map((alert) =>
                <Grid item xs={12} key={alert.market + alert.source + alert.name +
                alert.extra_context.defect_start + alert.extra_context.defect_end}>
                  <Card>
                    <CardHeader color="danger" stats icon>
                      <CardIcon color="danger">
                        <Icon>info_outline</Icon>
                      </CardIcon>
                      <h4 className={classes.cardCategory}>Market: {alert.market},
                        Severity: {alert.severity}</h4>
                      <h3 className={classes.cardTitle}>{alert.source}: {alert.name}</h3>
                    </CardHeader>
                    <CardBody>
                      <h4>Reasons: {
                        Object.entries(alert.conditions).filter(
                          ([key, value]) => (value === false || (typeof value == "number"))
                        ).map(
                          ([key, value]) => reasons_translation[key]
                        ).join(', ')
                      }</h4>
                      <Accordion>
                        <AccordionSummary
                          aria-controls={alert.market + alert.source + alert.name +
                          alert.extra_context.defect_start + alert.extra_context.defect_end + "content"}
                          id={alert.market + alert.source + alert.name +
                          alert.extra_context.defect_start + alert.extra_context.defect_end + "header"}
                        >
                          Extra context
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            {Object.entries(alert.extra_context).filter(
                              ([key, value]) => (value !== null)
                            ).map(
                              ([key, value]) => (
                                <TableRow>
                                  <TableCell component="th">{key}</TableCell>
                                  <TableCell>{value}</TableCell>
                                </TableRow>
                              )
                            )
                            }
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    </CardBody>
                  </Card>
                </Grid>
              ) : <Grid item><Card><CardBody><h3>No alerts</h3></CardBody></Card></Grid>
            }
            {this.props.compl_excs?.length
              ? (
                <Grid item xs={12} key="odd ostrich">
                  <Accordion>
                    <AccordionSummary aria-controls="odd ostrich content" id="odd ostrich header">
                      Completeness exceptions
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        {
                          this.props.compl_excs.map(
                            (exc) => (
                              <TableRow key={JSON.stringify(exc)}>
                                <TableCell>{exc.exc_end_mkt}</TableCell>
                                <TableCell>{exc.exc_start_mkt}</TableCell>
                                <TableCell>{exc.market}</TableCell>
                                <TableCell>{exc.name}</TableCell>
                                <TableCell>{exc.source}</TableCell>
                              </TableRow>
                            )
                          )
                        }
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )
              : null
            }
          </Grid>
        )
      }
    } else {
      return (<LoginPage/>);
    }
  }
}

StructuredAlerts.propTypes = {
  classes: PropTypes.object.isRequired
};

const StructuredAlertsConnect = connect(mapStateToProps, mapDispatchToProps)(StructuredAlerts);
export default withStyles(dashboardStyle)(StructuredAlertsConnect);
