import React, { useEffect, useState } from 'react';

import { FormControl, TextField, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import { Autocomplete } from '@material-ui/lab';
import CustomButton from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import {
  buildAPIRequest,
  security_fetch_params,
} from 'actions/index';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import { logout } from 'utils/auth';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const TradingConnectorsDetails = ({ location, classes }) => {

  const [tradeConn, setTradeConn] = useState({});
  const [metaData, setMetaData] = useState({});
  const [editedTransport, setEditedTransport] = useState('');
  const [editedPayload, setEditedPayload] = useState('');

  const parkId = location.state?.parkId;
  const tradeConnId = location.state?.tradeConId;
  const typeOperation = location.state?.typeOperation;

  const getMetaData = () => {
    let [url, headers] = buildAPIRequest(`/api/trading_connector/metadata`);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if  (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            if (data) setMetaData(data);
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tradeConnRequest = (urlReq, reqType, message, body = null) => {
    let [url, headers] = buildAPIRequest(urlReq);
    fetch(url, {
      method: reqType,
      headers: headers,
      body: body ? body : JSON.stringify(tradeConn),
      ...security_fetch_params,
    })
      .then((response) => {
        if (response.ok) {
          alertify.success(message, 5);
        } else if (response.status === 401) {
          logout();
          return;
        } else {
          alertify.error(response.statusText, 5);
        }
      })
      .catch((error) => {
        console.log(error);
        alertify.error(error, 5);
      });
  };
  const getTradeConn = () => {
    let [url, headers] = buildAPIRequest(
      `/api/windparks/${parkId}/connector/${tradeConnId}`
    );
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if  (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            if (data) setTradeConn(data);
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTestClick = () => {
    if (typeOperation === 'edit') {
        tradeConnRequest(
            `/api/windparks/${parkId}/connector/${tradeConnId}/test`,
            'POST',
            'Test message has sended.'
            );
        }
  };
  const handleSaveClick = () => {
    if (typeOperation === 'edit') {
      tradeConnRequest(
        `/api/windparks/${parkId}/connector/${tradeConnId}`,
        'PATCH',
        'Data Updated'
      );
    } else if (typeOperation === 'new') {
      tradeConnRequest(
        `/api/windparks/${parkId}/connector`,
        'POST',
        'Trading Connector has created'
      );
    }
  };

  const handleEnableClick = () => {
    if (typeOperation === 'edit') {
        const newTradeConn = {...tradeConn};
        newTradeConn.enabled = !newTradeConn.enabled;
        setTradeConn(newTradeConn);
        if (tradeConnId) {
            tradeConnRequest(
                `/api/windparks/${parkId}/connector/${tradeConnId}`,
                'PATCH',
                `Trading Connector has ${newTradeConn.enabled ? 'enabled' : 'disabled'}`,
                JSON.stringify(newTradeConn)
              );
        }
    }
  };

  const handleChange = (event, value, flag) => {
    if (flag === 'payload') {
      setEditedPayload(value);
    } else {
      setEditedTransport(value);
    }
  }
  
  useEffect(() => {
    if (editedPayload && editedTransport && metaData) {
      const kwargsTransportMeta = metaData?.data.transport[editedTransport];
      const kwargsPayloadMeta = metaData?.data.payload_builder[editedPayload];
      let kwargsTransportTradeConn = {};
      let kwargsPayloadTradeConn = {};
      Object.keys(kwargsTransportMeta).forEach((key) => {
        const tempalteTransportValue = metaData?.data.transport[editedTransport][key]['value'];
        kwargsTransportTradeConn[key] = tempalteTransportValue;
      });
      Object.keys(kwargsPayloadMeta).forEach((key) => {
          const tempaltePayloadValue = metaData?.data.payload_builder[editedPayload][key]['value'];
          kwargsPayloadTradeConn[key] = tempaltePayloadValue;
      });

      const templateTradeConn = {
        config: {
          transport: {
            name: editedTransport,
            kwargs: { ...kwargsTransportTradeConn },
          },
          payload_builder: {
            name: editedPayload,
            kwargs: { ...kwargsPayloadTradeConn},
          },
        },
      };
      setTradeConn(templateTradeConn);
    }
  }, [editedTransport, editedPayload, metaData])

  useEffect(() => {
    if (typeOperation === 'edit') {
      getTradeConn();
    }
    getMetaData();
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleChangeTransportField = (event) => {
    const newTradeConn = { ...tradeConn };
    let value;
    if (
      metaData?.data.transport[tradeConn?.config.transport.name][
        event.target.name
      ]['type'] === 'list'
    ) {
      value = event.target.value.split('\n');
    } else {
      value = event.target.value;
    }
    newTradeConn.config.transport.kwargs[event.target.name] = value;
    setTradeConn(newTradeConn);
  };

  const handleChangePayloadField = (event) => {
    const newTradeConn = { ...tradeConn };
    const value = event.target.value;
    newTradeConn.config.payload_builder['kwargs'][event.target.name] = value;
    setTradeConn(newTradeConn);
  };

  let transports = [];
  let payloads = [];
  let metaDataTransport = {};
  let kwargsTransport = {};
  let kwargsPayload = {};
  let metaDataPayload = {};
  const transport = tradeConn?.transport;
  const payload = tradeConn?.config ? tradeConn.config.payload_builder.name : 'default';
  if (Object.keys(metaData).length) {
    transports = Object.keys(metaData.data.transport);
    payloads = Object.keys(metaData.data.payload_builder)
    if (Object.keys(tradeConn).length) {
      const metaTransport =
      typeOperation === 'new' ? editedTransport : transport;
      const metaPayload = 
      typeOperation === 'new' ? editedPayload : payload;
      metaDataTransport = metaData?.data.transport[metaTransport];
      metaDataPayload = metaData.data.payload_builder[metaPayload]
      kwargsTransport = tradeConn.config.transport.kwargs;
      kwargsPayload = tradeConn.config.payload_builder.kwargs;
    }
  }

  const formTransportCreate = (
    <Grid container spacing={4}>
      {Object.keys(metaDataTransport).map((item, index) => {
        let component;
        const metaType = metaDataTransport[item].type;
        if (metaType === 'string') {
          component = (
            <TextField
              className={classes.textField}
              type={item.includes('password') ? 'password' : 'text'}
              label={metaDataTransport[item].label}
              name={item}
              value={kwargsTransport[item] ? kwargsTransport[item] : ''}
              margin="normal"
              onChange={handleChangeTransportField}
            />
          );
        } else if (metaType === 'template' || metaType === 'file') {
          component = (
            <TextField
              className={classes.textField}
              value={kwargsTransport[item] ? kwargsTransport[item] : ''}
              label={metaDataTransport[item].label}
              name={item}
              multiline
              margin="normal"
              onChange={handleChangeTransportField}
            />
          );
        } else if (metaType === 'list') {
          component = (
            <TextField
              id="outlined-multiline-flexible"
              value={kwargsTransport[item] ? kwargsTransport[item].join('\n') : ''}
              label={metaDataTransport[item].label}
              name={item}
              multiline
              onChange={handleChangeTransportField}
            />
          );
        }
        return (
          <Grid item xs={12} sm={4} key={index}>
            <FormControl className={classNames(classes.fullWidth)}>
              {component}
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );

  const formPayloadCreate = (
    <Grid container spacing={4}>
      {Object.keys(metaDataPayload).map((item, index) => {
        const metaType = metaDataPayload[item].type;
        let component;
        if(metaType === 'string') {
            component = (<TextField
                className={classes.textField}
                type='text'
                label={metaDataPayload[item].label}
                name={item}
                value={kwargsPayload[item]}
                margin="normal"
                multiline
                onChange={handleChangePayloadField}
            />)
        }
        
        return (
          <Grid item xs={12} sm={6} key={index}>
            <FormControl className={classNames(classes.fullWidth)}>
              {component}
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <Grid container spacing={4}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Trading Connectors Details</h4>
        </CardHeader>
        <CardBody>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classNames(classes.fullWidth)}>
                <Autocomplete
                  disablePortal
                  id="transport"
                  value={
                    typeOperation === 'new'
                      ? editedTransport
                      : transport || null
                  }
                  options={transports}
                  disabled={typeOperation === 'edit'}
                  renderInput={(params) => (
                    <TextField {...params} label="Transport" />
                  )}
                  onChange={(event, value) => handleChange(event, value, 'transport')}
                />
              </FormControl>
            </Grid>
            {Object.keys(tradeConn).length &&
              Object.keys(metaDataTransport).length ? (
                <>
                  <Grid item xs={12}>
                    {formTransportCreate} 
                  </Grid>
                </>
              ) : (
                <></>
              )
            }
            <Grid item xs={12} sm={6}>
              <FormControl className={classNames(classes.fullWidth)}>
                <Autocomplete
                  disablePortal
                  id="payload_builder"
                  value={
                    typeOperation === 'new'
                      ? editedPayload
                      : payload || null
                  }
                  options={payloads}
                  disabled={typeOperation === 'edit'}
                  renderInput={(params) => (
                    <TextField {...params} label="Payload" />
                  )}
                  onChange={(event, value) => handleChange(event, value, 'payload')}
                />
              </FormControl>
            </Grid>
            {Object.keys(tradeConn).length &&
              Object.keys(metaDataTransport).length ? (
                <>
                    <Grid item xs={12}>
                        {formPayloadCreate}
                    </Grid>
                </>
              ) : (
                <></>
              )
            }
          </Grid>
        </CardBody>
        <CardFooter>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                    <CustomButton color="primary" onClick={handleSaveClick}>
                        Save Data
                    </CustomButton>
                </Grid>
                {Object.keys(tradeConn).length && typeOperation === 'edit' ? <Grid item xs={12} sm={4}>
                    <CustomButton color="primary" onClick={handleEnableClick}>
                        {tradeConn.enabled ? 'Disable' : 'Enable'}
                    </CustomButton>
                </Grid>
                    : <></>
                }
                {typeOperation === 'edit' ? <Grid item xs={12} sm={4}>
                    <CustomButton color="primary" onClick={handleTestClick}>
                        Test
                    </CustomButton>
                </Grid>
                    : <></>
                }
            </Grid>

        </CardFooter>
      </Card>
    </Grid>
  );
};

const ConnectedTradingConnectorsDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(TradingConnectorsDetails);
export default withStyles(dashboardStyle)(ConnectedTradingConnectorsDetails);
