import { RECEIVED_REPORT } from "constants/action-types";
import { RECEIVED_PNL_PERIOD_PARKS_DATA } from "constants/action-types";

import { LOADING_REPORT } from "constants/action-types";

const initialState = {
  plreport: {
    tableHead: [
      [
        "Hour",
        "Volume, MWh",
        "Prices, currency/MWh",
        "Profit & Loss, currency",
        "Base Approach, currency",
        "Added, currency"
      ],
      [
        "Actual Production, MWh",
        "Day-Ahead forecast, MWh",
        "Day-Ahead trades, MWh",
        "Intraday trades, MWh",
        "Imbalance, MWh",
        "DA Price, currency/MWh",
        "Imb price Up, currency/MWh",
        "Imb price Down, currency/MWh",
        "Day-ahead, currency",
        "Intraday, currency",
        "Imbalance, currency",
        "Total, currency",
        "Day-ahead, currency",
        "Imbalance, currency",
        "Total, currency",
        "Value, currency"
      ],
    ],
    loading: true,
  },
  parks: [],
};

export default (state = initialState, action) => {
  if (action.type === LOADING_REPORT) {
     return { ...state, ...initialState };
  } else if (action.type === RECEIVED_REPORT) {
  	 return {
       ...state,
       plreport: {
         tableDataGran: action.report.tableDataGran,
         daOpenPositionGran: action.report.daOpenPositionGran,
         prodSalesGran: action.report.prodSalesGran,
         idPnlGran: action.report.idPnlGran,
         tableHead: state.plreport.tableHead,
         tableDetailsDataGran: action.report.tableDetailsDataGran,
         idTradesData: action.report.idTradesData,
         auctionsData: action.report.auctionsData,
         tableDetailsHead: action.report.tableDetailsHead,
         hourly: action.report.hourly,
         parameters: action.report.parameters,
         isSkipIntraday: action.report.isSkipIntraday,
         allData: action.report.allData,
         loading: false,
       },
     };
  } else if (action.type === RECEIVED_PNL_PERIOD_PARKS_DATA) {
    return {
      ...state,
      parks: action.data.parks.data,
    };
 }

  return state;
};
