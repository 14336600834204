import React, { useCallback } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid';
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoginPage from "views/Login/Oops.jsx";
import { get_notifications_data } from "actions/index";

const mapStateToProps = state => {
  return {
    notifications: state.notifications,
    loading: state.notifications.loading,
    isLoggedIn: state.login.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_notifications_data: data => dispatch(get_notifications_data(data))
});

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const NotificationsReport = ({
  classes, isLoggedIn, loading, notifications, get_notifications_data,
}) => {
  useCallback(get_notifications_data, []);

  if (isLoggedIn) {
    if (loading || typeof notifications === 'undefined'){
      return (
        <div className="loader" alt="Loading report..."/>
      );
    }

    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>System messages</h4>
        </CardHeader>
        <CardBody>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
              {notifications.data.slice(0, 50).map((row) => {
                  return (
                    <SnackbarContent
                      key={row.id}
                      message={`${row.text}, ${row.date}`}
                      close
                      color={row.severity>=30 ? "danger" : row.severity>=20 ? "warning" : row.severity>=10 ? "info" : "success"}
                    />
                  );
              })}
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </CardBody>
      </Card>
    );
  } else{
    return (<LoginPage />);
  }
};

const ConnectedNotifications = connect(mapStateToProps, mapDispatchToProps)(NotificationsReport);
export default withStyles(styles)(ConnectedNotifications);
