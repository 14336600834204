import React from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import FrrAnalysis from "./FrrAnalysis";

const styles = {
  contentContainer: {
    marginBottom: '36px'
  },
}

const AfrrAndMfrrAnonymousBids = (props) => {
  const { classes } = props;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FrrAnalysis name='Afrr' address='a' contentClass={classes.contentContainer}/>
        <FrrAnalysis name='Mfrr' address='m'/>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AfrrAndMfrrAnonymousBids);
