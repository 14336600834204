import {
  RECEIVED_MODELS_DATA,
  LOADING_MODEL_DATA,
  RECEIVED_CATEGORIES,
} from 'constants/action-types';

const initialState = {
  parkModels: {
    parkModels: [],
    loading: true,
  },

  //Dictionary of the variable categories
  categoriesDic: {
    categoriesDic: [],
    loading: true,
  },
};

export default (state = initialState, action) => {
  if (action.type === LOADING_MODEL_DATA) {
    return { ...state, ...initialState };
  } else if (action.type === RECEIVED_MODELS_DATA) {
    return {
      ...state,
      parkModels: { parkModels: action.parkModels, loading: false },
    };
  } else if (action.type === RECEIVED_CATEGORIES) {
    return {
      ...state,
      categoriesDic: { categoriesDic: action.categories, loading: false },
    };
  }
  return state;
};
