import { connect } from 'react-redux';

import DataDashboardBase, {
  mapStateToProps, mapDispatchToProps,
} from './DataDashboardBase';

import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';

class DeDataDashboard extends DataDashboardBase {
  prepareRenderParams() {
    return {
      group1: {
        ib_volume: {
          title: 'Imbalance volume',
          variable: 'All sources/Imbalance volume',
          check: 0,
          varType: 'general',
        },
        ib_price: {
          title: 'Imbalance price actual',
          variable: 'All sources/Single Imbalance price',
          check: 0,
          varType: 'general',
        },
        da_price_forecast: {
          title: 'Day-ahead price forecast',
          variable: 'EQ/GME 1200-CET Price Spot Ensemble Forecast',
          check: 1,
          varType: 'specific',
        },
        production_wind_actual: {
          title: 'Wind production actual',
          variable: 'EQ/Wind Actual',
          check: 1,
          varType: 'specific',
        },
        production_wind_forecast: {
          title: 'Wind production forecast',
          variable: 'EQ/Wind Forecast',
          check: 1,
          varType: 'specific',
        },
        production_solar_actual: {
          title: 'Solar production actual',
          variable: 'EQ/Solar Actual',
          check: 1,
          varType: 'specific',
        },
        production_solar_forecast: {
          title: 'Solar production forecast',
          variable: 'EQ/Solar Forecast',
          check: 1,
          varType: 'specific',
        },
        consumption_actual: {
          title: 'Consumption actual',
          variable: 'EQ/Consumption Actual',
          check: 1,
          varType: 'specific',
        },
        consumption_forecast: {
          title: 'Consumption forecast',
          variable: 'EQ/Consumption Forecast',
          check: 1,
          varType: 'specific',
        },
        temperature_actual: {
          title: 'Temperature actual',
          variable: 'EQ/Consumption Temperature Synthetic',
          check: 1,
          varType: 'specific',
        },
        temperature_forecast: {
          title: 'Temperature forecast',
          variable: 'EQ/Consumption Temperature Forecast',
          check: 1,
          varType: 'specific',
        },   
      },
    };
  }
}

const DeDataDashboardConnect = connect(
  mapStateToProps('IT-73I'),
  mapDispatchToProps,
)(DeDataDashboard);
export default withStyles(dashboardStyle)(DeDataDashboardConnect);
