import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {filterRoutes} from 'routes/dashboard.jsx';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    uinfo: state.login.uinfo,
    conn: state.conn,
  };
};

// Need for redirect purposes
// It could be that loggedIn will be true but uinfo will be null

const Loading = (props) => {
  useEffect(() => {
    if (props.uinfo) {
      let visible_items =
        props.uinfo.policy.hasOwnProperty('gui-oam-visible-items') &&
        props.uinfo.policy['gui-oam-visible-items'].length > 0
          ? props.uinfo.policy['gui-oam-visible-items'][0].obj
          : [];
      const defaultRoute = filterRoutes(this.props.conn)
        .filter(
          (route) =>
            visible_items.includes(route.path) ||
            visible_items.includes(route.parent) ||
            visible_items.includes('all')
        )
        .filter((x) => x.hide === undefined || x.hide === false)[0]?.path;
      if (props.uinfo) {
        props.history.push(defaultRoute);
      }
    }
  }, [props.uinfo, props.history]);

  return <div>Loading ...</div>;
};

const LoadingConnect = connect(mapStateToProps)(Loading);
export default LoadingConnect;
