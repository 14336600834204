import {
  get_datadashboard_data,
  get_markets_data,
} from 'actions/index';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import LoginPage from 'views/Login/Oops';

import DashboardCard from './DashboardCard';

export const mapStateToProps = (market) => (state) => ({
  isLoggedIn: state.login.loggedIn,
  market,
  markets: state.markets.markets,
  apiLabel: state.conn.label,
});

export const mapDispatchToProps = (dispatch) => ({
  get_datadashboard_data: (data) => dispatch(get_datadashboard_data(data)),
  getMarkets: () => dispatch(get_markets_data()),
});

class DataDashboardBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkSumColor: 'gray',
      issueMessage: '',
    };
    this.prepareRenderParams = this.prepareRenderParams.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (this.props.fsource === null && nextProps.fsource !== null) ||
      !this.props.markets.length
    );
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.getMarkets();
    }
  }

  loader = (
    <div className={this.props.classes.loadingContainer}>
      <div className="loader" alt="Loading report..." />
    </div>
  );

  render() {
    if (!this.props?.isLoggedIn) {
      return <LoginPage />;
    }
    const groupedCards = this.prepareRenderParams();
    if (this.props.market && this.props.markets.length)
      return (
        <>
          {Object.keys(groupedCards).map((group, groupId) => (
            <Grid container spacing={4} key={groupId}>
              {Object.keys(groupedCards[group]).map((item, itemId) => (
                <Grid item xs={12} sm={6} md={3} key={itemId}>
                  <DashboardCard
                    title={groupedCards[group][item].title}
                    variable={groupedCards[group][item].variable}
                    varType={groupedCards[group][item].varType}
                    check={groupedCards[group][item].check}
                    market={this.props.market}
                    markets={this.props.markets}
                    apiLabel={this.props.apiLabel}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCard
                title={'Check Sum Variables'}
                market={this.props.market}
                type={'checksum'}
                markets={this.props.markets}
                apiLabel={this.props.apiLabel}
              />
            </Grid>
          </Grid>
        </>
      );
    else 
      return this.loader;
  }
}

DataDashboardBase.propTypes = {
  market: PropTypes.string.isRequired,
};

export default DataDashboardBase;
