import React from "react";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
// core components
import { withRouter } from 'react-router'
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { APIS } from "constants/general";

import { pullParksFromInstance } from 'actions/index';
import configureStore from "../../configureStore";
import { buildAPIRequest, security_fetch_params, set_logged_in, setActiveAPI } from "actions/index";

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.loggedIn,
    uinfo: state.login.uinfo,
    conn: state.conn,
    targets: state.login.targets,
  };
};


function mapDispatchToProps(dispatch) {
  return {
    set_logged_in: data => dispatch(set_logged_in(data)),
    setActiveAPI: data => dispatch(setActiveAPI(data)),
  };
}

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      curConn: {},
      filterApis: {},
    }

    this.on_logout = this.on_logout.bind(this)
    this.on_change = this.on_change.bind(this)
  }


  componentDidMount() {
    const targetsArray = this.props.targets?.map((el) => el.label);
    const filterApis = {};
    Object.keys(APIS).forEach((item) => {
      if (targetsArray?.includes(APIS[item].target)) {
        filterApis[item] = APIS[item];
      }
    });

    const notIncludesApis = {};
    const excludeLabels = ['axpo', 'axpo-2', 'axpo-3', 'default', 'mntest', 'swaccount','dp','fr_statarbitrage', 'default@dev005', 'axpo-de', 'axpo-pl', 'axpo-ch', 'kube', 'customer-test', 'backtest_ds', 'customertest', 'ane', 'axpo-ch', 'axpo-pl', 'e2m', 'electroroute', 'eneco', 'energymc', 'ep', 'flexpower', 'pureenergy', 'qsol', 'quadraenergy', 'sunnic', 'strategy1'];
    // loop this.props.targets and check if APIS[item].target is in this.props.targets
    // if it is, add it to notIncludesApis
    // if not also item from this.props.targets, add it to notIncludesApis in the same format as filterApis
    const lowerCaseKeys = Object.entries(APIS).map((el) => el[1].target.toLowerCase());
    if(this.props.targets)
      this.props.targets.forEach((item) => {
        if(!lowerCaseKeys.includes(item.label) && !excludeLabels.includes(item.label)){
          const labelKey = item.label.charAt(0).toUpperCase() + item.label.slice(1);
          notIncludesApis[labelKey] = {
            'API_URL': item.url
            .replace('/account','')
            ,
            target: item.label,
            'label': item.label,
            'parks': [],
            'activePark': null,
            'curPage': 0,
            'curRegion': 'ALL',
            'mainMarket': null,
            'reporting': true,
            'showInstanceReport': false,
          };
        }
      });
    const fullFilterApis = {...filterApis, ...notIncludesApis};
    if (Object.keys(fullFilterApis).length) {
      const defaultConn = Object.values(fullFilterApis)[0];
      defaultConn.label = Object.keys(fullFilterApis)[0];
      const visibleRoutes = this.props.conn.visibleRoutes;
      let curConn;
      if (this.props.conn.API_URL) {
        const conn = {...this.props.conn};
        if (
          Object.keys(fullFilterApis).includes(conn.label)
        ) {
          curConn = {...conn};
        } else {
          curConn = {...defaultConn, visibleRoutes: visibleRoutes};
        }
      } else {
        sessionStorage.setItem('conn', JSON.stringify(defaultConn));
        curConn = defaultConn;
      }
      const sortedKeys = Object.keys(fullFilterApis).sort((a, b) => {
        const aLower = a.toLowerCase();
        const bLower = b.toLowerCase();
        const aFirstUpper = aLower.charAt(0).toUpperCase() + aLower.slice(1);
        const bFirstUpper = bLower.charAt(0).toUpperCase() + bLower.slice(1);
        if (aFirstUpper < bFirstUpper) {
          return -1;
        } else {
          return 1;
        }
      });
      const sortFullFilterApis = {};
      sortedKeys.forEach(key => {
        sortFullFilterApis[key] = fullFilterApis[key];
      });
      this.setState({ curConn: curConn, filterApis: sortFullFilterApis });
      this.props.setActiveAPI(curConn);
    }

    if (this.props.isLoggedIn) {
      if (this.props.conn.parks.length === 0) {
        if (this.props.pull_parks) {
          pullParksFromInstance(this.props.conn)
            .then(function (conn) {})
            .catch((error) => {
              console.log('pullParksFromInstance', error);
            });
        }
      }
    }
  };

  on_change(event) {
    const store = configureStore()
    const login = store.getState().login;
    //const conn = APIS[event.target.value];
    const conn = this.state.filterApis[event.target.value];
    conn.label = event.target.value;
    conn.visibleRoutes = this.props.conn.visibleRoutes;
    this.props.setActiveAPI(conn);
    sessionStorage.setItem('conn', JSON.stringify(conn))
    if (login.loggedIn) {
      pullParksFromInstance(conn).then(conn => {
        this.props.history.push(window.location.pathname);
      }).catch(error => {
        console.log('pullParksFromInstance', error);
      });
    } else {
      conn.parks = [];
    }
  }

  on_logout() {
    const [url, headers] = buildAPIRequest('/logout');
    sessionStorage.removeItem('loggedIn');
    sessionStorage.removeItem('uinfo');
    fetch(url, {
      method: 'GET',
      headers: headers, ...security_fetch_params,
    }).catch(error => {
      console.error('fetch error', error);      
    }).finally(() => {
      this.props.set_logged_in(false);
    });
  }

  render() {
    const store = configureStore();
    const login = store.getState().login;
    if (Object.keys(this.state.filterApis).length && !this.props.isLoggedIn) {
      return (
        <div className={`${this.props.classes.margin} ${this.props.className}`} style={{padding: "5px 0"}}>
          <InputLabel className={this.props.fontStyle} style={{marginBottom: "5px"}}>API: </InputLabel>
          <NativeSelect
            className={this.props.fontStyle}
            onChange={this.on_change}
            defaultValue={
              this.props.conn.label || Object.keys(this.state.filterApis)[0]
            }
            disableUnderline
            inputProps={{
              style: {
                backgroundColor: "#ededed",
                padding: "4px 8px",
                textAlign: "center",
                width: "150px"
              }
            }}
          >
            {Object.entries(this.state.filterApis).map(function (item) {
              return (
                <option key={item[0]} value={item[0]}>
                  {item[0]}
                </option>
              );
            })}
          </NativeSelect>
        </div>
      );
    } else if (this.props.isLoggedIn) {
      return (
        <div className={this.props.classes.margin}>
          {
            <InputLabel className={this.props.classes.whiteFont}>
              API:{' '}
              <a href={this.props.conn.API_URL}>
                {this.props.conn.label || Object.keys(this.state.filterApis)[0]}
              </a>
            </InputLabel>
          }
          {login.loggedIn ? (
            <a href="/" onClick={this.on_logout}>
              Log Out
            </a>
          ) : (
            ''
          )}
        </div>
      );
    } else {
      return <></>;
    };
  }
}

const DecoratedHeaderLinks = connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
export default withRouter(withStyles(headerLinksStyle)(DecoratedHeaderLinks));

