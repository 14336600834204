import {
  RECEIVED_DASHBOARD_DATA,
  LOADING_DASHBOARD_DATA,
} from "constants/action-types";
import moment from 'moment';

const initialState = {
  da_prices: {today: 'N/A', tomorrow: 'N/A'},
  num_optimisations: 'N/A',
  da_optimisation: {expected_profit: 0, computing_finish: '2018-01-01', dates: [], Pd: []},
  id_optimisation: {expected_profit: 0, computing_finish: '2018-01-01', dates: [], Pd: []},
  rte_imbalance_ratio: {today: 'N/A', yesterday: 'N/A'},
  epex_transactions: {today: [{count: 'N/A', country: "DE"},{count: 'N/A', country: "FR"}], yesterday: [{count: 'N/A', country: "DE"},{count: 'N/A', country: "FR"}]},
  lock: [{"2019-11-01": "OK"}, {"2019-11-02": "OK"}],
  loading: true,
};

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export default (state = initialState, action) => {
  if (action.type === LOADING_DASHBOARD_DATA) {
    return { ...state, ...initialState };
  } else if (action.type === RECEIVED_DASHBOARD_DATA) {
    const {
      da_prices,
      num_optimisations,
      id_optimisation,
      da_optimisation,
      RTE_imbalance_ratio,
      epex_transactions,
      market,
    } = action.dashboard;

    let lock = [{"2019-11-01": "OK"}, {"2019-11-02": "OK"}];
    if (action.dashboard.lock !== undefined) {
      lock = action.dashboard.lock.map((x) => {
        return { [x.substring(0, 8)]: x.substring(9,11) };
      });
    }

    const id_optimisation_date = moment(id_optimisation.computing_finish)
      .tz('CET');
    const da_optimisation_date = moment(da_optimisation.computing_finish)
      .tz('CET');

    return {
      ...state,
      da_prices,
      num_optimisations,
      id_optimisation: {
        ...id_optimisation,
        // 0 means min date: 1970-01-01
        computing_finish: id_optimisation_date.unix() !== 0
          ? `${id_optimisation_date.format(DATETIME_FORMAT)}, CET`
          : 'Not scheduled',
      },
      da_optimisation: {
        ...da_optimisation,
        computing_finish: da_optimisation_date.unix() !== 0
          ? `${da_optimisation_date.format(DATETIME_FORMAT)}, CET`
          : 'Not scheduled',
      },
      rte_imbalance_ratio: RTE_imbalance_ratio,
      epex_transactions,
      lock,
      market,
      loading: false,
    };
  }

  return state;
};
