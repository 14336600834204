import {
  LOADING_STRUCTURED_ALERTS,
  RECEIVED_STRUCTURED_ALERTS,
  RECEIVED_COMPL_EXCS,
} from "constants/action-types";

const initialState = {
  alerts: [],
  compl_excs: [],
  loading: true,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_STRUCTURED_ALERTS) {
    return { ...state, ...initialState };
  } else if (action.type === RECEIVED_STRUCTURED_ALERTS) {
    return {
        ...state,
        alerts: action.alerts,
        masterAlert: action.masterAlert,
        loading: false,
    };
  } else if (action.type === RECEIVED_COMPL_EXCS) {
    return {
      ...state,
      compl_excs: action.compl_excs,
    };
  }

    return state;
};
