import {LOADING_DATADASHBOARD_DATA, RECEIVED_DATADASHBOARD_DATA} from "constants/action-types";

const initialState = {
  'FR': {
    fsource: null,
    finfo: null,
    ainfo: null,
    loading: true,
  },
  'DE': {
    fsource: null,
    finfo: null,
    ainfo: null,
    loading: true,
  },
  'GB': {
    fsource: null,
    finfo: null,
    ainfo: null,
    loading: true,
  },
  'IT-788': {
    fsource: null,
    finfo: null,
    ainfo: null,
    loading: true,
  },
  'IT-73I': {
    fsource: null,
    finfo: null,
    ainfo: null,
    loading: true,
  },
};

export default (state = initialState, action) => {
  if (action.type === LOADING_DATADASHBOARD_DATA) {
    return { ...state, ...initialState };
  } else if (action.type === RECEIVED_DATADASHBOARD_DATA) {
    return {
      ...state,
      [action.market]: {
        ...action.dashboard,
        loading: false,
      },
    };
  }

  return state;
};
