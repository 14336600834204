import { useEffect, useState } from "react";
import { stocksChartOptions, makeSeries } from "../variables/charts";

export const useFrrChartData  = (products, selectedDates, visibleLegends, frrData, name) => {
    const [frrChartData, setFrrChartData] = useState([]);

    useEffect(() => {
        if (frrChartData.series?.length) {
          const visibleSerieses = frrChartData.series.map((el) => ({
            ...el,
            visible: visibleLegends,
          }));
          setFrrChartData({ ...frrChartData, series: visibleSerieses });
        }
        // eslint-disable-next-line
    }, [visibleLegends]);
    
    useEffect(() => {
        if (Object.keys(frrData).length) {
            const serieses = [];
            if (selectedDates.length && products.length) {
            selectedDates.forEach((date, i) => {
                products.forEach((prod, j) => {
                if (frrData[date][prod]) {
                    serieses.push(
                    makeSeries(
                        `${date} ${prod}`,
                        frrData[date][prod].map((el) => [el[2], el[0]]),
                        {
                        valueDecimals: 2,
                        }
                    )
                    );
                }
                });
            });
            }

            const minPrice = serieses
            .map((el) => el.data.map((el) => el[0]))
            .flat()
            .sort((a, b) => a - b)[0];

            const addOtions = {
            yAxis: [
                {
                title: {
                    text: 'Price EUR/MWh',
                },
                opposite: false,
                },
            ],
            xAxis: {
                ordinal: false,
                title: {
                text: 'Acc. Allocated Volume MW',
                },
                type: 'linear',
                min: minPrice < 0 ? minPrice : 0,
                max: 1500,
            },
            rangeSelector: {
                enabled: false,
            },
            navigator: {
                enabled: false,
            },
            plotOptions: {
                series: {
                pointStart: minPrice < 0 ? minPrice : 0,
                pointInterval: 1500,
                },
            },
            };
            const frrChartData = {
            ...stocksChartOptions({ filename: name }),
            ...addOtions,
            series: [...serieses],
            };

            setFrrChartData(frrChartData);
        }
    }, [products, frrData, selectedDates, name]);

    return frrChartData
}
