// ##############################
// // // javascript library for creating charts
// #############################
import Chartist from 'chartist';
import './highcharts.css';

// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
  durations = 500;

// ##############################
// // // Email Subscriptions
// #############################

const emailsSubscriptionChart = {
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    axisX: {
      showGrid: false
    },
    low: -100000,
    high: 100000,
    chartPadding: {
      top: 15,
      right: 5,
      bottom: 0,
      left: 15
    }
  },
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

// Highcharts options
const plotOptions = {
  area: {
    fillColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
      },
    },
    marker: {
      radius: 2,
    },
    lineWidth: 1,
    states: {
      hover: {
        lineWidth: 1,
      },
    },
    threshold: null,
  },
};

const xAxisInCET = {
  xAxis: {
    ordinal: false,
    title: {
      text: 'Time, CET',
    },
    type: 'datetime',
  },
};

const xAxisInUTC = {
  xAxis: {
    ordinal: false,
    title: {
      text: 'Time, UTC',
    },
    type: 'datetime',
  },
};

const exportingOptions = {
  buttons: {
    contextButton: {
      menuItems: [
        "downloadCSV",
        "separator",
        "downloadPNG",
        "downloadSVG",
        "downloadJPEG",
        "downloadPDF",
        "separator",
        "viewFullscreen",
        "printChart",
      ],
      theme: {
        fill: 'transparent',
      },
    },
  },
};

const navigationOptions = {
  menuItemStyle: {
    color: '#3C4858',
    fontSize: '0.9rem',
  },
  menuItemHoverStyle: {
    background: '#9c27b0',
    color: '#FFFFFF',
  },
};

const rangeSelectorOptions = {
  allButtonsEnabled: true,
  verticalAlign: 'top',
  x: 0,
  y: 0,
  inputDateFormat: '%d.%m.%Y',
  inputEditDateFormat: '%d.%m.%Y',
  inputStyle: {
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'none',
    fontSize: '1rem',
    lineHeight: 1.3,
    "&:hover": {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
};

// Highcharts Stocks Chart Options
const stocksChartOptions = (props = {}) => {
  const { filename, events, title, legend, rangeSelector, ...rest } = props;

  return {
    chart: {
      animation: false,
      zoomType: 'x',
      backgroundColor: 'transparent',
      height: 500,
      ...(events ? { events } : {}),
    },
    accessibility: {
      enabled: false,
    },
    colors: [
      "#434348", "#7cb5ec", "#90ed7d", "#f7a35c", "#8085e9",
      "#f15c80", "#2b908f", "#f45b5b", "#91e8e1",
      '#FF6633', '#FFB399', '#FF33FF', '#00B3E6', 
      '#3366E6', '#999966', '#99FF99', '#B34D4D',
      '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
      '#FF99E6', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
      '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
      '#4D8066', '#809980', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    ],
    exporting: {
      ...exportingOptions,
      ...(filename ? { filename } : {}),
      csv: {
        itemDelimiter: ',',
        decimalPoint: '.', 
      }
    },
    navigation: {
      ...navigationOptions,
    },
    rangeSelector: {
      ...rangeSelectorOptions,
      ...(rangeSelector ? rangeSelector : {}),
    },
    title: {
      text: title ?? '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
      ...(legend ? legend : {}),
    },
    xAxis: {
      ordinal: false,
      title: {
        text: 'Time, UTC',
      },
      type: 'datetime',
    },
    tooltip: {
      shared: true,
    },
    plotOptions,
    ...rest,
  };
};

const makeSeries = (name, data, props = {}) => {
  const { legendIndex, valueDecimals, turboThreshold, yAxis, ...rest } = props;
  return {
    name,
    data,
    animation: true,
    tooltip: {
      valueDecimals: valueDecimals ?? 3,
    },
    yAxis: yAxis ?? 0,
    legendIndex: legendIndex ?? 0,
    ...(turboThreshold ? { turboThreshold } : {}),
    ...rest,
  };
};

// Plotly Scatter Chart Options
const makePlotOptions = (props = {}) => {
  const { x, y, name, ...rest } = props;
  return {
    x: [x],
    y: [y],
    name,
    type: 'scatter',
    mode: 'markers',
    hoverinfo: 'text',
    marker: {
      size: 12,
    },
    ...rest,
  };
};

export {
  emailsSubscriptionChart,
  xAxisInCET,
  xAxisInUTC,
  stocksChartOptions,
  makeSeries,
  makePlotOptions,
};
