// eslint-disable-next-line
import React from "react";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import MaterialTable from "@material-table/core";
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CardFooter from "components/Card/CardFooter.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import LoginPage from "views/Login/Oops.jsx";
import Orders from 'components/Optimisation/Orders';
import Trades from 'components/Optimisation/Trades';
import {
    get_rtrader_data,
    get_rtrader_marketdepth,
    get_rtrader_markettrades,
    getDefaultPark,
} from "actions/index";
import { DEFAULT_PAGE_SIZE } from "constants/general";
import classNames from 'classnames';
import moment from 'moment';
import 'moment-timezone';

const mapStateToProps = state => {
    return {
        loading: state.rtrader.loading, isLoggedIn: state.login.loggedIn, delivery_hours: state.rtrader.delivery_hours,
        orders: state.rtrader.orders, trades: state.rtrader.trades, marketdepth: state.rtrader.marketdepth,
        markettrades: state.rtrader.markettrades,
        marketdepth_loading: state.rtrader.marketdepth_loading, markettrades_loading: state.rtrader.markettrades_loading,
        country_iso3166a2: state.rtrader.country_iso3166a2
    };
};

const mapDispatchToProps = (dispatch) => ({
    get_rtrader_data: data => dispatch(get_rtrader_data(data)),
    get_rtrader_marketdepth: data => dispatch(get_rtrader_marketdepth(data)),
    get_rtrader_markettrades: data => dispatch(get_rtrader_markettrades(data)),
});

const styles = {
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    spacingBottom: {
      marginBottom: '1.75rem',
    },
    tableCell: {
        padding: '1px 0',
    }
};


class RTrader extends React.Component {
    constructor() {
        super();
        const park = getDefaultPark() || {id: -1}
        this.state = {
            value: 0,
            delivery_hour: "",
            portfolio: park.id,
            expanded: false,
            booksexpanded: false,
            tradesexpanded: true,
            bids: [[30.5, 0.9], [31.3, 3.9], [32.2, 76.7]],
            asks: [[35.1, 0.1], [38.7, 9.4], [45.6, 54.1]],
            imbalance_price_up: 0,
            imbalance_price_down: 0,
            generation: 0,
            da_volume: 0,
            da_price: 0,
            base_volume: 0,
            loading_hour_recap: false,
            country_iso3166a2: 'FR',
            conf_extend: false,
            list_top: DEFAULT_PAGE_SIZE,
            list_top_trades: DEFAULT_PAGE_SIZE,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleExpandTrades = this.handleExpandTrades.bind(this);
        this.handleBooksExpandClick = this.handleBooksExpandClick.bind(this);
    }

    handleChange(event) {
      const newState = {
        ...this.state,
        [event.target.name]: event.target.value,
      };
      if (event.target.name === 'list_top_trades') {
        const { value } = event.target;
        const { delivery, country_iso3166a2 } = this.state;

        // Load data immediately after value was changed
        this.props.get_rtrader_markettrades({
          delivery,
          country_iso3166a2,
          pageSize: value,
        });
      }
      if (event.target.name === 'country_iso3166a2') {
        this.props.get_rtrader_data({
          wpark_id: event.target.value,
          pageid: 1,
          psize: 100,
        });
        newState.delivery_hour = '';
        newState.booksexpanded = false;
      }
      if (event.target.name === 'delivery_hour'
        || event.target.name === 'country_iso3166a2') {
        clearInterval(this.interval1);
        clearInterval(this.interval2);
        let delivery = newState.delivery_hour;
        let country = newState.country_iso3166a2;

        if (event.target.name === 'delivery_hour') {
          delivery = event.target.value;
        }

        if (event.target.name === 'country_iso3166a2') {
          country = event.target.value;
        }
        this.props.get_rtrader_marketdepth({
          delivery,
          country_iso3166a2: country,
        });
        newState.booksexpanded = true;

        this.props.get_rtrader_markettrades({
          delivery,
          country_iso3166a2: country,
          pageSize: newState.list_top_trades,
        });

        this.interval1 = setInterval(() => this.refresh_marketdepth(), 10000);
        this.interval2 = setInterval(() => this.refresh_markettrades(), 10000);

        newState.loading_hour_recap = true;
      }

      this.setState(newState);
    }

    refresh_marketdepth() {
        this.props.get_rtrader_marketdepth({ 'delivery': this.state.delivery_hour, 'country_iso3166a2': this.state.country_iso3166a2 });
    }

    refresh_markettrades() {
        this.props.get_rtrader_markettrades({
            'delivery': this.state.delivery_hour,
            'country_iso3166a2': this.state.country_iso3166a2,
            pageSize: this.state.list_top_trades,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== undefined) {
            if (nextProps.isLoggedIn === true && this.state.value === 0) {
                this.props.get_rtrader_data({ 'wpark_id': getDefaultPark().id, 'pageid': 1, 'psize': 100 });
                this.setState({ value: 1 });
            }
        }
        return true;
    }

    componentWillUnmount() {
        clearInterval(this.interval1);
        clearInterval(this.interval2);
    }

    componentDidMount() {
        if (this.props.isLoggedIn) {
            this.props.get_rtrader_data({ 'wpark_id': getDefaultPark().id, 'pageid': 1, 'psize': 100 });
            this.setState({ value: 1 });
        }
    }

    handleExpandClick() {
        var newstate = this.state;
        newstate.expanded = !newstate.expanded;
        this.setState(newstate);
    }


    handleExpandTrades() {
        var newstate = this.state;
        newstate.tradesexpanded = !newstate.tradesexpanded;
        this.setState(newstate);
    }

    handleBooksExpandClick() {
        var newstate = this.state;
        newstate.booksexpanded = !newstate.booksexpanded;
        this.setState(newstate);
    }

    render() {
        const { classes } = this.props;
        if (this.props && this.props.isLoggedIn) {
            if (this.props.delivery_hours === undefined) {
                return (<div />);
            }
            else {
                var that = this;
                var deliveryts = moment.tz(this.state.delivery_hour, 'UTC').unix();
                var hours = this.props.delivery_hours.map(function (hour, index) {
                    return (<MenuItem key={index} value={moment.tz(hour, 'UTC').format('YYYY-MM-DD HH:mm')}>{moment.tz(hour, 'UTC').format('YYYY-MM-DD HH:mm')}</MenuItem>);
                });

                const optionsMatTable = {
                    toolbar: false,
                    tableLayout: "fixed",
                    paging: false,
                    maxBodyHeight: window.innerHeight - 330,
                    headerStyle: {
                      position: "sticky", 
                      top: 0, 
                      zIndex: 2, 
                    },
                    emptyRowsWhenPaging: false,
                    pageSize: 6,
                    draggable: false,
                    search: false,
                    cellStyle: {
                        textAlign: 'center',
                    },
                };

                const mainColumnSettings = (head) => {
                    const title = head.split('_')[0];
                    return {
                    title: title,
                    field: head.toLowerCase(),
                    sorting: false,
                    width: 45,
                    textAlign: 'center',
                    headerStyle: {
                        fontSize: '1em',
                        padding: '1em 0',
                        textAlign: 'center',
                    },
                }};

                // Prepare all fro live trades table
                const columnsHeadLiveTrades = ['VWAP_bid', 'Acc_bid', 'Qty_bid', 'Bid_bid', 'Ask_ask', 'Qty_ask', 'Acc_ask', 'VWAP_ask'];
                const columnsLiveTrades = columnsHeadLiveTrades.map(head => mainColumnSettings(head));

                let dataLiveTrades = [];
                
                if (this.props.marketdepth !== undefined && this.state.delivery_hour !== '') {
                    if (this.props.marketdepth[deliveryts] !== undefined) {

                        var accumulated_bids = this.props.marketdepth[deliveryts].data.filter(x => x.side === 'BUY').sort(function (a, b) { return b.px - a.px }).slice(0, this.state.list_top);
                        var total = 0.0;
                        let vwap = 0;
                        for (let i = 0; i < accumulated_bids.length; i++) {
                            vwap = (vwap * total + accumulated_bids[i].qty * accumulated_bids[i].px) / (total + accumulated_bids[i].qty)
                            total += accumulated_bids[i].qty;
                            accumulated_bids[i]['accumulated'] = total;
                            accumulated_bids[i]['VWAP'] = vwap;
                        }

                        var accumulated_asks = this.props.marketdepth[deliveryts].data.filter(x => x.side === 'SELL').sort((a, b) => (a.px < b.px) ? 1 : -1).slice(-this.state.list_top - 1, -1);
                        total = 0.0;
                        vwap = 0;
                        for (let i = 0; i < accumulated_asks.length; i++) {
                            vwap = (vwap * total +
                                accumulated_asks[accumulated_asks.length - 1 - i].qty * accumulated_asks[accumulated_asks.length - 1 - i].px) /
                                (total + accumulated_asks[accumulated_asks.length - 1 - i].qty)
                            total += accumulated_asks[accumulated_asks.length - 1 - i].qty;
                            accumulated_asks[accumulated_asks.length - 1 - i]['accumulated'] = total;
                            accumulated_asks[accumulated_asks.length - 1 - i]['VWAP'] = vwap;
                        }
                        
                        const data =  accumulated_bids.length > accumulated_asks.length ? accumulated_bids : accumulated_asks;
                        dataLiveTrades = data.map((depth, index) => {
                            return {
                                'vwap_bid': accumulated_bids.length > index ? (accumulated_bids[index].VWAP).toFixed(2) : '',
                                'acc_bid': accumulated_bids.length > index ? (accumulated_bids[index].accumulated).toFixed(1) : '',
                                'qty_bid': accumulated_bids.length > index ? (accumulated_bids[index].qty).toFixed(1) : '',
                                'bid_bid': accumulated_bids.length > index ? (accumulated_bids[index].px).toFixed(2) : '',
                                'ask_ask': accumulated_asks.length > index ? (accumulated_asks[accumulated_asks.length - 1 - index].px).toFixed(2) : '',
                                'qty_ask': accumulated_asks.length > index ? (accumulated_asks[accumulated_asks.length - 1 - index].qty).toFixed(1) : '',
                                'acc_ask': accumulated_asks.length > index ? (accumulated_asks[accumulated_asks.length - 1 - index].accumulated).toFixed(1) : '',
                                'vwap_ask': accumulated_asks.length > index ? (accumulated_asks[accumulated_asks.length - 1 - index].VWAP).toFixed(2) : '',
                            }
                        });
                    }
                };

                // Prepare all fro latest trades table
                const columsHeadLatestTrades = ['Trade Time', 'Volume, MW', 'Price, \u20AC'];
                const columnsLatestTrades = columsHeadLatestTrades.map(head => mainColumnSettings(head));
                let dataLatestTrades = [];
                if (this.props.markettrades !== {} && this.props.markettrades[deliveryts] !== undefined)
                {
                    this.props.markettrades[deliveryts].data.slice(0, this.state.list_top_trades).forEach((trade) => {
                        dataLatestTrades.push({
                            ...trade, 
                            'trade time': moment.tz(trade.tradeExecTime, 'UTC').format('YYYY-MM-DD HH:mm:SS'),
                            'volume, mw': trade.qty,
                            'price, \u20AC': trade.px
                        });
                    });
                }
                
                var openPosition = this.props.trades.reduce(function (total, currentValue, currentIndex, arr) {
                    if (currentValue.side === 'BUY') {
                        return total + currentValue.qty;
                    } else {
                        return total - currentValue.qty;
                    }
                }, 0);
                if (Math.abs(openPosition) < 0.01) {
                    openPosition = 0.00;
                }
                var intraDayProfit = this.props.trades.reduce(function (total, currentValue, currentIndex, arr) {
                    if (currentValue.side === 'BUY') {
                        return total - currentValue.qty / 1000.0 * currentValue.px / 100.0;
                    } else {
                        return total + currentValue.qty / 1000.0 * currentValue.px / 100.0;
                    }
                }, 0);

                var newTableData = this.props.trades === undefined ? [] : this.props.trades.sort((a, b) => (a.execTime > b.execTime) ? 1 : -1).map(x => [x.tradeId, that.state.delivery_hour, x.side, x.px,
                x.qty, x.execTime, x.state, x.ordrId]);
                let vwap = 0;
                if (newTableData.length > 0) {
                    if (Math.abs(openPosition) > 0) {
                        var totalVolume = 0;
                        var totalQuantity = 0;
                        for (let i = 0; i < this.props.trades.length; i++) {
                            if (totalQuantity === 0) {
                                totalVolume = 0;
                            }
                            var qty = this.props.trades[i].side === 'BUY' ?
                                this.props.trades[i].qty : - this.props.trades[i].qty;
                            totalQuantity = totalQuantity + qty;
                            totalVolume = totalVolume + this.props.trades[i].px * qty;
                        }
                        vwap = totalVolume / totalQuantity;
                    }
                    newTableData.push([0, '', openPosition > 0.01 ? "LONG" : (openPosition < -0.01 ? "SHORT" : "SQUARE"), vwap, openPosition]);
                }
                var added_value = this.state.da_price * this.state.da_volume + intraDayProfit +
                    (this.state.generation + openPosition - this.state.da_volume) *
                    (this.state.generation + openPosition - this.state.da_volume < 0 ? this.state.imbalance_price_down : this.state.imbalance_price_up) -
                    this.state.da_price * this.state.base_volume -
                    (this.state.generation - this.state.base_volume) *
                    (this.state.generation - this.state.base_volume < 0 ? this.state.imbalance_price_down : this.state.imbalance_price_up);
                
                return (
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>Select orders & trades for:</h4>
                                </CardHeader>
                                <CardBody style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <FormControl className={classes.formControl}
                                        style={{ width: '47%', }}
                                    >
                                        <InputLabel htmlFor="portfolio-helper">Country</InputLabel>
                                        <Select
                                            id="country_iso3166a2"
                                            name="country_iso3166a2"
                                            value={this.state.country_iso3166a2}
                                            onChange={this.handleChange}
                                            style={{ marginRight: '15px', width: '100%' }}
                                            input={<Input name="country_iso3166a2" id="country_iso3166a2" />}
                                        >
                                            <MenuItem value={'FR'}>France</MenuItem>
                                            <MenuItem value={'DE'}>Germany</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl className={classes.formControl}
                                        style={{ width: '47%' }}
                                    >
                                        <InputLabel htmlFor="deliver">Delivery hour</InputLabel>
                                        <Select
                                            id="delivery_hour"
                                            name="delivery_hour"
                                            value={this.props.orders.length === 0 || this.state.delivery_hour !== '' ? this.state.delivery_hour :
                                                moment.tz(this.props.orders[0].delivery_start, 'UTC').format('YYYY-MM-DD HH:mm')}
                                            onChange={this.handleChange}
                                            style={{ marginRight: '15px', width: '100%' }}
                                            input={<Input name="delivery_hour" id="delivery_hour" />}
                                        >
                                            {hours}
                                        </Select>
                                    </FormControl>
                                </CardBody>
                                <CardActions style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                    <IconButton
                                        disabled={this.state.delivery_hour === ''}
                                        onClick={this.handleBooksExpandClick}
                                        aria-expanded={this.state.booksexpanded}
                                        aria-label="show more"
                                    > <small>Order books</small>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                    <h6>Show Top Orders: </h6><input type="text" name="list_top" value={this.state.list_top} onChange={this.handleChange} size="5" />
                                    {this.props.marketdepth_loading
                                    ? (
                                      <div
                                        className={classNames('loader', 'sizeS', 'relative')}
                                        alt="Loading..."
                                      />
                                    ) : null}
                                </CardActions>
                                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                    <CardContent style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                                        <div className="container">
                                            <div style={{ textAlign: 'center' }}>
                                                <h3 style={{ display: 'inline-block', paddingTop: '3px', marginRight: '5px' }}>Intraday Position: </h3>
                                                <h3 style={{
                                                    display: Math.abs(openPosition) > 0 ? 'inline-block' : 'none',
                                                    paddingTop: '3px', marginRight: '5px',
                                                    color: openPosition > 0 ? 'green' : (openPosition === 0 ? 'black' : 'red'), fontWeight: 600
                                                }}>
                                                    {openPosition > 0 ? '+' + openPosition.toFixed(1) : openPosition.toFixed(1)} </h3>
                                                <h3 style={{
                                                    display: Math.abs(openPosition) > 0 ? 'inline-block' : 'none',
                                                    paddingTop: '3px', marginRight: '5px'
                                                }}>MW</h3>
                                                <h3 style={{
                                                    display: Math.abs(openPosition) > 0 ? 'inline-block' : 'none',
                                                    paddingTop: '3px', marginRight: '5px'
                                                }}>@</h3>
                                                <h3 style={{
                                                    display: Math.abs(openPosition) > 0 ? 'inline-block' : 'none',
                                                    paddingTop: '3px', marginRight: '25px'
                                                }}>{vwap.toFixed(2)} &euro;</h3>
                                                <h3 style={{
                                                    display: Math.abs(openPosition) > 0 ? 'none' : 'inline-block',
                                                    paddingTop: '3px', marginRight: '25px'
                                                }}>SQUARE</h3>
                                                <div style={{ display: 'inline' }}>
                                                    <TextField
                                                        name="quantity"
                                                        label="Quantity, MW"
                                                        value={this.state.quantity}
                                                        onChange={this.handleChange}
                                                        type="number"
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        margin="normal"
                                                        variant="filled"
                                                        style={{ marginRight: '25px', }}
                                                    />
                                                    <Button variant="contained" color="primary"
                                                        disabled={deliveryts <= moment.tz(moment(), 'UTC').unix()}
                                                        style={{ marginTop: '0px', marginRight: '25px' }} className={classes.button}>
                                                        BUY
                                            </Button>
                                                    <Button variant="contained"
                                                        disabled={deliveryts <= moment.tz(moment(), 'UTC').unix()}
                                                        color="secondary" style={{ marginTop: '0px' }} className={classes.button}>
                                                        SELL
                                            </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Collapse>
                                <Collapse in={this.state.booksexpanded} timeout="auto" unmountOnExit style={{ padding: '0px' }}>
                                    <CardContent style={{
                                        padding: '0px',
                                        display: deliveryts
                                        && (typeof this.props.marketdepth[deliveryts] !== 'undefined')
                                        && (this.props.marketdepth[deliveryts].data.length > 0)
                                        ? 'block' : 'none',
                                    }}>
                                        <MaterialTable 
                                            columns={columnsLiveTrades}
                                            data={dataLiveTrades}
                                            options={optionsMatTable}
                                            onRowClick={(event) => {}}
                                        />
                                    </CardContent>
                                </Collapse>

                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>Latest Trades</h4>
                                </CardHeader>
                                <CardActions style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                    <IconButton
                                        onClick={this.handleExpandTrades}
                                        aria-expanded={this.state.tradesexpanded}
                                        aria-label="show more"
                                    > <small>Latest Trades</small>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                    <h6>Shown Latest Trades</h6><input type="text" name="list_top_trades" value={this.state.list_top_trades} onChange={this.handleChange} size="5" />
                                    {this.props.markettrades_loading
                                    ? (
                                      <div
                                        className={classNames('loader', 'sizeS', 'relative')}
                                        alt="Loading..."
                                      />
                                    ) : null}
                                </CardActions>
                                <CardContent style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                                    <Collapse in={this.state.tradesexpanded} timeout="auto" unmountOnExit style={{ padding: '0px' }}>
                                        <MaterialTable 
                                            columns={columnsLatestTrades}
                                            data={dataLatestTrades}
                                            options={optionsMatTable}
                                            onRowClick={(event) => {}}
                                        />
                                    </Collapse>
                                </CardContent>
                            </Card>
                        </Grid>



                        <Grid container spacing={4}>
                            <Grid item style={{
                                display: (this.state.delivery_hour === '' || this.state.loading_hour_recap ||
                                    moment.duration(moment.tz(new Date(), 'UTC').diff(moment.tz(this.state.delivery_hour, 'UTC'))).asHours() < -940) ? 'none' : 'block'
                            }}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader color="primary">
                                        <p className={classes.cardCategory}>Hour Recap</p>
                                    </CardHeader>
                                    <CardBody>
                                        <h5 style={{ display: 'inline-block', paddingTop: '3px', marginRight: '5px' }}>Intraday Position: </h5>
                                        <h5 style={{
                                            display: Math.abs(openPosition) > 0 ? 'inline-block' : 'none',
                                            paddingTop: '3px', marginRight: '5px',
                                            color: openPosition > 0 ? 'green' : (openPosition === 0 ? 'black' : 'red'), fontWeight: 600
                                        }}>
                                            {openPosition > 0 ? '+' + openPosition.toFixed(1) : openPosition.toFixed(1)} </h5>
                                        <h5 style={{
                                            display: Math.abs(openPosition) > 0 ? 'inline-block' : 'none',
                                            paddingTop: '3px', marginRight: '5px'
                                        }}>MW</h5>
                                        <h5 style={{
                                            display: Math.abs(openPosition) > 0 ? 'inline-block' : 'none',
                                            paddingTop: '3px', marginRight: '5px'
                                        }}>@</h5>
                                        <h5 style={{
                                            display: Math.abs(openPosition) > 0 ? 'inline-block' : 'none',
                                            paddingTop: '3px', marginRight: '25px'
                                        }}>{vwap.toFixed(2)} &euro;</h5>
                                        <h5 style={{
                                            display: Math.abs(openPosition) > 0 ? 'none' : 'inline-block',
                                            paddingTop: '3px', marginRight: '25px'
                                        }}>SQUARE {(intraDayProfit > 0 ? '+' : '') + intraDayProfit.toFixed(2)} &euro;</h5>
                                        <p style={{ margin: 0, }}></p>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>DA position: </h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>{this.state.da_volume - this.state.base_volume > 0 ?
                                            '-' + (this.state.da_volume - this.state.base_volume).toFixed(2) : '+' + (this.state.base_volume - this.state.da_volume).toFixed(2)}</h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>@ </h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, marginRight: '10px', }}>{this.state.da_price.toFixed(2)} &euro;</h5>

                                        <p style={{ margin: 0, }}></p>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>Optimised Imbalance: </h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>{(-this.state.da_volume + this.state.generation + openPosition).toFixed(2)}</h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>@ </h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, marginRight: '10px', }}>{(this.state.generation + openPosition - this.state.da_volume > 0 ?
                                            this.state.imbalance_price_up : this.state.imbalance_price_down).toFixed(3)} &euro;</h5>

                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>Base Imbalance: </h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>{(-this.state.base_volume + this.state.generation).toFixed(2)}</h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>@ </h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, marginRight: '10px', }}>{(this.state.generation - this.state.base_volume > 0 ?
                                            this.state.imbalance_price_up : this.state.imbalance_price_down).toFixed(3)} &euro;</h5>

                                        <p style={{ margin: 0, }}></p>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>Optimized P&L: </h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, marginRight: '10px' }}>{(this.state.da_price * this.state.da_volume - openPosition * vwap +
                                            (this.state.generation + openPosition - this.state.da_volume) *
                                            (this.state.generation + openPosition - this.state.da_volume < 0 ? this.state.imbalance_price_down : this.state.imbalance_price_up)).toFixed(2)} &euro;</h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>Base P&L: </h5>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, }}>{(this.state.da_price * this.state.base_volume +
                                            (this.state.generation - this.state.base_volume) *
                                            (this.state.generation - this.state.base_volume < 0 ? this.state.imbalance_price_down : this.state.imbalance_price_up)).toFixed(2)} &euro;</h5>
                                        <p style={{ margin: 0, }}></p>
                                        <h5 style={{ display: 'inline-block', marginTop: 0, marginRight: '5px', fontWeight: 'bold' }}>Added Value: </h5>
                                        <h5 style={{
                                            display: 'inline-block', marginTop: 0, fontWeight: 'bold',
                                            color: added_value > 0 ? 'green' : (added_value === 0 ? 'black' : 'red')
                                        }}>{added_value.toFixed(2)} &euro;</h5>
                                    </CardBody>
                                    <CardFooter stats>
                                        <div className={classes.stats}>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Grid>
                            <Grid item style={{ display: (this.props.orders === undefined || this.props.orders.length === 0 ? 'none' : 'block') }} xs={12}>
                                <Card>
                                    <CardHeader color="primary">
                                        <p className={classes.cardCategory}>Orders</p>
                                    </CardHeader>
                                    <CardBody>
                                        <Orders
                                            orders={this.props.orders}
                                            tableHead={['OrderID', 'Delivery', 'Side', 'Price', 'Initial Qty', 'Quantity',
                                                'Time', 'Validity', 'State', 'Status', 'Action']}
                                            tableData={this.props.orders === undefined ? [] :
                                                this.props.orders.sort((a, b) => (a.lastUpdateTm > b.lastUpdateTm) ? 1 : -1).map(x => [that.state.delivery_hour, x.side, x.px / 100.0, x.initialQty / 1000.0,
                                                x.qty / 1000.0, x.validityDate, x.lastUpdateTm, x.state, x.ordrId, x.action])}
                                        />

                                    </CardBody>
                                    <CardFooter stats>
                                        <div className={classes.stats}>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Grid>
                            <Grid item style={{ display: (this.props.trades.length === 0 ? 'none' : 'block') }} xs={12}>
                                <Card>
                                    <CardHeader color="primary">
                                        <p className={classes.cardCategory}>Trades</p>
                                    </CardHeader>
                                    <CardBody>
                                        <Trades
                                            trades={this.props.trades}
                                            tableHead={['TradeID', 'Delivery', 'Side', 'Price', 'Quantity', 'Time', 'State', 'OrderID']}
                                            tableData={newTableData}
                                        />

                                    </CardBody>
                                    <CardFooter stats>
                                        <div className={classes.stats}>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        }
        else {
            return (<LoginPage />);
        }
    }
}

const ConnectedRTrader = connect(mapStateToProps, mapDispatchToProps)(RTrader);
export default withStyles(styles)(ConnectedRTrader);
