import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import MaterialTable from '@material-table/core';
import PropTypes from 'prop-types';

class ProfitLossReportDetails extends Component {
  render() {
    const { classes, tableHead, tableData, winHeight, currency } = this.props;

    const columns = [];
    tableHead.forEach((prop, key) => {
      if (key === 0) {
        columns.push({
          title: 'Hour',
          field: 'hour',
          width: 80,
          sorting: false,
        });
      } else {
        columns.push({
          title: `${prop} Volume`,
          field: `${prop} Volume ${key}`,
          width: 80,
          sorting: false,
        });
        columns.push({
          title: `${prop} Price`,
          field: `${prop} Price ${key}`,
          width: 80,
          height: 200,
          sorting: false,
        });
      }
    });

    const data = tableData.map((data, index) => {
      const obj = {};
      data.forEach((prop, key) => {
        if (key === 0) {
          obj.hour = prop;
        } else if (key % 2 !== 0) {
          obj[`${tableHead[(key + 1) / 2]} Volume ${key}`] = prop;
        } else {
          obj[`${tableHead[key / 2]} Price ${key}`] = prop;
        }
        obj['id'] = index;
      });
      return obj;
    });

    // Generate first level header
    const genHeaderFirstLevel = (columns) => {
      const headerFirstLevel = [];
      columns.forEach((column, index) => {
        let cell;
        if (index === 0) {
          cell = (
            <TableCell
              key={`firstlevel_${index}`}
              rowSpan={2}
              className={`${classes.primaryPnlTableHeader} ${classes.stickyCol} ${classes.topZero} ${classes.lightblueCell}`}
            >
              Hour
            </TableCell>
          );
        } else if (index % 2 !== 0) {
          const colTitleArr = column.title.split(' ');
          const title = colTitleArr.splice(0, colTitleArr.length - 1).join(' ');
          cell = (
            <TableCell
              key={`firstlevel_${index}`}
              colSpan={2}
              align="center"
              className={`${classes.primaryPnlTableHeader} ${classes.stickyRowFirst} ${classes.lightblueCell}`}
            >
              {title}
            </TableCell>
          );
        }
        headerFirstLevel.push(cell);
      });
      return headerFirstLevel;
    };

    // Generate second level header
    const genHeaderSecondLevel = (columns) => {
      const columns_ = columns.slice(1);
      const headerSecondLevel = [];
      columns_.forEach((column, index) => {
        const cell = (
          <TableCell
            key={`secondlevel_${index}`}
            align="center"
            className={`${classes.primaryPnlTableHeader} ${classes.stickyRowSecond}`}
          >
            {index % 2 === 0 ? 'Volume, MWh' : `Price, ${currency || 'EUR'}/MWh`}
          </TableCell>
        );
        headerSecondLevel.push(cell);
      });
      return headerSecondLevel;
    };

    // Generate data cells
    const genDataCells = (data) => {
      const index = data.tableData.index;
      const data_ = { ...data };
      delete data_.tableData;
      delete data_.id;
      return (
        <TableRow hover id={index} key={`datarow_${index}`}>
          {Object.keys(data_).map((key, i) => (
            <TableCell
              className={`${classes.fundamentalCell} 
                ${
                  i === 0
                    ? classes.lightblueCell
                    : i % 2 === 0
                    ? classes.grayCell
                    : ''
                }
                ${i === 0 ? classes.stickyCol : ''}`}
              key={`data_${i}`}
            >
              {data_[key]}
            </TableCell>
          ))}
        </TableRow>
      );
    };

    return (
      <div className={classes.tableResponsive}>
        <MaterialTable
          columns={columns}
          sx={{
            borderCollapse: "separate"
          }}
          data={data}
          title={'Energy Delivery Day'}
          options={{
            maxBodyHeight: winHeight - 250,
            pageSize: 24,
            draggable: false,
            search: false,
            toolbar: false,
            paging: false,
            doubleHorizontalScroll: true,
          }}
          components={{
            Row: ({ data }) => genDataCells(data),
            Header: ({ columns }) => {
              return (
                <TableHead>
                  <TableRow id={'first_header_row'} key={'first_header_row'}>
                    {genHeaderFirstLevel(columns)}
                  </TableRow>
                  <TableRow id={'second_header_row'} key={'second_header_row'}>
                    {genHeaderSecondLevel(columns)}
                  </TableRow>
                </TableHead>
              );
            },
          }}
        />
      </div>
    );
  }
}

ProfitLossReportDetails.defaultProps = {
  tableHeaderColor: 'primary',
};

ProfitLossReportDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};

export default withStyles(tableStyle)(ProfitLossReportDetails);
