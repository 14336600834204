import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";


import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const { classes, color, logo, image, logoText, routes } = props;

  const handleClick = (groupeId) => {
    const newOpen = [...open];
    newOpen[groupeId] = !newOpen[groupeId];
    setOpen(newOpen);
  };
  const groupsArray = routes
    .filter((route) => route.group !== undefined).map((route) => route.group)
    .filter((item, i, ar) => ar.indexOf(item) === i);
  const routesObject = {};
  groupsArray.forEach((group) => {
    routesObject[group] = routes.filter((route) => route.group === group);
  });
  const renderRoutes = [];
  const [open, setOpen] = React.useState(groupsArray.map(() => false));
  Object.keys(routesObject).forEach((group, groupeId) => {
    renderRoutes.push(
      <ListItemButton onClick={() => handleClick(groupeId)} id={groupeId} key={`groupe_${groupeId}`}>
        <ListItemIcon className={classes.whiteFont}>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={group} className={`${classes.whiteFont} ${classes.itemText}`} />
        {open[groupeId] ? <ExpandLess className={classes.whiteFont}/> : <ExpandMore className={classes.whiteFont}/>}
      </ListItemButton>
    );
    routesObject[group].forEach((prop, propId) => {
      if (prop.redirect) return null;
      let activePro = " ";
      let listItemClasses;
      if (prop.path === "/upgrade-to-pro") {
        activePro = classes.activePro + " ";
        listItemClasses = classNames({
          [" " + classes[color]]: true
        });
      } else {
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        });
      }
      const whiteFontClasses = classNames({
        [" " + classes.whiteFont]: activeRoute(prop.path)
      });
      renderRoutes.push(
        <Collapse in={open[groupeId]} timeout="auto" unmountOnExit key={`route_${groupeId}_${propId}`}>
          <List component="div" disablePadding>
            <NavLink
             to={prop.path}
             className={activePro + classes.item}
             activeClassName="active"
             key={propId}
            >
              <ListItem button sx={{ pl: 4 }} className={classes.itemLink + listItemClasses}>
                <div className={classes.contentWrapper}>
                  <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                    {typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <prop.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.sidebarName}
                    className={classes.itemText + whiteFontClasses}
                    disableTypography={true}
                  />
                </div>
              </ListItem>
            </NavLink>
          </List>
        </Collapse>
      );
    });

  });
  var links = (
    <List
    sx={{ 
      width: '100%',
      maxWidth: 360,
    }}
    component="nav"
    aria-labelledby="nested-list-subheader"
    className={classes.list}
  >
    {renderRoutes}
  </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a href='/' className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks dbgsrc="Sidebar" pull_parks={false} />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
