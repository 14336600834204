import moment from 'moment';

const hoursDay = 24;
const minArr = ['00', '15', '30', '45'];
const auctionCountAnHour = minArr.length;

export const INITIAL_DATE_FROM = moment().add(-8, 'days');
export const INITIAL_DATE_TO = moment().add(-1, 'days');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const getUnicProductsArr = (prodLabelArr) => {
    const selectArr = [];
    for (let i = 0; i < hoursDay; i++) {
      for (let j = 0; j < auctionCountAnHour; j++) {
        const num = j + 1 + i * auctionCountAnHour;
        selectArr.push(`NEG_${num} ${prodLabelArr[num - 1]}`);
      }
    }
    for (let i = 0; i < hoursDay; i++) {
      for (let j = 0; j < auctionCountAnHour; j++) {
        const num = j + 1 + i * auctionCountAnHour;
        selectArr.push(`POS_${num} ${prodLabelArr[num - 1]}`);
      }
    }
    return selectArr;
};

export const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};