import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { TableCell, TableRow } from '@material-ui/core';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import withStyles from '@material-ui/core/styles/withStyles';
import {filterRoutes} from 'routes/dashboard';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import Checkbox from '@mui/material/Checkbox';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import { setVisibleRoutes} from 'actions/index';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    conn: state.conn,
    uinfo: state.login.uinfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setVisibleRoutes: (data) => dispatch(setVisibleRoutes(data)),
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#757ce8' : 'white',
  ...draggableStyle,
});

const Permissions = ({ classes, conn, setVisibleRoutes, uinfo }) => {
  const [permRoutes, setPermRoutes] = useState([]);
  const [uiRoutes, setUIRoutes] = useState([]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let movedItems = reorder(
      uiRoutes,
      result.source.index,
      result.destination.index
    );
    setUIRoutes(movedItems);
    const routes = [];
    movedItems.forEach((route) => {
      if (route.checked) {
        routes.push(route.path);
      }
    });
    localStorage.setItem('visibleRoutes', JSON.stringify(movedItems));
    setVisibleRoutes(movedItems);
  };

  useEffect(() => {
    let visibleItems =
      uinfo &&
      uinfo.policy.hasOwnProperty('gui-oam-visible-items') &&
      uinfo.policy['gui-oam-visible-items'].length > 0
        ? uinfo.policy['gui-oam-visible-items'][0].obj
        : [];

    const permRoutesInit = []; // routes that are permited from BE
    for (const route of filterRoutes(conn)) {
      if (
        !route.hide &&
        route.sidebarName &&
        (visibleItems.includes(route.path) || visibleItems.includes('all'))
      ) {
        permRoutesInit.push(route);
      }
    }

    let uiRoutesInit = [];  // routes with checked property
    const visibleRoutes = conn.visibleRoutes;

    const objDashboardRoutes = {};
    permRoutesInit.forEach((route) => {
      objDashboardRoutes[route.path] = route;
    });

    if (visibleRoutes) {
      uiRoutesInit = visibleRoutes;
    } else {
      const filterElementsDashboard = filterRoutes(conn).filter((route) => {
        return (
          !route.hide &&
          route.sidebarName &&
          route.sidebarName !== 'Sidebar settings' &&
          (visibleItems.includes(route.path) || visibleItems.includes('all'))
        );
      });
      uiRoutesInit = filterElementsDashboard.map((route) => ({
        ...route,
        checked: true,
      }));
    }
    console.log(uiRoutesInit, 'uiRoutesInit')
    console.log(permRoutesInit,'permRoutesInit')
    setUIRoutes(uiRoutesInit);
    setPermRoutes(permRoutesInit);
    // eslint-disable-next-line
  }, []);

  // Updating state, setting permissed routes to localStorage
  const updatingPermRoutes = (checked, value) => {
    const objUiRoutes = {};
    const newUiRoutes = uiRoutes.map((route) => {
      if (route.sidebarName === value) {
        return { ...route, checked };
      }
      return route;
    });

    newUiRoutes.forEach((route) => {
      objUiRoutes[route.sidebarName] = route;
    });

    let filteredPermRoutes = [];
    if (!checked) {
      uiRoutes.forEach((route) => {
        if (
          route.sidebarName !== value &&
          (!objUiRoutes?.[route.sidebarName] ||
            objUiRoutes[route.sidebarName].checked)
        ) {
          filteredPermRoutes.push({ ...route, checked: true });
        }
      });
    } else {
      filteredPermRoutes = permRoutes.filter(
        (route) =>
          route.sidebarName === value ||
          !objUiRoutes?.[route.sidebarName] ||
          objUiRoutes[route.sidebarName].checked
      );
    }
    localStorage.setItem('visibleRoutes', JSON.stringify(newUiRoutes));
    setUIRoutes(newUiRoutes);
    setVisibleRoutes(newUiRoutes);
  };

  const handleCheckChange = (event) => {
    updatingPermRoutes(event.target.checked, event.target.value);
  };


  const renderingTableRows = uiRoutes
    .filter((data, id) => {
      return data.sidebarName && !data.hide})
    .map((data, id) => 
      (<Draggable key={id} draggableId={'q-' + id} index={id}>
          {(provided, snapshot) => (
            <TableRow
              key={id}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <TableCell
                className={classes.fundamentalCell}
                component="th"
                scope="row"
                style={{ width: '50%' }}
              >
                {data.sidebarName}
              </TableCell>
              <TableCell className={classes.fundamentalCell}>
                <Checkbox
                  value={data.sidebarName}
                  checked={data.checked}
                  onChange={handleCheckChange}
                />
              </TableCell>
            </TableRow>
          )}
        </Draggable>)
    );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Sidebar settings</h4>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className={classes['primaryTableHeader']}>
                  <TableRow>
                    <TableCell className={classes.fundamentalCell}>
                      Sidebar Name
                    </TableCell>
                    <TableCell className={classes.fundamentalCell}>
                      Visible
                    </TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {renderingTableRows}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};

const PermissionsConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Permissions);
export default withStyles(tableStyle)(PermissionsConnect);
