import { connect } from 'react-redux';

import DataDashboardBase, {
  mapStateToProps, mapDispatchToProps,
} from './DataDashboardBase';

import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';

class FrDataDashboard extends DataDashboardBase {
  prepareRenderParams() {
    return {
      group1: {
        rte_ib_vol: {
          title: 'Imbalance volume',
          variable: 'All sources/Imbalance volume',
          check: 0,
          varType: 'rte',
        },
        rte_ib_pos: {
          title: 'Positive imbalance price',
          variable: 'All sources/Positive Imbalance price',
          check: 0,
          varType: 'rte',
        },
        rte_ib_neg: {
          title: 'Negative imbalance price',
          variable: 'All sources/Negative Imbalance price',
          check: 0,
          varType: 'rte',
        },
        rte_un: {
          title: 'RTE Unavailability',
          variable: 'Rte-Unavailabilities/Audit',
          check: 1,
          varType: 'specific',
        },
      },

      group2: {
        production_wind_actual: {
          title: 'Wind production actual',
          variable: 'EQ/Wind Actual',
          check: 1,
          varType: 'specific',
        },
        production_wind_forecast: {
          title: 'Wind production forecast',
          variable: 'EQ/Wind Forecast',
          check: 1,
          varType: 'specific',
        },
        production_solar_actual: {
          title: 'Solar production actual',
          variable: 'EQ/Solar Actual',
          check: 1,
          varType: 'specific',
        },
        production_solar_forecast: {
          title: 'Solar production forecast',
          variable: 'EQ/Solar Forecast',
          check: 1,
          varType: 'specific',
        },
        consumption_actual: {
          title: 'Consumption actual',
          variable: 'EQ/Consumption Actual',
          check: 1,
          varType: 'specific',
        },
        consumption_forecast: {
          title: 'Consumption forecast',
          variable: 'EQ/Consumption Forecast',
          check: 1,
          varType: 'specific',
        },
        da_price_actual: {
          title: 'Day-ahead price actual',
          variable: 'EQ/EPEX 1200-CET Price Spot EUR/MWh Actual',
          check: 1,
          varType: 'specific',
        },
        da_price_forecast: {
          title: 'Day-ahead price forecast',
          variable: 'EQ/EPEX 1200-CET Price Spot Forecast',
          check: 1,
          varType: 'specific',
        },
      },

      group3: {
        da_price: {
          title: 'EPEX Day-ahead price',
          variable: 'EPEX/1200-CET Hourly Day-ahead price',
          check: 1,
          varType: 'specific',
        },
        id_price: {
          title: 'EPEX Intraday price',
          variable: 'EPEX-API/Intraday price',
          check: 1,
          varType: 'specific',
        },
      },
    };
  }
}

const FrDataDashboardConnect = connect(
  mapStateToProps('FR'),
  mapDispatchToProps,
)(FrDataDashboard);
export default withStyles(dashboardStyle)(FrDataDashboardConnect);
