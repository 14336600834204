import React from 'react';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core';

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    loading: state.dashboard.loading,
  };
};

const styles = theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1),
        width: '65%',
        display: 'inline-block',
        marginTop: '10%',
        marginRight: '15%'
    }
});

class Oops extends React.Component {
  render() {
    if (this.props.isLoggedIn) {
      return (
        <div>
          <a href="/login" onClick={() => sessionStorage.removeItem('loggedIn')}>Log Out</a>
        </div>
      );
    } else {
      window.location.href = '/login';
      return '';
    }
  };
};

const DecoratedOops = connect(mapStateToProps, null)(Oops);
export default withStyles(styles)(DecoratedOops);
