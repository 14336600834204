// Calculate WAPE, but UI it's labeled as MAPE.
// https://www.baeldung.com/cs/mape-vs-wape-vs-wmape#wape
// Series should have next format: [{ time: Timestamp, value: Number }]
// eslint-disable-next-line import/prefer-default-export
export const calculateWAPE = (published, forecasted, minX, maxX) => {
  if (!published?.length || !forecasted?.length) {
    return NaN;
  }

  let sumDiff = 0.00;
  let sumProd = 0.00;

  for (let i = 0; i < published.length; i += 1) {
    if ((typeof forecasted[i].value === 'number')
      && (typeof published[i].value === 'number')
      && (forecasted[i].time >= minX && forecasted[i].time <= maxX)) {
      sumDiff += Math.abs(published[i].value - forecasted[i].value);
      sumProd += Math.abs(published[i].value);
    }
  }

  // For a case when "sumDiff" or "sumProd" is zero
  const wape = 100.0 * (sumDiff / sumProd);
  return Number.isFinite(wape) ? wape : NaN;
};
