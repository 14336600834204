import { RECEIVED_OPTIMISATION_DATA } from "constants/action-types";
import { UPDATE_OPTIMISATION_TYPE } from "constants/action-types";
import { LOADING_OPTIMISATION_DATA } from "constants/action-types";
import { SHOW_OPTIMISATION_NON_ACTIVE } from "constants/action-types";

const initialState = {
  result: { result: {} },
  loading: true,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_OPTIMISATION_DATA) {
     return { ...state, ...initialState };
  } else if (action.type === RECEIVED_OPTIMISATION_DATA) {
  	 return {
       ...state,
        result: action.optimisation,
        loading: false,
     };
  } else if (action.type === UPDATE_OPTIMISATION_TYPE) {
    return {
      ...state,
      opt_type: action.payload
    }
  } else if (action.type === SHOW_OPTIMISATION_NON_ACTIVE) {
    return {
      ...state,
      showNonActive: action.payload
    }
  }

  return state;
};
