import React, { Component } from "react";
import { connect } from "react-redux";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import withStyles from "@material-ui/core/styles/withStyles";
import Input from '@material-ui/core/Input';

import Button from "components/CustomButtons/Button.jsx";
import RegularButton from "components/CustomButtons/Button.jsx";
import {Grid, TextField} from '@material-ui/core';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Autocomplete from '@mui/material/Autocomplete';
import ParkSelector from "components/ParkSelector/ParkSelector";

import { buildAPIRequest, security_fetch_params } from "actions/index";
import LoginPage from "views/Login/Oops.jsx";
import localForage from 'localforage';
import moment from 'moment';
import classNames from 'classnames';
import {logout} from 'utils/auth';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';
import { DATE_FORMAT, DATE_FORMAT_DASH } from "constants/general";

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const LF_CACHE_TTL = 60;

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  inputField: {
    paddingTop: '20px',
    textAlign: 'left',
    justifyContent: 'space-between',
    width: '100%',
  },
  restoreButton: {
    width: '100%',
  },
};


class OverrideDA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      park: null,
      multiData: [],
      fileInputFieldValue: null,
      tradesDaData: [],
      tradesDaInputValue: null,
      auStage: null,
      tradesAuStage: null,
      tradesDaDate: null,
    };

    this.render_one_park_field = this.render_one_park_field.bind(this);
    this.override_da = this.override_da.bind(this);
    // copy from config.py
    this.load_park = this.load_park.bind(this);
    // copy from config.py
    this.get_park_data = this.get_park_data.bind(this);

    // copy from config.py
    this._on_park_select = this._on_park_select.bind(this);
    this._on_override_da = this._on_override_da.bind(this);
    this._on_restore_da = this._on_restore_da.bind(this);

    this.renderForm = this.renderForm.bind(this);
    this.renderTable = this.renderTable.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleDATradesChangeConfirm= this.handleDATradesChangeConfirm.bind(this);
    this.handleDaTradesChange = this.handleDaTradesChange.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleChangeAuStage = this.handleChangeAuStage.bind(this);
    this.handleChangeTradesAuStage = this.handleChangeTradesAuStage.bind(this);

    this.abrt = new AbortController();
  };

  // XXX compare to Optimizations and Forecast, eventually factor out
  load_park(park_id, cb) {
    const [url, headers] = buildAPIRequest('/api/v1/parks/' + park_id);
    const that = this;
    fetch(url, {
      signal: this.abrt.signal,
      method: 'GET',
      headers: headers, ...security_fetch_params
    }).then(function (response) {
      if (response.ok) {
        response.json().then(data => {
          if (data.error) {
            alertify.error("Response error");
            console.log(data.error);
            return
          }
          data.updated_at = moment().unix();
          localForage.setItem(`park_data_${park_id}_${that.props.conn.label}`, data, function (err) {
            // if err is non-null, we got an error
            if (err) {
              console.log('localForage.setItem error', err);
            }
          });

          if (data.data) {
            cb(data.data);
          } else {
            cb(null);
          };
        }).catch(error => {
          console.log(error);
          cb(null);
        });
      } else if (response.status === 401) {
        logout();
        return;
      }
      else {
        cb(null);
      }
    }).catch((error) => {
      console.log(error);
      cb(null);
    });
  };


  get_park_data(park_id, cb, force=false) {
    localForage.getItem(
        `park_data_${park_id}_${this.props.conn.label}`, function (err, value) {
      if (value != null) {
        if ((value.updated_at < (moment().unix() - LF_CACHE_TTL)) || force) {
          this.load_park(park_id, cb);
        } else {
          if (!value.data) {
            cb(null);
          } else {
            cb(value.data);
          }
        }
      } else {
        this.load_park(park_id, cb);
      }
    }.bind(this));
  };


  _setup_park(park_id, force) {
    this.get_park_data(park_id, function (park) {
      this.setState({ park });
    }.bind(this), force);
  }


  override_da(park_id, field_id, cb) {
    let cda = document.getElementById(field_id);
    let url;
    if (field_id === 'constant-da') {
      const [urlStr] = buildAPIRequest(`/api/v1/parks/${park_id}/constant_da?stage=${this.state.auStage.id}`);
      url = urlStr;
    } else {
      const params = `?stage=${this.state.tradesAuStage.id}&label=OptimalAssetMonitor`;
      const [urlStr] = buildAPIRequest(
        `/api/windparks/${park_id}/da_confirmations/${this.state.tradesDaDate.format(
          DATE_FORMAT
        )}${params}`
      );
      url = urlStr;
    }
    fetch(url, {
      signal: this.abrt.signal,
      method: 'POST',
      headers: {
        "Content-type": cda.files[0].type
       },
      body: cda.files[0], 
      ...security_fetch_params,
    }).then(function (response) {
      if (response.ok) {
        response.json().then(function (data) {
          if (data.error) {
            alertify.error("Response error");
            console.log(data.error);
            return
          }
          if (data['message']) {
            alertify.warning(
              'Park was updated, but: ' + data['message'], 10);
          } else {
            alertify.success(
              'Park was updated successfuly', 5);
          }
          cb(true);

        }).catch((error) => {
          alertify.error('API json error', 5);
          console.log(`${field_id} json error`, error);
          cb(false);
        });

      } else if (response.status === 401) {
        logout();
        return;
      } else {
        response.json().then(function (data) {
          alertify.error('API server error: ' + data['error'], 5);
          console.log(`${field_id} response error`, response);
          cb(false);

        }).catch((error) => {
          alertify.error('API json error', 5);
          console.log(`${field_id} json error`, error);
          cb(false);
        });
      }

    }).catch((error) => {
      alertify.error('API fetch error', 5);
      console.log(`${field_id} fetch error`, error);
      cb(false);
    });
  }


  restore_da(park_id, process, cb) {
    const [url, headers] = buildAPIRequest('/api/v1/parks/' + park_id + '/restore_configuration');

    fetch(url, {
      signal: this.abrt.signal,
      method: 'PUT',
      headers: headers, ...security_fetch_params,
    }).then(function (response) {
      if (response.ok) {
        response.json().then(function (data) {
          if(process === 'restore')
            alertify.success(
              'Park was restored successfuly', 5);
          cb(true);

        }).catch((error) => {
          alertify.error('API json error', 5);
          console.log('restore_da json error', error);
          cb(false);
        });
      } else if (response.status === 401) {
        logout();
        return;
      } else {
        alertify.error('API server error', 5);
        console.log('restore_da response error', response);
        cb(false);
      }
    }).catch((error) => {
      alertify.error('API fetch error', 5);
      console.log('restore_da fetch error', error);
      cb(false);
    });
  }

  _on_park_select(_, value) {
    if (!value) return;
    this._setup_park(value.id, false);
  }

  _on_override_da(field_id) {
    let park_id = this.state.park.id;
    this.override_da(park_id, field_id, function (flag) {
      this._setup_park(park_id, flag);
    }.bind(this));
  }

  _on_restore_da(process) {
    let park_id = this.state.park.id;
    this.restore_da(park_id, process, function (flag) {
      this._setup_park(park_id, flag);
    }.bind(this));
  }

  componentDidMount() {
    if (this.props.conn.parks.length > 0) {
      try {
        const park_id = this.props.conn.parks[0].id;
        this._setup_park(park_id, true);
      } catch (error) {
        console.log('error handling parks API' + error);
      };
    }
  };

  componentWillUnmount() {
    this.abrt.abort();
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.park !== nextState.park
      || this.state.conn !== nextProps.conn
      || this.state.fileInputFieldValue !== nextProps.fileInputFieldValue;
  };

  // Use componentDidUpdate as componentDidMount is too early
  // and there's no parks loaded
  componentDidUpdate(prevProps,prevState) {
    // Run only once when park isn't set
    if (this.props.conn.parks.length > 0 && !this.state.park) {
      const park_id = this.props.conn.parks[0].id;
      this._setup_park(park_id, true);
    }
    if(this.state.park && this.state.park !== prevState.park) {
      this.setState({auStage: null});
    }
    if(this.state.fileInputFieldValue && prevState.fileInputFieldValue !== this.state.fileInputFieldValue) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => this.handleFile(e, 'multiData');
      fileReader.readAsText(this.state.fileInputFieldValue);
    }
    if (this.state.tradesDaInputValue && prevState.tradesDaInputValue !== this.state.tradesDaInputValue) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => this.handleFile(e, 'tradesDaData');
      fileReader.readAsText(this.state.tradesDaInputValue);
    }
  };

  render_one_park_field(f, l, m) {
    return(
        <FormControlLabel
          control={<Input type="text" disabled multiline={m} id="park-id" value={f} />}
          label={l}
          value={l}
          labelPlacement="start"
          className={this.props.classes.inputField}
        />
    );
  };

  handleFile = (event, dataKey) => {
    const multArray = [...event.target.result.replaceAll('\r\n',',').split(",")];
    if(multArray[multArray.length-1] === '')
      multArray.pop();
    if(multArray.length%24 === 0){
      const tableData = [];
      multArray.forEach((el,ind)=>{
        tableData.push([ind,el]);
      })
      this.setState({[dataKey]: [...tableData]});
    }
  }
  
  handleChange = () => {
    const cda = document.getElementById('constant-da');
    if(cda.value !== '') {
      this.setState({fileInputFieldValue:cda?.files[0]})
    }    
  }

  handleDaTradesChange = () => {
    const cda = document.getElementById('confirm-da');
    if (cda.value !== '') {
      this.setState({ tradesDaInputValue: cda?.files[0] })
    }
  }

  handleConfirm = (isConstDaSignals) => {
    if(this.state.auStage !== null) {
      const process = isConstDaSignals ? 'update' : 'set_const';
      if(isConstDaSignals) {
        this._on_restore_da(process);
      }
      this._on_override_da('constant-da');
      this.handleCancel();
    } else {
      alertify.error(
        'You should select AU stage', 5);
    }
  }

  handleDATradesChangeConfirm = () => {
    if (this.state.tradesAuStage !== null && this.state.tradesDaDate) {
      this._on_override_da('confirm-da');
      this.handleCancel('confirm-da');
    } else {
      alertify.error('You should select AU stage and Date', 5);
    }
  }

  handleCancel = (type) => {
    let cda = document.getElementById(type ? 'confirm-da' : 'constant-da');
    cda.value = '';
    this.setState({...(type ? { tradesDaInputValue: null } : { fileInputFieldValue: null })})
  }

  handleChangeAuStage = (event, value) => {
    this.setState({auStage: value});
  }

  handleChangeTradesAuStage = (event, value) => {
    this.setState({tradesAuStage: value});
  }

  renderForm = () => {
    const { park } = this.state;
    const amSchedule = [];
    const schedule = park.schedule ? park.schedule.split(';') : [];
    const entryOver = park.optimization_job.entry_over;
    const auIdStages = [];
    let numStages = 1;
    if(entryOver) {
      numStages = Object.keys(entryOver).length;
      Object.keys(entryOver).forEach((el)=>{
        if(!entryOver[el].afternoon_run) {
          amSchedule.push(schedule[el]);
        }
      })
    } else if (!park.afternoon_run) {
      amSchedule.push(...schedule) 
    }
    const renderedForm = [];
    const beta = {};
    const pmax = {};
    let isConstDaSignals = {};
    for (let i = 0; i < numStages; i++) {
      if(park.optimization_job.entry_over?.[i]) {
        if(!park.optimization_job.entry_over[i].afternoon_run) {
          auIdStages.push(i);
        }
        beta[i] =  park.optimization_job.entry_over[i].beta ? park.optimization_job.entry_over[i].beta : park.optimization_job.beta;
        pmax[i] = park.optimization_job.entry_over[i].pmax ? park.optimization_job.entry_over[i].pmax : park.optimization_job.Pmax;
        isConstDaSignals[i] = park.optimization_job.entry_over[i].beta ? park?.optimization_job.entry_over[i].beta instanceof Array : park?.optimization_job.beta instanceof Array;
      } else {
        beta[0] = park.optimization_job.beta;
        pmax[0] = park.optimization_job.Pmax;
        isConstDaSignals[0] = park?.optimization_job?.beta instanceof Array;
        if(!park.optimization_job.afternoon_run) {
          auIdStages.push(0);
        }
      }
    }
    for (let i = 0; i < auIdStages.length; i++) {
      const auId = auIdStages[i];
      const optimizer = park.optimization_job.entry_over?.[auId].optimizer;
      renderedForm.push(
        { idStage: auId,
          renderStage : (<Grid item xs={12} sm={12} md={9} key={i}>
          {this.render_one_park_field(park.id, `AU - ${i}`, false)}
    
          {this.render_one_park_field(
            park.name, "Name", false)}
    
          {this.render_one_park_field(park.location ?
            park.location?.country_name : "N/A",
            "Location")}
    
          {this.render_one_park_field(
            park.capacity, "Capacity", false)}
    
          {this.render_one_park_field(
            amSchedule[auId],
            "Schedule",
            false,
          )}
    
          {this.render_one_park_field(
            park.data_source, "Data Source", false)}
    
          {this.render_one_park_field(
            park.extra_config['live_simulation'] ?
            "yes" : "no", "Live Simulation", false)}
    
          {this.render_one_park_field(
            park.extra_config['price_model_op'] ?
            park.extra_config['price_model_op'] :
            "N/A", "Price Model For Optimizations", false)}
    
          {optimizer ===
              'npm_simple' ?
            this.render_one_park_field(
              isConstDaSignals[auId] ?
              beta[auId].map(
                  x => Math.round(x * 1000.0) / 1000.0).join('\n') :
                  beta[auId],
              "Constant DA Signals", true) : null}
    
          {this.render_one_park_field(
            pmax[auId],
            "Pmax",
            false,
          )}
          {isConstDaSignals[auId] &&
          <RegularButton
            type="submit"
            color="primary"
            onClick={() => this._on_restore_da('restore')}
            className={this.props.classes.restoreButton}>Restore
          </RegularButton>}
        </Grid>)}
      );
    }
    return renderedForm;
  }

  renderTable = (tableData, auList, isConstDaSignals, type) => {
    const { classes } = this.props;
    const tableHead = ['Time', 'Signal'];
    return (
      <Grid container item xs={12} sm={12} justifyContent="center">
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>{type ? 'Override DA Trades Table' : 'Override Table'}</h4>
          </CardHeader>
          <CardBody>
            <div>
              <Table style={{tableLayout: "auto" }}>
                <colgroup>
                    <col width="50%" />
                    <col width="50%" />
                </colgroup>

                <TableHead>
                  <TableRow>
                    {tableHead.map((prop, key) => {
                      return (
                        <TableCell
                          style={{textAlign: 'center'}}
                          key={key}
                        >
                          {prop}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData.map( (row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row"
                          style={{whiteSpace: 'nowrap',
                            padding: 0,
                            textAlign: 'center'
                          }}
                        >
                          {row[0]}
                        </TableCell>
                        <TableCell align="right"
                          style={{whiteSpace: 'nowrap',
                            padding: 0,
                            textAlign: 'center'
                          }}>{row[1]}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </CardBody>
          <CardFooter>
            <Grid container spacing={4} style={{ width: '100%' }}>
              <Grid item xs={6} sm={6} md={6}
                style={{ textAlign: 'left' }}
              >
                <Autocomplete
                  disablePortal
                  id="var_au"
                  value={type ? this.state.tradesAuStage : this.state.auStage || null}
                  options={auList}
                  renderInput={(params) => (
                    <TextField {...params} label="AU stage" />
                  )}
                  onChange={type ? this.handleChangeTradesAuStage : this.handleChangeAuStage}
                />
              </Grid>
              {type && (
                <Grid item xs={6}>
                  <TextField
                    id="date"
                    label="Date"
                    type="date"
                    value={moment(this.state.tradesDaDate).format(DATE_FORMAT_DASH)}
                    className={classNames(
                      classes.textField,
                      classes.spacing
                    )}
                    onChange={(e) => this.setState({tradesDaDate: moment(e.target.value)})}
                    style={{ marginRight: '15px', width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} style={{textAlign: 'left'}}>
                <Button 
                  color="primary" 
                  onClick={() => type ? this.handleDATradesChangeConfirm() : this.handleConfirm(isConstDaSignals)}
                >
                  Confirm
                </Button>
                <Button 
                  color="primary" 
                  onClick={() => this.handleCancel(type)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
      </Grid>
    )
  }

  render() {
    const {classes, conn} = this.props;
    const {park, multiData, fileInputFieldValue, tradesDaData, tradesDaInputValue} = this.state;
    if (this.props?.isLoggedIn) {
      // am_schedule_hour/minute
      // capacity
      // data_source
      // extra_config: live_simulation, price_model_op
      // location: country
      // optimization_job_da: Pmax, beta (if price_mdel_op is npm_simple),

      let activePark = conn.parks.filter(
        (item) => item.id === park?.id
      );
      activePark = activePark.length ? activePark[0] : null;
      const tableHead = ['Time', 'Signal'];
      const isConstDaSignals = park?.optimization_job?.beta instanceof Array;
      let renderedForm = [];
      const auList = []; 
      if(park) {
        this.renderForm().forEach((el)=>{
          auList.push({label: `AU - ${el.idStage}`, id: el.idStage});
          renderedForm.push(el.renderStage);
        });
      }

      return (
        <Grid container spacing={4}>
          <ParkSelector 
            country_code={park?.location?.country_iso3166}
            country_name={park?.location?.country_name}
            options={conn.parks}
            selected={activePark}
            handleChange={this._on_park_select}
            capacity={park ? park.capacity.toFixed(1) : 'N/A'}
          />
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Current Settings
                </h4>
              </CardHeader>
              <CardBody>
                {park
                  ? <Grid container spacing={4}>
                      {renderedForm}
                    </Grid>
                  : null
                }
              </CardBody>
            </Card>
          </Grid>

          {(multiData.length && fileInputFieldValue) ? (
            <>
              {this.renderTable(multiData, auList, isConstDaSignals)}
            </>
          ) : <></>}
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Override Tomorrow DA</h4>
              </CardHeader>
              <CardBody>
                <Grid container spacing={4} justifyContent="center">
                  <Grid item xs={12} sm={12} md={9}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Input type="file" id="constant-da" name="constant-da" onChange={this.handleChange}/>}
                        label="Signals File"
                        labelPlacement="start"
                        className={classes.inputField}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          {(tradesDaData.length && tradesDaInputValue) ? (
            <>
              {this.renderTable(tradesDaData, auList, null, 'confirm-da')}
            </>
          ) : <></>}
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Override DA Trades</h4>
              </CardHeader>
              <CardBody>
                <Grid container spacing={4} justifyContent="center">
                  <Grid item xs={12} sm={12} md={9}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Input type="file" id="confirm-da" name="confirm-da" onChange={this.handleDaTradesChange}/>}
                        label="Signals File"
                        labelPlacement="start"
                        className={classes.inputField}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      );
    } else {
      return (<LoginPage />);
    }
  };
}


const ConnectedOverrideDA = connect(mapStateToProps, mapDispatchToProps)(OverrideDA);
export default withStyles(styles)(ConnectedOverrideDA);
