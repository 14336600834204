import {
  LOADING_MARKETSDATA_DATA,
  RECEIVED_MARKETSDATA_DATA,
} from "constants/action-types";

const initialState = {
    markets: {
        FR: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },
        BE: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },
        DE: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },
        AT: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },

        CH: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },

        NL: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },

        ES: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },

        PT: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },
        CZ: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          },
        DK: {
            high: {RTE: 0, WATTSIGHT: 0, EPEX: 0, AXPO: 0},
            medium: {WATTSIGHT: 0, ENTSOE: 0},
            low: {RTE: 0, ENTSOE: 0},
          }
    },
    loading: true,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_MARKETSDATA_DATA) {
    return {
      ...state,
      ...initialState,
    };
  } else if(action.type === RECEIVED_MARKETSDATA_DATA){
    return {
      ...state,
      markets: action.dashboard,
      loading: false,
    };
  }

  return state;
};
