import { combineReducers } from 'redux'
import reports from './modules/reports'
import dashboard from './modules/dashboard'
import datadashboard from './modules/datadashboard'
import marketsdata from './modules/marketsdata'
import marketscores from './modules/marketscores'
import tradesdashboard from './modules/tradesdashboard'
import jobs from './modules/jobs'
import notifications from './modules/notifications'
import generation from './modules/generation'
import markets from './modules/markets'
import metrics from './modules/metrics'
import rtrader from './modules/rtrader'
import fundamentals from './modules/fundamentals'
import login from './modules/login'
import optimisation  from './modules/optimisation'
import conn  from './modules/conn'
import structured_alerts  from './modules/structured_alerts'
import pnlAnalysis from './modules/pnlAnalysis';
import dictionaries from './modules/dictionaries';
import auditdata from './modules/auditdata'


export default combineReducers({
  reports, login, dashboard, datadashboard, tradesdashboard, marketsdata,
  jobs, notifications, generation, markets, marketscores, metrics,
  optimisation, rtrader, fundamentals, conn, structured_alerts, pnlAnalysis,
  dictionaries, auditdata
});
