import React, { useState, useEffect} from 'react';
import { connect } from "react-redux";

import { buildAPIRequest, security_fetch_params } from 'actions/index';
import {logout} from 'utils/auth';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import localForage from 'localforage';
import moment from 'moment';
import 'moment-timezone';

const mapStateToProps = state => {
  return {
    apiLabel: state.conn.label,
    conn: state.conn,
  };
};

const ModelName = ({ parkId, idOptimisation, apiLabel, conn}) => {
    const [modelName, setModelName] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const getModelName = (parkId, idOptimisation) => {
      const localForageVar = !idOptimisation
        ? `model_name_${parkId}_${apiLabel}`
        : `model_name_${parkId}_${idOptimisation}_${apiLabel}`;
      localForage.getItem(localForageVar, function (err, value) {
          if (value) {
              if (value.updated_at < moment().unix() - 10000) {
                downloadModelName(parkId, idOptimisation);
              }
              else {
                setModelName(value.data);
                setLoading(false);
              }   
          }
          else {
            downloadModelName(parkId, idOptimisation);
          }
      });
    }
    const downloadModelName = (parkId, idOptimisation) => {
        const urlString = !idOptimisation
          ? `/api/v1/parks/${parkId}/model_name`
          : `/api/v1/parks/${parkId}/model_name?result=${idOptimisation}`;
        const [url, headers] = buildAPIRequest(urlString);
        fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
          .then((response) => {
            if (response.ok) {
              return response.json().then((data) => {
                if (data.error) {
                  alertify.error("Response error");
                  console.log(data.error);
                  return
                }
                const localForageVar = !idOptimisation
                  ? `model_name_${parkId}_${apiLabel}`
                  : `model_name_${parkId}_${idOptimisation}_${apiLabel}`;
                data.updated_at = moment().unix();
                localForage.setItem(localForageVar, data, function (err) {
                  if (err) {
                      console.log(err);
                  }
                });
                setModelName(data.data);
                setLoading(false);
              });
            } else if (response.status === 401) {
              logout();
              return;
            } else if (response.status === 404) {
              alertify.error('Model name is N/A!');
              return;
            }
            return Promise.reject(response);
          })
          .catch((error) => {
            alertify.error(error.message, 'error', 5);
            return [false, null];
          });
    }

    useEffect(() => {
      if(parkId) {
        setLoading(true);
        getModelName(parkId, idOptimisation);
      }
    // eslint-disable-next-line
    },[parkId, idOptimisation]);

    return (
      <div>
          {modelName && !loading ? modelName : 'Model: N/A'}
      </div>
    )

}

const ModelNameConnect = connect(mapStateToProps)(ModelName);
export default ModelNameConnect;
