import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import LoginPage from "views/Login/Oops.jsx";
import PortfolioResult from 'components/TradesDashboard/PortfolioResult';
import { connect } from "react-redux";


import { get_tradesdashboard_data } from "actions/index";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const mapStateToProps = state => {
  return { isLoggedIn: state.login.loggedIn, value: 0, loading: state.dashboard.loading,
    trades: state.tradesdashboard.trades,
    };
};

const mapDispatchToProps = (dispatch) => ({
  get_tradesdashboard_data: data => dispatch(get_tradesdashboard_data(data))
});



class TradesDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 0
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps !== undefined){
      if(nextProps.isLoggedIn === true && this.state.value === 0){
        this.props.get_tradesdashboard_data();
        this.setState({ value: 1 });
      }
    }
    return true;
  }

  componentDidMount(){
    if(this.props.isLoggedIn === false){
    //  this.props.history.push('/login');
    } else{
      this.props.get_tradesdashboard_data();
    }
  }


  render() {
    var that = this;
    const optimisations = Object.keys(this.props.trades['portfolios']).map(function(key, index) {
      return(<PortfolioResult key={key} portfolio={key} hours={that.props.trades['portfolios'][key]} />)
    });

    if (this.props && this.props.isLoggedIn) {
      if(this.props.loading){
        return (
          <div className="loader" alt="Loading report..."/>
        )
      } else {
        return (
            <div>
              {optimisations}
            </div>

        )}
    } else{
      return (
      <LoginPage />)
    }
  }
}

TradesDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

//export default withStyles(dashboardStyle)(Dashboard);


const TradesDashboardConnect = connect(mapStateToProps, mapDispatchToProps)(TradesDashboard);
export default withStyles(dashboardStyle)(TradesDashboardConnect);


