import configureStore from "configureStore.js";
import { set_logged_in, set_uinfo } from "actions/index";

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

export const logout = () => {
  sessionStorage.removeItem('loggedIn');
  sessionStorage.removeItem('uinfo');
  window.location.href = '/login';
};

export const parse_policy = (policy) => {
  /**
   * Returns object (selection of statements relevant to us):
   *
   * {
   *  "gui-oam-visible-items": [ ... ],
   * }
   *
   */
  const re = new RegExp(
    '\\s*(\\S+)\\s+(.+?)\\s+(accept|reject|null)?\\s*(?:;|$)',
    'gs'
  );

  const m = policy.matchAll(re);

  const supported_policy_tags = ['gui-oam-visible-items'];

  const a = Array.from(m, function (i) {
    const st = {
      tag: i[1],
      expr: i[2],
      action: i[3],
    };
    return st;
  });

  const res = {};

  a.filter(function (i) {
    return supported_policy_tags.includes(i.tag);
  }).forEach(function (i) {
    if (!res.hasOwnProperty(i.tag)) {
      res[i.tag] = [];
    }
    res[i.tag].push({
      obj: JSON.parse(i['expr']), // "obj" is a parsed "expr"
      ...i,
    });
  });
  return res;
  
};


export const managePolicyRequest = (r) => {
  const store = configureStore();
  if (r.ok) {
    r.json().then(
      function (j) {
        const uinfo = {
          login: j['data']['user']['login'],
          email: j['data']['user']['email'],
          policy: parse_policy(
            j['data']['policy']['statement']
          ),
        };
        if(j['data']['user']['is_authenticated'] && j['data']['user']['is_active']){
          store.dispatch(set_uinfo(uinfo));
          sessionStorage.setItem('uinfo', JSON.stringify(j));
        } else if (!j['data']['user']['is_authenticated']){
          console.error('Account service login failed', j);
          alertify.error(
            'Account service login failed.',
            5
          );
        } else if (!j['data']['user']['is_active']){
          console.error('Account is inactive', j);
          alertify.error(
            'Account is inactive',
            5
          );
        }
      }
    );
  } else {
    r.json().then(function (j) {
      // XXX silly heuristics
      let msg = null;
      if (j.hasOwnProperty('args')) {
        const a = j.args;

        if (a.hasOwnProperty('args')) {
          msg = `${a['args'][0]}: ${a['args'][1]}`;
        } else {
          if (a[0] === 'ticket') {
            msg = 'policy or permission error';
          } else {
            msg = JSON.stringify(a[1]);
          }
        }
      } else {
        msg = JSON.stringify(j);
      }
      alertify.error('Login failed.  Respose from service: ' + msg, 7);
    });
    store.dispatch(set_logged_in(false));
    store.dispatch(set_uinfo(null));
    sessionStorage.removeItem('loggedIn');
    sessionStorage.removeItem('uinfo');
  }
}