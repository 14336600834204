import React from 'react';
import {MenuItem} from '@material-ui/core';

const getStyles = (name, personName) => {
    return {
      fontWeight: personName.indexOf(name) === -1 ? 'normal' : 'bold',
    };
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
PaperProps: {
    style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
    },
},
};

export const listMenu = (data, selectedData) => data.map((el) => (
<MenuItem
    key={el}
    value={el}
    style={getStyles(el, selectedData)}
>
    {el}
</MenuItem>
));
