import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { PowerOffRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  vAlignMiddle: {
    verticalAlign: 'middle',
  },
};

const NoData = ({ classes }) => (
  <Typography>
    No data{' '}
    <PowerOffRounded className={classes.vAlignMiddle} />
  </Typography>
);

NoData.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoData);
