import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import PropTypes from 'prop-types';

const IntraDayNomExecReport = ({
  classes,
  tableHead,
  tableData,
  parkId,
  tableHeaderColor,
  isSkipIntraday,
}) => (
  <div className={classes.tableResponsive}>
    <Table className={classes.table}>
      {tableHead !== undefined ? (
        <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
          <TableRow>
            {tableHead.map((prop, key) => {
              return (
                <TableCell
                  className={classes.tableCell + ' ' + classes.tableHeadCell}
                  key={key}
                >
                  {prop}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      ) : null}
      <TableBody>
        {tableData.map((prop, index) => {
          return (
            <TableRow key={index}>
              {prop.map((prop, key) => {
                const style = {};
                style['whiteSpace'] = 'nowrap';
                style['padding'] = '0';
                style['paddingRight'] = '5px';
                style['textAlign'] = 'center';
                return (
                  <TableCell
                    className={classes.tableCell}
                    style={style}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </div>
);

IntraDayNomExecReport.defaultProps = {
  tableHeaderColor: 'primary',
  parkId: PropTypes.number.isRequired,
};

IntraDayNomExecReport.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};

export default withStyles(tableStyle)(IntraDayNomExecReport);
