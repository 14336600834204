import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React from 'react';

const ParkAutocomplete = ({
  id, label, options, selectedOption, getOptionLabel, variant, onChange, ...props
}) => (
  <Autocomplete
    id={id}
    name={id}
    options={options}
    value={selectedOption}
    getOptionLabel={getOptionLabel}
    PopperComponent={(props) => (
      <Popper
        {...props}
        style={{ width: 'inherit' }}
        placement="bottom"
      />
    )}
    onChange={onChange}
    style={{ width: '100%' }}
    renderInput={(params) => (
      <TextField
        label={label}
        variant={variant || 'standard'}
        {...params}
      />
    )}
    {...props}
  />
);

ParkAutocomplete.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  ),
  selectedOption: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

ParkAutocomplete.defaultProps = {
  id: 'park',
  label: 'Energy Park',
  options: [],
  selectedOption: '',
  getOptionLabel: (option) => option.name,
};

export default ParkAutocomplete;
