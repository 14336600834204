import React, { Component } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Grid from '@material-ui/core/Grid';
import CardFooter from "components/Card/CardFooter.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import {buildAPIRequest, security_fetch_params} from "../../actions/index"

import { makeSeries, stocksChartOptions } from 'variables/charts';
import localForage from 'localforage';
import moment from 'moment';
import {logout} from 'utils/auth';

import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod
  from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const mapStateToProps = state => {
  return {
    apiLabel: state.conn.label 
  }
};

class SessionResults extends Component {
  constructor(props) {
    super(props);
    this.state = {variables: [], loading: true, graph: [[], []]};
    this.handleChange = this.handleChange.bind(this);
    this.get_session_results = this.get_session_results.bind(this);
    this.update_session_results = this.update_session_results.bind(this);

    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    HighchartsExportVisiblePeriod(Highcharts);
    Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');
  }

  update_session_results(){
    var that = this;
    const [url, headers] = buildAPIRequest('/api/btsessions/' + this.props.location.state.data.id + '/daily_fr_profits');
    fetch(url, {method: 'GET', headers: headers, ...security_fetch_params})
      .then((response) => {
            if (response.ok) {
              response.json().then(data => {
                  data.updated_at = moment().unix();
                  localForage.setItem(`btsession_ ${that.props.location.state.data.id}_${that.props.apiLabel}`, data, function (err) {
                      // if err is non-null, we got an error
                      console.log(err);
                  });
                  var jsonObj = {'results': data.data, 'loading': false}
                  var newstate =  Object.assign({}, that.state, jsonObj);
                  that.setState(newstate);
              })
            } else if (response.status === 401) {
              logout();
              return;
            }
      })
    .catch((error) => {console.log(error)})
  }


  get_session_results(){
    var that = this;
    localForage.getItem(`btsession_ ${that.props.location.state.data.id}_${that.props.apiLabel}`, function (err, value) {
        if( value != null ){

            if(value.updated_at < moment().unix() - 1000){
                that.update_session_results();
            }
            var jsonObj = {'results': value.data}
            var newstate =  Object.assign({}, that.state, jsonObj);
            that.setState(newstate);
        }
        else{
            that.update_session_results()
        }
    });
  }

  componentDidMount(){
    this.get_session_results();
  }

  handleChange = event => {
    //this.setState({ [event.target.name]: event.target.value });
    console.log(event.target.name);
    console.log(event.target.value);
    if (event.target.value.length === 0) {
      return;
    }
    this.get_variable_data(event.target.value);
    var newstate =  Object.assign({}, this.state, {
              variable: event.target.value,
              graph: [[], []],
              loading: true}
    );
    this.setState(newstate);
  };


  render() {
    const da = this.state.results === undefined ? [] : this.state.results.data.da.sort(function (a, b) {return a[0] < b[0] ? -1 : 1}).map(x=>[x[0], x[1]]);
    const id = this.state.results === undefined ? [] : this.state.results.data.intraday.sort(function (a, b) {return a.key < b.key ? -1 : 1}).map( (x,index)=>[da[index][0], x.day_values.id_pnl]);
    const ib = this.state.results === undefined ? [] : this.state.results.data.intraday.sort(function (a, b) {return a.key < b.key ? -1 : 1}).map( (x,index)=>[da[index][0], x.day_values.ib_pnl]);
    var total = [];
    if(this.state.results !== undefined){
      for(var i = 0; i < this.state.results.data.intraday.length; i++){
        total.push([da[i][0], da[i][1] + id[i][1] + ib[i][1]]);
      }
    }
    const graph = this.state.results === undefined ? [[]] : [da, id, ib, total];

    const title = this.props.location.state.data.name;
    const options = {
      ...stocksChartOptions({ filename: title }),
      yAxis: [
        {
          title: {
            text: 'Profit(Loss)',
          },
        },
      ],
      series: [
        makeSeries('Day-ahead', graph[0]),
        makeSeries('Intraday', graph[1]),
        makeSeries('Imbalance', graph[2]),
        makeSeries('Total', graph[3]),
      ],
    };

    const { classes } = this.props;
    if (graph && graph[0].length > 0) {
      return (
        <Grid container spacing={4}>
          <Grid item xs={12}>
              <Card style={{backgroundColor: '#EEEEEE'}}>
                <CardHeader>
                  <h4
                    className={classes.cardTitleWhite}
                    style={{color: 'black'}}
                  >
                    {title}
                  </h4>
                </CardHeader>
                <CardBody style={{backgroundColor: '#EEEEEE'}}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={options}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
                <CardFooter style={{backgroundColor: '#EEEEEE'}}>
                  <Button color="primary" onClick={this.props.get_variable_data}>Refresh</Button>
                </CardFooter>
              </Card>
            </Grid>
        </Grid>
      )
    } else{
      return (
        <div className="loader" alt="Loading report..."/>
      )
    }
  }
}


const ConnectedMarketData = connect(mapStateToProps)(SessionResults);
export default withStyles(styles)(ConnectedMarketData);
