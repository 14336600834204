import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle.jsx";

import {
  OAMONITOR_IMAGE_TAG,
} from 'constants/general.js';

function Footer({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <footer className={classes.footer}
      style={{ bottom: '100px' }}
    >
      <div className={classes.container}>
        {rest && rest.isLoggedIn === true ?
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a href="http://integral.solutions" className={classes.block}>
                  Home
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="http://integral.solutions/about-us" className={classes.block}>
                  Company
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="http://integral.solutions/interactive-web-applications/" className={classes.block}>
                  Portfolio
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="http://integral.solutions/blog/" className={classes.block}>
                  Blog
                </a>
              </ListItem>
            </List>
          </div> : null
        }
        <p className={classes.left}>
          <small> Image: {OAMONITOR_IMAGE_TAG} </small>
        </p>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a href="http://samawatt.com" className={classes.a}>
              Samawatt
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
