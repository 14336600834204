import {
  RECEIVED_METRICS_DATA,
  RECEIVED_METRICS_DATA_ALL,
  RECEIVED_METRICS_DATA_NOT_ALL,
  RECEIVED_METRICS_DATA_CHUNK,
  RECEIVED_SUB_DA_METRICS_DATA,
  RECEIVED_SUB_DA_METRICS_DATA_ALL,
  RECEIVED_SUB_DA_METRICS_DATA_NOT_ALL,
  RECEIVED_SUB_DA_METRICS_DATA_CHUNK,
  LOADING_METRICS_DATA,
  LOADING_CHUNKS_METRICS_DATA,
  RESET_METRICS_DATA_ALL_LOADED,
  STOP_METRICS_DATA,
} from "constants/action-types";
import localForage from 'localforage';


const initialState = {
  prices: [],
  subDaPrices: [],
  loading: true,
  loadingChunks: false,
  allDataLoaded: false,
  stopLoading: false,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_METRICS_DATA:
      return {
        ...state,
        loading: true,
        allDataLoaded: false,
      };
    case LOADING_CHUNKS_METRICS_DATA:
      return {
        ...state,
        loading: true,
        loadingChunks: true,
        allDataLoaded: false,
      };
    case RECEIVED_METRICS_DATA:
      return {
        ...state,
        prices: action.prices,
        loading: false,
        allDataLoaded: false,
      };
    case RECEIVED_SUB_DA_METRICS_DATA:
      return {
        ...state,
        subDaPrices: action.prices,
        loading: false,
        allDataLoaded: false,
      };
    case RECEIVED_METRICS_DATA_CHUNK:
      return {
        ...state,
        prices: [...action.prices, ...state.prices],
        loading: false,
      };
    case RECEIVED_SUB_DA_METRICS_DATA_CHUNK:
      return {
        ...state,
        subDaPrices: [...action.prices, ...state.subDaPrices],
        loading: false,
      };
    case RECEIVED_METRICS_DATA_ALL:
      let pricesAll = [...action.prices, ...state.prices];

      // Remove duplicated items in case the part of data
      // was loaded from local storage
      const uniquePricesAll = {};
      pricesAll.map((x) => uniquePricesAll[x.time] = x );
      pricesAll = Object.values(uniquePricesAll);

      // Save all data to local storage
      localForage.setItem(action.key, pricesAll, (err) => {
        if (err !== null) {
          console.log(err);
        }
        return true;
      });

      return {
        ...state,
        prices: pricesAll,
        loading: false,
        loadingChunks: false,
        allDataLoaded: true,
      };
    case RECEIVED_SUB_DA_METRICS_DATA_ALL:
      let subDaPricesAll = [...action.prices, ...state.subDaPrices];

      // Remove duplicated items in case the part of data
      // was loaded from local storage
      const uniqueSubDaPricesAll = {};
      subDaPricesAll.map((x) => uniqueSubDaPricesAll[x.time] = x );
      subDaPricesAll = Object.values(uniqueSubDaPricesAll);

      // Save all data to local storage
      localForage.setItem(action.key, subDaPricesAll, (err) => {
        if (err !== null) {
          console.log(err);
        }
        return true;
      });

      return {
        ...state,
        subDaPrices: subDaPricesAll,
        loading: false,
        loadingChunks: false,
        allDataLoaded: true,
      };
    case RECEIVED_METRICS_DATA_NOT_ALL:
      let prices = [...action.prices, ...state.prices];

      // Remove duplicated items in case the part of data
      // was loaded from local storage
      const uniquePrices = {};
      prices.map((x) => uniquePrices[x.time] = x );
      prices = Object.values(uniquePrices);

      return {
        ...state,
        prices,
        loading: false,
        loadingChunks: false,
        allDataLoaded: false,
        stopLoading: false
      };
    case RECEIVED_SUB_DA_METRICS_DATA_NOT_ALL:
      let subDaPrices = [...action.prices, ...state.subDaPrices];

      // Remove duplicated items in case the part of data
      // was loaded from local storage
      const uniqueSubDaPrices = {};
      subDaPrices.map((x) => uniqueSubDaPrices[x.time] = x );
      subDaPrices = Object.values(uniqueSubDaPrices);

      return {
        ...state,
        subDaPrices,
        loading: false,
        loadingChunks: false,
        allDataLoaded: false,
        stopLoading: false
      };
    case RESET_METRICS_DATA_ALL_LOADED:
      return {
        ...state,
        prices: [],
        subDaPrices: [],
        allDataLoaded: false,
      };
      
    case STOP_METRICS_DATA:
      return {
        ...state,
        stopLoading: action.stop,
        loadingChunks: false,
        loading: false
      };
    default:
      return state;
  }
};
