import {
  drawerWidth,
  transition,
  container
} from "assets/jss/material-dashboard-react.jsx";
import backgroundImg from "assets/img/background.png";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  scrollbar: {
    '& .ps__rail-y': {
      width: '10px',
    },
    '& .ps__thumb-y': {
      width: '10px',
    },
    '& .ps__rail-y:hover': {
      width: '15px',
    },
    '& .ps__thumb-y:hover': {
      width: '15px',
    },
  },
  content: {
    padding: "15px",

    minHeight: "calc(100vh - 200px)",
  },
  container,
  map: {
    marginTop: "70px",
  },
  canvas: {
    backgroundImage: `url('${backgroundImg}')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  loginPanel: {
    float: "none",
    margin: "0 auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  loginContent: {
    display: "flex",
    alignItems: "center"
  },
  supText: {
    fontSize: '12px',
  },
  logo: {
    fontFamily: 'Marcellus, serif',
    marginTop: '5px',
    color: '#2b328f',
  },
  asset: {
    color: '#eb2526',
  },
});

export default appStyle;
