import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import MaterialTable from '@material-table/core';
import PropTypes from 'prop-types';

class ProfitLossReportSummary extends Component {
  componentDidMount() {}

  render() {
    const { classes, tableHead, tableData, cashFlow } = this.props;

    // Header of the table have two rows main and sub header
    const subHeaders = [0, 5, 3, ...(cashFlow ? [5] : [4]), 3, 1];  // indexes of sub header names
    const colSpanIndex = [0, 5, 3, ...(cashFlow ? [5] : [4]), 3, 0];  // indexes of sub header colSpan

    const objFieldHeader = [];
    let sum = 0;

    // Generate object of headers
    tableHead[0].forEach((firstHead, keyFirst) => {
      for (let i = 0; i < subHeaders[keyFirst]; i++) {
        objFieldHeader.push(`${firstHead}|${tableHead[1][i + sum]}`);
      }
      sum = subHeaders[keyFirst] + sum;
    });
    objFieldHeader.unshift('Hour');

    const columns = [];
    objFieldHeader.forEach((prop) => {
      columns.push({
        title: prop,
        field: prop,
        sorting: false,
      });
    });

    const data = tableData.map((data, index) => {
      const obj = {};
      data.forEach((prop, key) => {
        obj[objFieldHeader[key]] = prop;
        obj['id'] = index;
      });
      return obj;
    });

    // Generate first level header
    const genHeaderFirstLevel = () => {
      const headerFirstLevel = [];
      tableHead[0].forEach((column, index) => {
        const cell =
          index === 0 ? (
            <TableCell
              key={`firstlevel_${index}`}
              rowSpan={2}
              className={`${classes.primaryTableHeader} ${classes.borderedRoundHeader} ${classes.stickyCol} ${classes.topZero} ${classes.lightblueCell}`}
            >
              {column}
            </TableCell>
          ) : (
            <TableCell
              key={`firstlevel_${index}`}
              colSpan={colSpanIndex[index]}
              className={`${classes.primaryTableHeader} ${classes.borderedRightHeader} ${classes.borderedTopHeader} ${classes.stickyRowFirst} ${classes.lightblueCell}`}
            >
              {column}
            </TableCell>
          );
        headerFirstLevel.push(cell);
      });
      return headerFirstLevel;
    };
    // Generate second level header
    const genHeaderSecondLevel = () => {
      // get array where increment next value with sum of previous
      const rightBorderId = subHeaders
        .map((_, i, arr) => arr.slice(0, i + 1).reduce((a, b) => a + b, 0));
      const header = [];
      tableHead[1].forEach((column, i) => {
        const cell = (
          <TableCell
            key={`secondlevel_${i}`}
            className={`
              ${classes.primaryTableHeader} 
              ${classes.borderedTopHeader}
              ${classes.stickyRowSecond}
              ${rightBorderId.includes(i+1) && `${classes.borderedRightHeader}`} // set left border for the last cell in the row of each group
            `}
          >
            {column}
          </TableCell>
        );
        header.push(cell);
      });
      return header;
    };

    // Generate data cells
    const genDataCells = (data) => {
      const index = data.tableData.index;
      const data_ = { ...data };
      delete data_.tableData;
      delete data_.id;
      return (
        <TableRow hover id={index} key={`datarow_${index}`}>
          {Object.keys(data_).map((key, i) => (
            <TableCell
              className={`${classes.fundamentalCell} 
                  ${i === 0 && `${classes.lightblueCell} ${classes.stickyCol}`}
                  ${data['Hour'] === 'Total' ? `${classes.boldCell}` : ''}
                  ${cashFlow
                      ? ((i === 0 || i === 6 || i === 7 || i === 8 || i === 14 || i === 15 || i === 16) && data['Hour'] !== 'Total') 
                        ? `${classes.lightgreyCell}` 
                        : ''
                      : ((i === 0 || i === 6 || i === 7 || i === 8 || i === 13 || i === 14 || i === 15) && data['Hour'] !== 'Total')
                        ? `${classes.lightgreyCell}` 
                        : ''
                  }
                `}
              key={`data_${i}`}
            >
              {data[key]}
            </TableCell>
          ))}
        </TableRow>
      );
    };

    return (
      <MaterialTable
        sx={{
          borderCollapse: "separate"
        }}
        columns={columns}
        data={data}
        options={{
          maxBodyHeight: '80vh',
          pageSize: 24,
          draggable: false,
          search: false,
          toolbar: false,
          paging: false,
        }}
        components={{
          Row: ({ data }) => genDataCells(data),
          Header: () => {
            return (
              <TableHead>
                <TableRow key={'first_header_row'}>
                  {genHeaderFirstLevel()}
                </TableRow>
                <TableRow key={'second_header_row'}>
                  {genHeaderSecondLevel()}
                </TableRow>
              </TableHead>
            );
          },
        }}
      />
    );
  }
}

ProfitLossReportSummary.defaultProps = {
  tableHeaderColor: 'primary',
};

ProfitLossReportSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};

export default withStyles(tableStyle)(ProfitLossReportSummary);
