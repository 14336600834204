import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import FileCopy from "@material-ui/icons/FileCopy";
import TrendingFlat from "@material-ui/icons/TrendingFlat";
import TrendingUp from "@material-ui/icons/TrendingUp";
import TrendingDown from "@material-ui/icons/TrendingDown";
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import AccessTime from "@material-ui/icons/AccessTime";
import Grid from '@material-ui/core/Grid';
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import LoginPage from "views/Login/Oops.jsx";
import ModelName from "views/Models/ModelName.jsx";
import ParkAutocomplete from "components/Autocompletes/ParkAutocomplete";
import { connect } from "react-redux";
import localForage from 'localforage';
import moment from 'moment';
import 'moment-timezone';

import { getTrades } from 'utils/getDataMethods';
import {logout} from 'utils/auth';
import {oneDimVol} from 'utils/calcFunctions';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import { get_dashboard_data } from "actions/index";
import { getDefaultPark } from "actions/index";

import { buildAPIRequest, buildKUBERequest, security_fetch_params, getModelsData, downloadParks, getPNLAnalysisData } from "actions/index";
import { downloadParks as downloadStoredParks } from "utils/getDataMethods";
import { emailsSubscriptionChart } from 'variables/charts';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { STRATEGIES, CONTRACTS, DATE_FORMAT } from "../../constants/general";

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    dashboard: state.dashboard,
    loading: state.dashboard.loading,
    da_prices: state.dashboard.da_prices,
    num_optimisations: state.dashboard.num_optimisations,
    latest_id_optimisation: state.dashboard.id_optimisation,
    rte_imbalance_ratio: state.dashboard.rte_imbalance_ratio,
    epex_transactions: state.dashboard.epex_transactions,
    lock: state.dashboard.lock,
    market: state.dashboard.market,
    total_gen_forecast_yesterday: state.total_gen_forecast_yesterday,
    total_gen_yesterday: state.total_gen_yesterday,
    total_imb_na_yesterday: state.total_imb_na_yesterday,
    imbalance_na_yesterday: state.imbalance_na_yesterday,
    imb_chart_opts: state.imb_chart_opts,
    epex_dayahead_y_de: state.epex_dayahead_y_de,
    epex_imb_price_y_de: state.epex_imb_price_y_de,
    epex_dayahead_y_fr: state.epex_dayahead_y_fr,
    epex_imb_price_y_fr: state.epex_imb_price_y_fr,
    strategiesData: state.pnlAnalysis.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_dashboard_data: data => dispatch(get_dashboard_data(data)),
  getModelsData: () => dispatch(getModelsData()),
  fetchData: (data) => dispatch(getPNLAnalysisData(data)),
});

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      park: null,
      imb_chart_opts: emailsSubscriptionChart,
      total_gen_forecast_yesterday: 'N/A',
      total_gen_yesterday: 'N/A',
      total_gen_forecast_11_yesterday: 'N/A',
      total_imb_na_yesterday: 'N/A',
      loading_gen: true,
      epex_dayahead_y_de: 'N/A',
      loading_epex_dayahead_y_de: true,
      epex_dayahead_y_fr: 'N/A',
      loading_epex_dayahead_y_fr: true,
      epex_imb_price_y_de: 'N/A',
      loading_epex_imb_price_y_de: true,
      epex_imb_price_y_fr_pos: 'N/A',
      loading_epex_imb_price_y_fr_pos: true,
      epex_imb_price_y_fr_neg: 'N/A',
      loading_epex_imb_price_y_fr_neg: true,
      total_da_volumes_optimized: 'N/A',
      total_ib_volumes_optimized: 'N/A',
      loading_pl_interval: true,
      ib_volumes_optimised: { 'labels': [], 'series': [] },
      trades: [],
      trades_cnts: { 'labels': [], 'series': [] },
    };
    this.on_park_select = this.on_park_select.bind(this);
    this.get_generation_data = this.get_generation_data.bind(this);
    this.update_generation = this.update_generation.bind(this);
    this.get_market_var_data = this.get_market_var_data.bind(this);
    this.calc_pnl_data = this.calc_pnl_data.bind(this);
    this.count_trades = this.count_trades.bind(this);
    this.get_market_variable = this.get_market_variable.bind(this);
    this._setup_park = this._setup_park.bind(this);
    this._show_waiting = this._show_waiting.bind(this);
    this._show_complete = this._show_complete.bind(this);
    this._show_waiting_all = this._show_waiting_all.bind(this);
    this._show_complete_parkdata = this._show_complete_parkdata.bind(this);
    this.get_windpark_data = this.get_windpark_data.bind(this);
  }

  _show_waiting(elm) {
    if (elm !== null) {
      elm.style.pointerEvents = 'none';
      elm.style.opacity = '0.63';
      elm.style.transition = '0.3s';
    }
  }

  _show_waiting_all() {
    [
      'parkdata',
      //'mktvars',
      //'miscvars',
      //'chartsection',
    ].forEach(i => this._show_waiting(document.getElementById(i)));
  }

  _show_complete(elm) {
    if (elm !== null) {
      elm.style.pointerEvents = '';
      elm.style.opacity = '';
      elm.style.transition = '0.3s';
    }
  }

  _show_complete_parkdata() {
    const elm = document.getElementById('parkdata');
    this._show_complete(elm);
  }

  // copy-paste from Forecast.jsx
  update_windpark(park_id) {
    const [url, headers] = buildAPIRequest(`/api/v1/parks/${park_id}`);
    fetch(url, {
      method: 'GET',
      headers: headers, ...security_fetch_params
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          if (data.error) {
            alertify.error("Response error");
            console.log(data.error);
            return
          }
          data.updated_at = moment().unix();
          localForage.setItem(
            `park_data_${park_id}_${this.props.conn.label}`, data, (err) => {
              if (err) {
                console.log(err);
              }
            }
          );
          if (!data.data) {
            return;
          }
          this.setState(
            { park: data.data },
            this._show_complete_parkdata,
          );
        })
      } else if (response.status === 401) {
        logout();
        return;
      } else {
        console.error('HTTP response error', response);
      };
    }).catch((error) => {
      console.log(error)
    });
  }


  get_windpark_data(park_id) {
    localForage.getItem(`park_data_${park_id}_${this.props.conn.label}`, function (err, value) {
      if (value != null) {
        if ((value.updated_at < moment().unix() - 28800)) {
          this.update_windpark(park_id);
        }
        if (!value.data) {
          return;
        }
        this.setState(
          { park: value.data },
          this._show_complete_parkdata,
        );
      } else {
        this.update_windpark(park_id)
      }
    }.bind(this));
  }
  // copy-paste from Forecast.jsx


  on_park_select(_, value) {
    if (!value) return;

    this._show_waiting_all();
    
    this.get_windpark_data(value.id);
    this.get_generation_data(value.id);
    this.props.get_dashboard_data({ 'park_id': value.id });

    const dt = moment.tz("Europe/Berlin")
      .startOf('day').add(-1, 'day').format('YYYYMMDD');
    getTrades(dt.replaceAll('-', ''), value.id)
    .then((data) => {
      if (data.error) {
        alertify.error("Response error");
        console.log(data.error);
        return
      }
      if (data[0]){
        this.count_trades(data[1])
        this.calc_pnl_data(data[1]);
      }
    })
  }

  _setup_park(park) {
    this._show_waiting_all();

    var st = {};
    st.park = park;
    st.imb_chart_opts = emailsSubscriptionChart;
    st.total_gen_forecast_yesterday = 'N/A';
    st.total_gen_yesterday = 'N/A';
    st.total_gen_forecast_11_yesterday = 'N/A';
    st.total_imb_na_yesterday = 'N/A';
    st.loading_gen = true;
    st.epex_dayahead_y_de = 'N/A';
    st.loading_epex_dayahead_y_de = true;
    st.epex_dayahead_y_fr = 'N/A';
    st.loading_epex_dayahead_y_fr = true;
    st.epex_imb_price_y_fr_pos = 'N/A';
    st.loading_epex_imb_price_y_fr_pos = true;
    st.epex_imb_price_y_fr_neg = 'N/A';
    st.loading_epex_imb_price_y_fr_neg = true;
    st.epex_imb_price_y_de = 'N/A';
    st.loading_epex_imb_price_y_de = true;
    st.total_da_volumes_optimized = 'N/A';
    st.total_ib_volumes_optimized = 'N/A';
    st.loading_pl_interval = true;
    st.trades = [];
    st.imbalance_na_yesterday = { 'labels': [], 'series': [] };
    st.trades_cnts = { 'labels': [], 'series': [] };
    st.ib_volumes_optimised = { 'labels': [], 'series': [] };

    this.setState(st, function () {
      const parkId = this.state.park.id;

      this.props.get_dashboard_data({ 'park_id': parkId });
      this.get_generation_data(parkId);
      this.get_windpark_data(parkId)

      // Germany market data
      this.get_market_variable(19, 'epex_dayahead_y_de', 'EPEX/1200-CET Hourly Day-ahead price');
      this.get_market_variable(19, 'epex_imb_price_y_de', 'reconstruction-aux/Imbalance Price');

      // France market data
      this.get_market_variable(14, 'epex_dayahead_y_fr', 'EPEX/1200-CET Hourly Day-ahead price');

      // France imbalances
      this.get_market_variable(14, 'epex_imb_price_y_fr_pos', 'RTE/RTE Positive Imbalance');
      this.get_market_variable(14, 'epex_imb_price_y_fr_neg', 'RTE/RTE Negative Imbalance');

      const dt = moment.tz("Europe/Berlin").startOf('day').add(-1, 'day').format('YYYYMMDD');
      
      getTrades(dt.replaceAll('-', ''), parkId)
      .then((data) => {
        if (data.error) {
          alertify.error("Response error");
          console.log(data.error);
          return
        }
        if (data[0]){
          this.count_trades(data[1])
          this.calc_pnl_data(data[1]);
        }
      })

    }.bind(this));
  }

  componentDidUpdate (){
    // first time (componentDidMount is too early for the first time)
    if ((this.props.conn.parks?.length > 0) && !this.state.park) {
      var park = getDefaultPark();

      if (park !== null) {
        this._setup_park(park);
      }
    }
  }

  storingStrategiesParks = async () => {
    const data = await localForage.getItem("strategies_parks");

    if(!data || data.updated_at < moment().unix() - 43200) {
      let obj = {};
      STRATEGIES.forEach((strategy) => {
        const onParksLoaded = (data) => {
          obj = { ...obj, [strategy]: data.data };
          if (Object.keys(obj).length === STRATEGIES.length) {
            const dataObj = {
              data: obj,
              updated_at: moment().unix(),
            }
            localForage.setItem("strategies_parks", dataObj, (err) => {
             err && console.log(err);
            })
          }
        };
        downloadParks({ strategies: [strategy], CONTRACTS }, onParksLoaded);
      })
    }
  }

  async storingStrategiesData() {
    const parks = await downloadStoredParks();
      const period = [moment().add(-7, 'days')];
      period.forEach(date => this.props.fetchData({
        parks: parks.data, 
        dateFrom: date.format(DATE_FORMAT), 
        dateTo: moment().add(-1, 'days').format(DATE_FORMAT),
        preloading: false,
        key: 'top_strategies',
      }))
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      var park = getDefaultPark();
      if (park !== null) {
        this._setup_park(park);
        this.get_generation_data(park.id);
      }
      this.props.getModelsData();
    }
    
   this.storingStrategiesParks();
   this.storingStrategiesData()

   const getAiQuotes = async function (prompt) {
      const endpoint = 'https://api.openai.com/v1/chat/completions';
      const headers = {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json'
      };

      const requestBody = {
        model: "gpt-4o-mini",
        messages: [{role: "user", content: prompt}],
        max_tokens: 8000,
      };

      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        return responseData.choices[0].message.content; 
      } catch (error) {
        console.error('Error:', error);
        return null;
      }
    }

    const getQuotes = async () => {
      const stored = localStorage.getItem('quotesObj');

      if (!stored) {
        const text = await getAiQuotes('Generate a JSON object with an array of a 50 unique motivational or good work ethics quotes on a trading or energetic topic, up to 20 words with a positive context and the author credit. Each quote should be in format `"quote" - author`, if author unknown write Unknown, each quote in array should be a string. Your response should contain just a json');
        const jsonStr = `${text.replaceAll('`', '').replace('json', '')}`
        localStorage.setItem('quotesObj', jsonStr);
      }
    }
    getQuotes()
  };

  count_trades(data) {   
      var trades = (data.trades || []);
      var tcnt = {
        labels: [],
        series: [[], []]
      };
      var rd = {}
      for (var i = 0; i < trades.length; i++) {
        var t = moment(trades[i].tradetime).tz('UTC').format('H');
        if (!(t in rd)) {
          rd[t] = { 'p': 0, 'n': 0 };
        }
        if (trades[i].volume >= 0) {
          rd[t].p += trades[i].volume;
        } else {
          rd[t].n += trades[i].volume;
        }
      }
      for (var prop in rd) {
        tcnt.labels.push(prop);

        tcnt.series[0].push(rd[prop].p);
        tcnt.series[1].push(rd[prop].n);
      };
      this.setState({
        trades: trades,
        trades_cnts: tcnt,
      });
      setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 500);
      return true;
  }

  calc_pnl_data(data) {
    const da_volumes_optimised = oneDimVol(
      JSON.parse(data.report.da_volumes_samawatt),
      data.parameters.dt,
      data.imbalance_prices.IBU.length * data.parameters.ib_dt
    ).map((x, index) => {
      return [
        moment(data.report.date).add(index, 'hours').unix() * 1000,
        x
      ];
    })
    const da_volumes_base = data.base_volumes.map((x, index) => {
      return [
        moment(data.report.date).add(index, 'hours').unix() * 1000,
        x
      ];
    })
    const ib_volumes_optimised = JSON.parse(data.report.ib_volumes_samawatt).map((x, index) => {
      return [
        moment(data.report.date).add(index, 'hours').unix() * 1000,
        x
      ];
    })
    const ib_volumes_base = JSON.parse(data.report.ib_volumes_customer).map((x, index) => {
      return [
        moment(data.report.date).add(index, 'hours').unix() * 1000,
        x
      ];
    })
    
    //extra_data consists of da_dt, id_dt, and ib_dt, i.e. the granularity of Day-ahead contracts, Intraday contracts, and Imbalance intervals
    const ibPer = data.parameters.ib_dt;
    
    const itemsPerHour = 1 / ibPer;
    const len = ib_volumes_optimised.length;
    const imbHourData = [];
    for (let i = 0; i < len; i = i + itemsPerHour) {
      let sumVol = 0;
      for (let j = 0; j < itemsPerHour; j++) {
        sumVol += ib_volumes_optimised[i + j][1];
      }
      imbHourData.push(sumVol);
    }
    
    let imb_opti = { 'labels': [], 'series': [] }
    let da_opti = { 'labels': [], 'series': [] }
    imb_opti.series[0] = [];
    da_opti.series[0] = [];
    for (let k = 0; k < imbHourData.length; k++) {
      imb_opti.labels.push(k);
      imb_opti.series[0].push(imbHourData[k]);
    }
    for (let k = 0; k < da_volumes_optimised.length; k++) {
      da_opti.labels.push(k);
      da_opti.series[0].push(da_volumes_optimised[k][1])
    }

    function add(accumulator, a) {
      return accumulator + a;
    }
    function add_abs(accumulator, a) {
      return accumulator + Math.abs(a);
    }
    const total_ib_volumes_optimized = JSON.parse(data.report.ib_volumes_samawatt).reduce(add_abs, 0);

    // da_volumes_samawatt is an array of arrays
    const da_volumes_optimized = JSON.parse(data.report.da_volumes_samawatt);
    let total_da_volumes_optimized = 0;
    for(let i = 0; i < da_volumes_optimized.length; i++){
      total_da_volumes_optimized += da_volumes_optimized[i].reduce(add, 0);
    };
    
    this.setState({
      da_volumes_optimised: da_opti,
      da_volumes_base,
      ib_volumes_optimised: imb_opti,
      ib_volumes_base,
      total_da_volumes_optimized: total_da_volumes_optimized.toFixed(2),
      total_ib_volumes_optimized: total_ib_volumes_optimized.toFixed(2),
      loading_pl_interval: false,
    });
    setTimeout(function () { window.dispatchEvent(new Event('resize')); }, 500);
  };

  get_market_variable(market_id, store_name, variable) {
    var key = `market_var_${market_id}_${store_name}_${this.props.conn.label}`;
    localForage.getItem(key, (err, value) => {
      if (value != null) {
        if ((value.updated_at < moment().unix() - 60000)) {
          this.get_market_var_data(market_id, store_name, variable);
          return;
        }
        this.setState({ [value['name']]: value['value'].toFixed(2),[`loading_${value['name']}`]:false});
      } else {
        this.get_market_var_data(market_id, store_name, variable)
      }
    });
  }

  get_market_var_data(market_id, store_name, variable) {
    var that = this;
    const [url, headers] = buildKUBERequest('/api/markets/rich_values/' + market_id + '/' + encodeURIComponent(variable));
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then(data => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            function avg_data_yesterday(ts_gen_seq) {
              // Start is at 11am yesterday
              var start = (moment.tz("Europe/Berlin").startOf('day').unix() - (86400)) * 1000;
              // End is 11pm yesterday
              var end = (moment.tz("Europe/Berlin").endOf('day').unix() - (86400)) * 1000;
              var sum = 0;
              var cnt = 0;
              for (var i = 0; i < ts_gen_seq.length; i++) {
                if ((ts_gen_seq[i][0] >= start) && (ts_gen_seq[i][0] < end)) {
                  sum += ts_gen_seq[i][1];
                  cnt += 1;
                }
              }
              return sum / cnt;
            };

            if (data.data === undefined || data.data.length === 0) {
              that.setState({[`loading_${store_name}`]:false });
              return;
            }

            var varAvg = avg_data_yesterday(data.data[0]);

            var key = `market_var_${market_id}_${store_name}_${this.props.conn.label}`;
            var vdata = { 'updated_at': moment().unix(), 'value': varAvg, 'name': store_name };
            localForage.setItem(key, vdata, function (err) {
              if (err !== null) {
                console.log(err, key, vdata);
              }
            });
            that.setState({ [store_name]: varAvg.toFixed(2), [`loading_${store_name}`]:false });
          })
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => { console.log(error) });
  };

  update_generation(park_id) {
    const [url, headers] = buildAPIRequest(`/api/windparks/generation/${park_id}`);
    var that = this;
    fetch(url, { method: 'GET', headers: headers, options: { timeout: 5000 }, ...security_fetch_params })
      .then(function (response) {
        if (response.ok) {
          response.json().then(data => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            var generation = data;
            function sum_generation_yesterday(ts_gen_seq) {
              // ts_gen_seq: Array where each element [unix_timestamp, generation]
              var start = (moment.tz("UTC").startOf('day').unix() - (86400)) * 1000;
              var end = (moment.tz("UTC").endOf('day').unix() - (86400)) * 1000;
              var sum = 0;
              var out = {};
              for (var i = 0; i < ts_gen_seq.length; i++) {
                if ((ts_gen_seq[i][0] >= start) && (ts_gen_seq[i][0] <= end)) {
                  sum += ts_gen_seq[i][1];
                  out[ts_gen_seq[i][0]] = ts_gen_seq[i][1];
                }
              }
              return [sum, out];
            };

            function get_deltas(fcast11, generation) {
              var total = 0;
              var imbalance = { 'labels': [], 'series': [] }
              imbalance.series[0] = [];
              for (var k in fcast11) {
                var delta = generation[k] - fcast11[k];
                total += Math.abs(delta);
                //imbalance.labels.push(Date(parseInt(k)).format('h'));
                imbalance.labels.push(moment(parseInt(k)).tz('UTC').format('H'));

                imbalance.series[0].push(delta);
              }
              return [total, imbalance];
            };

            generation.updated_at = moment().unix();
            try {
              var [prod, prod_s] = sum_generation_yesterday(generation.data.power || []);
              // eslint-disable-next-line
              var [forecast, _] = sum_generation_yesterday(generation.data.latest_forecast[0] || []);
              var [forecast_11, forecast_11s] = sum_generation_yesterday(generation.data.forecast[0] || []);
              var [imb_total, imb_seq] = get_deltas(forecast_11s, prod_s);
              var opts = emailsSubscriptionChart;
              opts.options.low = Math.min(...imb_seq.series[0]);
              opts.options.high = Math.max(...imb_seq.series[0]);
              const gdata = {
                'total_gen_forecast_yesterday': forecast.toFixed(2),
                'total_gen_forecast_11_yesterday': forecast_11.toFixed(2),
                'total_gen_yesterday': prod.toFixed(2),
                'total_imb_na_yesterday': imb_total.toFixed(2),
                'imbalance_na_yesterday': imb_seq,
                'loading_gen': false,
              };
              that.setState(gdata, that._show_complete_parkdata);
              localForage.setItem(`gen_park_data_${park_id}_${that.props.conn.label}`, gdata, function (err) {
                if (err) {
                  console.log(err);
                }
              });
            } catch (e) {
              console.log("Error on processing generation and forecast data: " + e);
              that.setState({
                'total_gen_forecast_yesterday': 'N/A',
                'total_gen_forecast_11_yesterday': 'N/A',
                'total_gen_yesterday': 'N/A',
                'total_imb_na_yesterday': 'N/A',
                'imbalance_na_yesterday': { 'labels': [], 'series': [] },
                'imb_chart_opts': emailsSubscriptionChart,
                'loading_gen': true,
              }, that._show_complete_parkdata);
            }
          })
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => { console.log(error) })
  }

  get_generation_data(park_id) {
    localForage.getItem(`gen_park_data_${park_id}_${this.props.conn.label}`, (err, value) => {
      if (value != null) {
        if ((value.updated_at < moment().unix() - 60000)) {
          this.update_generation(park_id);
        } else {
          this.setState(value, this._show_complete_parkdata);
        }
      } else {
        this.update_generation(park_id);
      }
    });
  }

  render() {
    const { 
      classes,
      rte_imbalance_ratio,
      da_prices
     } = this.props;
     const {
      park,
     } = this.state;
    var lockStatus = 1;
    if (this.props.lock[0][Object.keys(this.props.lock[0])[0]] !== "OK") {
      lockStatus = -1;
    }
    if (this.props.lock[1][Object.keys(this.props.lock[1])[0]] !== "OK") {
      lockStatus = -1;
    }
    if (moment.tz(moment(), 'CET').hour() > 10) {
      if (Object.keys(this.props.lock[0])[0] !== moment.tz(moment(), 'CET').format('YYYYMMDD')) {
        if (lockStatus > 0) {
          lockStatus = 0;
        }
      }
    } else {
      if (Object.keys(this.props.lock[1])[0] !== moment.tz(moment(), 'CET').format('YYYYMMDD')) {
        if (lockStatus > 0) {
          lockStatus = 0;
        }
      }
    }

    var epex_transactions = this.props.epex_transactions;
    if (epex_transactions === undefined) {
      epex_transactions = { today: [{ count: 0, country: "DE" }, { count: 0, country: "FR" }], yesterday: [{ count: 0, country: "DE" }, { count: 0, country: "FR" }] };
    }

    if (this.props.isLoggedIn && (park !== null)) {

      //collect time positions when all optimisations should be done  
      const parkSchedule = park?.schedule;
      const scheduleStages = parkSchedule?.split(';');
      const stagesNumber = scheduleStages?.length;
      const optShouldBeArr = [];
      let optShouldBe = {}; //number of optimisations should be done befor now time
      let optimisationsCount = {};
      let curAuNumber = 0;
      let curIdNumber = 0;
      let optimisationsOk = false;
      if(this.props.num_optimisations !== 'N/A'){
        for(let i = 0; i < stagesNumber; i++){
          const parsCron = scheduleStages[i].split(' ');
          const minutes = parsCron[0];
          const hours = parsCron[1];
          const hourMinArr = [];
          if(hours) {
            if(hours === '*'){
              for(let hour = 0; hour < 24; hour++){
                minutes.split(',').forEach(min=>{
                  hourMinArr.push([hour, Number(min)]);  
                });
              }
            }else{
              hours.split(',').forEach(hour=>{
                minutes.split(',').forEach(min=>{
                  hourMinArr.push([hour, Number(min)]);
                });
              });
            }
          }

          optShouldBeArr[i] = hourMinArr;
          //sort by time
          optShouldBeArr[i].sort((a,b)=>(Date.parse(`01/01/1970 ${a[0]}:${a[1]}:00`) < Date.parse(`01/01/1970 ${b[0]}:${b[1]}:00`) ? -1 : 1));
          const hoursBeforeDelivery = park?.optimization_job?.hours_before_delivery;
          const tz = park?.market?.tz_long;
  
          optimisationsCount[i] = this.props.num_optimisations?.[i] ? this.props.num_optimisations[i] : 0;
  
          // check what type of stage is
          let currentStageType;
          if(park.optimization_job.entry_over){
            if(park.optimization_job.entry_over[i].afternoon_run) {
              currentStageType = 'ID';
              curIdNumber += 1;
            }
            else{
              currentStageType = 'AU';
              curAuNumber += 1;
            }
          } else {
            if(park.optimization_job.afternoon_run) {
              currentStageType = 'ID';
              curIdNumber += 1;
            }
            else{
              currentStageType = 'AU';
              curAuNumber += 1;
            }
          };
  
          if(tz){
            const currentMinute = moment().tz(tz).minute();
            let currentHour = moment.tz(tz).hour();
            let shouldBe = 0;
            if(currentStageType === 'ID'){
              if (currentHour > 23 - hoursBeforeDelivery) {
                shouldBe = optShouldBeArr[i].filter(el=>
                  Date.parse(`01/01/1970 ${el[0]}:${el[1]}:00`) <= Date.parse(`01/01/1970 ${currentHour}:${currentMinute}:00`) 
                  &&
                  Date.parse(`01/01/1970 ${el[0]}:${el[1]}:00`) >= Date.parse(`01/01/1970 ${23 - hoursBeforeDelivery}:00:00`)
                  ).length;
              }
              else {
                shouldBe = optShouldBeArr[i].filter(el=>
                  Date.parse(`01/01/1970 ${el[0]}:${el[1]}:00`) <= Date.parse(`01/01/1970 ${currentHour}:${currentMinute}:00`) 
                  ||
                  Date.parse(`01/01/1970 ${el[0]}:${el[1]}:00`) >= Date.parse(`01/01/1970 ${23 - hoursBeforeDelivery}:00:00`)
                  ).length;
              }
            } else {
              shouldBe = optShouldBeArr.length;
            }
            optShouldBe[i] = {value: shouldBe, label: `${currentStageType}-${currentStageType === 'AU' ? curAuNumber : curIdNumber}`};
          }
        }
      }

      if(
        Object.keys(optShouldBe).length === stagesNumber && 
        Object.keys(optimisationsCount).every((el,id) => optimisationsCount[el] === optShouldBe[id].value)
        ){
        optimisationsOk = true;
      }


      let rte_ratio_today = -1;
      let rte_ratio_yesterday = -1;
      try {
        if(typeof rte_imbalance_ratio.today === 'number' && typeof rte_imbalance_ratio.yesterday === 'number'  ){
          rte_ratio_today = rte_imbalance_ratio.today.toFixed(2);
          rte_ratio_yesterday = rte_imbalance_ratio.yesterday.toFixed(2);
        }
      } catch (error) {
        console.log('RTE ratio error:', error);
      }

      if (!this.props.latest_id_optimisation.expected_profit) {
        this.props.latest_id_optimisation.expected_profit = 0;
      }

      let activePark = this.props.conn.parks.filter(
        (item) => item.id === park.id
      );
      activePark = activePark.length ? activePark[0] : null;
      return (
        <>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <ParkAutocomplete
                options={this.props.conn.parks}
                selectedOption={activePark}
                onChange={this.on_park_select}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} id="parkdata">
            <Grid item xs={12} sm={6} md={3}>
              <Card>
              <CardHeader color={this.state.loading_gen ? "gray" : "success"} stats icon>
                  <CardIcon color={this.state.loading_gen ? "gray" : "success"} >
                    {/*<Icon>content_copy</Icon>*/}
                    <h2> Gen </h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Total Generation</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.total_gen_yesterday} MWh</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.state.loading_gen ? "gray" : "success"} stats icon>
                  <CardIcon color={this.state.loading_gen ? "gray" : "success"} >
                    {/*<Icon>content_copy</Icon>*/}
                    <h2> Fct </h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Total Generation Forecast</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.total_gen_forecast_yesterday} MWh</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.state.loading_gen ? "gray" : "success"} stats icon>
                  <CardIcon color={this.state.loading_gen ? "gray" : "success"} >
                    {/*<Icon>content_copy</Icon>*/}
                    <h2>F11</h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Total Generation Forecast 11 am</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.total_gen_forecast_11_yesterday} MWh</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.state.loading_pl_interval ? "gray" : "success"} stats icon>
                  <CardIcon color={this.state.loading_pl_interval ? "gray" : "success"} >
                    {/*<Icon>content_copy</Icon>*/}
                    <h2>DA</h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Total Day-ahead Optimized</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.total_da_volumes_optimized} MWh</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.state.loading_pl_interval ? "gray" : "warning"} stats icon>
                  <CardIcon color={this.state.loading_pl_interval ? "gray" : "warning"} >
                    {/*<Icon>content_copy</Icon>*/}
                    <h2>Imb</h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Total Imbalance Optimized</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.total_ib_volumes_optimized} MWh</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.state.loading_gen ? "gray" : "warning"} stats icon>
                  <CardIcon color={this.state.loading_gen ? "gray" : "warning"} >
                    {/*<Icon>content_copy</Icon>*/}
                    <h2>Imb</h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Naive Imbalance Total</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.total_imb_na_yesterday} MWh</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={4} id="mktvars">
            {/* Market data dashboard */}
            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.state.loading_epex_dayahead_y_de ? "gray" : "info"} stats icon>
                  <CardIcon color={this.state.loading_epex_dayahead_y_de ? "gray" : "info"} >
                    <h2> DE </h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Average Day-Ahead Price</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.epex_dayahead_y_de} €</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.state.loading_epex_dayahead_y_fr ? "gray" : "info"} stats icon>
                  <CardIcon color={this.state.loading_epex_dayahead_y_fr ? "gray" : "info"} >
                    <h2> FR </h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Average Day-Ahead Price</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.epex_dayahead_y_fr} €</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
                <CardHeader color={this.state.loading_epex_imb_price_y_de ? "gray" : "warning"} stats icon>
                  <CardIcon color={this.state.loading_epex_imb_price_y_de ? "gray" : "warning"} >
                    <h2> DE </h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Average Imbalance Price</p>
                  <h3 className={classes.cardTitle}>
                    <small> {this.state.epex_imb_price_y_de} €</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.state.loading_epex_imb_price_y_fr_pos && this.state.loading_epex_imb_price_y_fr_neg ? "gray" : "warning"} stats icon>
                  <CardIcon color={this.state.loading_epex_imb_price_y_fr_pos && this.state.loading_epex_imb_price_y_fr_neg ? "gray" : "warning"} >
                    <h2> FR </h2>
                  </CardIcon>
                  <p className={classes.cardCategory}>Average Imbalance Price</p>
                  <h3 className={classes.cardTitle}>
                    <small>Pos: {this.state.epex_imb_price_y_fr_pos} €</small>
                  </h3>
                  <h3 className={classes.cardTitle}>
                    <small>Neg: {this.state.epex_imb_price_y_fr_neg} €</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    Period: Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={4} id="miscvars">
            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
                <CardHeader color={this.props.loading ? "gray" : optimisationsOk ? "success" : "danger"} stats icon>
                  <CardIcon color={this.props.loading ? "gray" : optimisationsOk ? "success" : "danger"} >
                    <Icon>content_copy</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Model parameters</p>
                  {optShouldBe ?
                  <h3 className={classes.cardTitle}>
                      <div>
                        <small>
                          {`Optimisations: 
                            ${Object.keys(optShouldBe)
                              .map(
                                (el, id) =>
                                  `${optShouldBe[el].label}:${
                                    optimisationsCount[id] !== undefined ? optimisationsCount[id] : 'N/A'
                                  }/${optShouldBe[el].value}`
                              )
                              .join(', ')}`}
                        </small>
                      </div>
                  </h3> : <br/>}
                  <h3 className={classes.cardTitle}>
                    <div><small>Market: {park.market?.country_iso3166a2}</small></div>
                  </h3>
                  <h3 className={classes.cardTitle}>
                    <div><small><ModelName parkId={park.id}/></small></div>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Last run:{' '}
                    {this.props.latest_id_optimisation.computing_finish}
                  </div>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
                <CardHeader color={this.props.loading ? "gray" : da_prices.today > 0 && da_prices.tomorrow > 0 ? "success" :
                  da_prices.today > 0 ? "warning" : "danger"} stats icon>
                  <CardIcon color={this.props.loading ? "gray" : da_prices.today > 0 && da_prices.tomorrow > 0 ? "success" :
                    da_prices.today > 0 ? "warning" : "danger"}>
                    <Store />
                  </CardIcon>
                  <p className={classes.cardCategory}>DA prices</p>
                  <br/>
                  <h3 className={classes.cardTitle}><small>{da_prices.today > 0 ? 'Downloaded for today' : 'Missing for today'}</small></h3>
                  <h3 className={classes.cardTitle}><small>{da_prices.tomorrow > 0 ? 'Downloaded for tomorrow' : 'Missing for tomorrow'}</small></h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    {da_prices.tomorrow > 0 ? 'Download sucess' : 'Check out download after 12:10PM UTC'}
                  </div>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
              <CardHeader color={this.props.loading ? "gray" : "info"} stats icon>
                  <CardIcon color={this.props.loading ? "gray" : "info"} >
                    {rte_ratio_today > rte_ratio_yesterday + 1 ?
                      <TrendingUp /> : rte_ratio_today < rte_ratio_yesterday - 1 ?
                        <TrendingDown /> : <TrendingFlat />
                    }
                  </CardIcon>
                  <p className={classes.cardCategory}>RTE negative / positive ratio</p>
                  <h3 className={classes.cardTitle}><small>{`Today: ${rte_ratio_today === -1 ? 'N/A' : rte_ratio_today}`}</small></h3> 
                  <h3 className={classes.cardTitle}><small>{`Yesterday: ${rte_ratio_yesterday === -1 ? 'N/A' : rte_ratio_yesterday}`}</small></h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Update />
                    {Math.abs(rte_ratio_today - rte_ratio_yesterday) < 1 ? 'Ratio did not change' : 'Ratio changed'}
                  </div>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.flex}>
              <Card>
                <CardHeader color={this.props.loading ? "gray" : (epex_transactions.today.length && epex_transactions.yesterday.length && epex_transactions.today[0].count > 700 && epex_transactions.yesterday[0].count > 3000) ? "success" :
                  (epex_transactions.today.length && epex_transactions.yesterday.length && epex_transactions.today[0].count > 500 && epex_transactions.yesterday[0].count > 1000) ? "warning" : "danger"} stats icon>
                  <CardIcon color={this.props.loading ? "gray" : (epex_transactions.today.length && epex_transactions.yesterday.length && epex_transactions.today[0].count > 700 && epex_transactions.yesterday[0].count > 3000) ? "success" :
                    (epex_transactions.today.length && epex_transactions.yesterday.length && epex_transactions.today[0].count > 500 && epex_transactions.yesterday[0].count > 1000) ? "success" : "danger"}>
                    <FileCopy />
                  </CardIcon>
                  <p className={classes.cardCategory}>EPEX Transactions</p>
                  <p />
                  <br/>
                  <h3 className={classes.cardTitle}><small>
                    {moment().tz('Europe/Paris').format('DD.MM: ')} 
                    
                    { (epex_transactions.today.length && epex_transactions.yesterday.length) ? 
                        epex_transactions.today[0].count === 'N/A' && epex_transactions.today[1].count  === 'N/A' ? 'N/A' :
                          `${epex_transactions.today[0].country} :  ${window.numberWithCommas(epex_transactions.today[0].count)} ;
                          ${epex_transactions.today.length > 1 ? (epex_transactions.today[1].country + ': ' + window.numberWithCommas(epex_transactions.today[1].count)) : ''}` 
                      : "no data"}</small>
                  </h3>
                  <h3 className={classes.cardTitle}><small>
                    {moment().tz('Europe/Paris').add(-1, 'days').format('DD.MM: ')} 
                    { (epex_transactions.today.length && epex_transactions.yesterday.length) ? 
                        epex_transactions.yesterday[0].count === 'N/A' && epex_transactions.yesterday[1].count  === 'N/A' ? 'N/A' :
                          `${epex_transactions.yesterday[0].country} :  ${window.numberWithCommas(epex_transactions.yesterday[0].count)} ;
                          ${epex_transactions.yesterday[1].country}  :  ${window.numberWithCommas(epex_transactions.yesterday[1].count)}` 
                      : "no data"}</small>
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Update />
                    {(epex_transactions.today.length && epex_transactions.yesterday.length && epex_transactions.today[0].count > 500 && epex_transactions.yesterday[0].count > 3000) ? 'Download success' : 'EPEX FTP download probably failed'}
                  </div>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={4} id="chartsection">
            {/*Imbalance graph*/}
            <Grid item xs={12} sm={12} md={6}>
              <Card chart>
                <CardHeader color="success">
                  <ChartistGraph
                    className="ct-table"
                    data={
                      this.state.da_volumes_optimised 
                        ? this.state.da_volumes_optimised 
                        : {} 
                    }
                    type="Bar"
                    options={null}
                    responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                    listener={emailsSubscriptionChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>Day Ahead volumes</h4>
                  <p className={classes.cardCategory}>
                  </p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card chart>
                <CardHeader color="warning">
                  <ChartistGraph
                    className="ct-chart"
                    data={
                      this.state.ib_volumes_optimised 
                        ? this.state.ib_volumes_optimised 
                        : {}
                    }
                    type="Bar"
                    options={null}
                    responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                    listener={emailsSubscriptionChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>Imbalance Optimized Yesterday</h4>
                  <p className={classes.cardCategory}>
                  </p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> Yesterday
                  </div>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
        </>
      )
    } else {
      return (
        <LoginPage />
      );
    }
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};


const DashboardConnect = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withStyles(dashboardStyle)(DashboardConnect);
