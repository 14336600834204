import { CHANGE_CONNECTION } from "constants/action-types";
import { REFRESH_GW_TOKEN } from "constants/action-types";
import { UPDATE_PARKS_INFO } from "constants/action-types";
import { UPDATE_ACTIVE_PARK } from "constants/action-types";
import { UPDATE_CURRENT_PAGE } from "constants/action-types";
import { UPDATE_CURRENT_REGION } from "constants/action-types";
import { UPDATE_CURRENT_ANALYS_MARKET } from "constants/action-types";
import { UPDATE_LOGIN_INSTANCES } from "constants/action-types";

import { CHANGE_VISIBLE_ROUTES } from "constants/action-types";
import { UPDATE_PNL_CURRENT_SETTINGS } from "constants/action-types";
import { UPDATE_BATTERY_TRADING_SETTINGS } from "constants/action-types";

const routes = JSON.parse(localStorage.getItem('visibleRoutes'));
const initialState = sessionStorage.getItem('conn') ? JSON.parse(sessionStorage.getItem('conn')) : {};

export default (state = {...initialState, visibleRoutes: routes}, action) => {
  if (action.type === CHANGE_CONNECTION) {
    if (!('TG' in action.payload)) {
      state.TG = undefined;
    }
    return {...action.payload};
  } else if (action.type === REFRESH_GW_TOKEN) {
    if ('TG' in state) {
      // console.log("Exists: ", state.TG);
    }
  } else if (action.type === UPDATE_PARKS_INFO) {
    return { ...state, ...action.conn };
  } else if (action.type === UPDATE_ACTIVE_PARK) {
    return { ...state, activePark: action.payload };
  } else if (action.type === UPDATE_CURRENT_PAGE) {
    return { ...state, curPage: action.payload };
  } else if (action.type === UPDATE_CURRENT_REGION) {
    return { ...state, curRegion: action.payload };
  } else if (action.type === UPDATE_CURRENT_ANALYS_MARKET) {
    return { ...state, curAnalysMarket: action.payload };
  } else if (action.type === CHANGE_VISIBLE_ROUTES) {
    return { ...state, visibleRoutes: action.payload };
  } else if (action.type === UPDATE_PNL_CURRENT_SETTINGS) {
    return { ...state, pnlCurrentSettings: action.payload };
  } else if (action.type === UPDATE_BATTERY_TRADING_SETTINGS) {
    return { ...state, batteryTradingSettings: action.payload };
  } else if (action.type === UPDATE_LOGIN_INSTANCES) {
    return { ...state, loginInstances: action.payload };
  }

  return state;
};
