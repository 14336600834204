import React, { Component } from 'react';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";
import PropTypes from "prop-types";
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import 'moment-timezone';

class Trades extends Component {
  constructor() {
    super();
    this.state = {
      selected: [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
    this.props.set_selected(newSelected);
  };

  // move to componentDidMount ?
  componentWillReceiveProps(nextProps){
    if(nextProps.selected !== this.state.selected){
      this.setState({selected: nextProps.selected});
    }
  }

  handleChangePage = (event, page) => {
    this.setState({...this.state, page: page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({...this.state, rowsPerPage: event.target.value });
  };


  render() {
    const { classes, tableHead, tableData, tableHeaderColor } = this.props;
    if (tableData === undefined || tableData.length === 0) {
        return(<div />);
    } else {
    return (
      <div className={classes.tableResponsive}>
          <Table style={{tableLayout: "auto" }}>
            <colgroup>
                <col width="10%" />
                <col width="20%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="20%" />
                <col width="10%" />
                <col width="10%" />
            </colgroup>

            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      style={{textAlign: 'center'}}
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map( (row, index) => {
                return (
                  <TableRow key={row[0].toString()}>
                    <TableCell align="right"
                      style={{whiteSpace: 'nowrap',
                        paddingLeft: '0px',
                        paddingRight: '5px',
                        textAlign: 'right',
                        fontWeight: row[0] === 0 ? 800 : 'normal'
                      }}>{row[0] === 0 ? "Total" : row[0]}
                    </TableCell>

                    <TableCell component="th" scope="row"
                      style={{whiteSpace: 'nowrap',
                        padding: 0,
                        textAlign: 'center'
                      }}
                    >
                      {row[0] === 0 ? '' : moment.tz(row[1], 'UTC').format('YYYY-MM-DD HH:mm')}
                    </TableCell>
                    <TableCell align="center"
                      style={{whiteSpace: 'nowrap',
                        padding: 0,
                        textAlign: 'center',
                        fontWeight: row[0] === 0 ? 800 : 'normal',
                        color: row[0] === 0 ? (row[4] > 0 ? 'green' : row[4] < 0 ? 'red' : 'black') : 'black'
                      }}>{row[2]}
                    </TableCell>
                    <TableCell align="right"
                      style={{whiteSpace: 'nowrap',
                        padding: 0,
                        textAlign: 'right',
                        fontWeight: row[0] === 0 ? 800 : 'normal'
                      }}>{row[0] > 0 || Math.abs(row[3]) > 0 ? row[3].toFixed(2) : ''}
                    </TableCell>
                    <TableCell align="right"
                      style={{whiteSpace: 'nowrap',
                        paddingLeft: '0px',
                        paddingRight: '5px',
                        textAlign: 'right',
                        fontWeight: row[0] === 0 ? 800 : 'normal',
                        color: row[0] === 0 ? (row[4] > 0 ? 'green' : row[4] < 0 ? 'red' : 'black') : 'black'
                      }}>{Math.abs(row[4]).toFixed(1)}
                    </TableCell>
                    <TableCell component="th" scope="row"
                      style={{whiteSpace: 'nowrap',
                        padding: 0,
                        textAlign: 'center'
                      }}
                    >
                      {row[0] === 0 ? '' : moment.tz(row[5], 'UTC').format('YYYY-MM-DD HH:mm')}
                    </TableCell>
                    <TableCell align="center"
                      style={{whiteSpace: 'nowrap',
                        padding: 0,
                        textAlign: 'center'
                      }}>{row[0] === 0 ? '' : row[6]}
                    </TableCell>
                    <TableCell align="center"
                      style={{whiteSpace: 'nowrap',
                        padding: 0,
                        textAlign: 'center'
                      }}><a href={"#O" + row[7]}>{row[0] === 0 ? '' : row[7]}</a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={tableData === undefined ? 0 : tableData.length}
            rowsPerPage={this.state.rowsPerPage}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
          />
        </div>
    );}
  }
}

Trades.defaultProps = {
  tableHeaderColor: "primary"
};

Trades.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
};

export default withStyles(tableStyle)(Trades);
