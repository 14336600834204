import {
  LOADING_FUNDAMENTALS_AUDIT,
  LOADING_FUNDAMENTALS_CURVE,
  RECEIVED_FUNDAMENTALS_AUDIT,
  RECEIVED_FUNDAMENTALS_CURVE,
} from 'constants/action-types';

const initialState = {
  fundamentals: null,
  loading: true,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_FUNDAMENTALS_AUDIT ||
    action.type === LOADING_FUNDAMENTALS_CURVE) {
    return {
      ...state,
      ...initialState,
    };
  } else if (action.type === RECEIVED_FUNDAMENTALS_AUDIT) {
    return {
      ...state,
      fundamentals: action.fundamentals.data,
      loading: false,
    };
  } else if (action.type === RECEIVED_FUNDAMENTALS_CURVE) {
    return {
      ...state,
      fundamentals_curve: action.fundamentals_curve.data,
      loading: false,
    };
  }

  return state;
};
