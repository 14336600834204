import { dangerColor } from '../../material-dashboard-react';

const frrAnalysisStyle = {
    
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
        color: '#777',
        fontSize: '65%',
        fontWeight: '400',
        lineHeight: '1',
        },
    },
    container: {
        minHeight: '500px',
    },
    table: {
        width: '100%',
    },
    loadingContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
    },
    sizeS: {
        left: 380,
        top: '2rem',
        width: 'auto',
        height: 'auto',
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    block: {
        display: 'block',
    },
    relative: {
        position: 'relative',
    },
    textLeft: {
        textAlign: 'left',
    },
    bgColor: {
        backgroundColor: '#fff',
    },
    fullWidth: {
        width: '100%',
    },
    sticky: {
        position: 'sticky',
        top: '5rem',
    },
    noMargin: {
        margin: 0,
    },
    marginTopXs: {
        marginTop: '0.2rem',
    },
    marginBottomXs: {
        marginBottom: '0.2rem',
    },
    marginBottom: {
        marginBottom: '1rem',
    },
    marginBottomL: {
        marginBottom: '2rem',
    },
    error: {
        color: dangerColor,
    },
};

export default frrAnalysisStyle;