import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import TextField from '@material-ui/core/TextField';
import LoginPage from "views/Login/Oops.jsx";
import Sessions from 'components/Backtesting/Sessions';
import { buildAPIRequest, security_fetch_params } from "../../actions/index";
import { getDefaultPark } from "../../actions/index";
import localForage from 'localforage';
import moment from 'moment';
import 'moment-timezone';
import {logout} from 'utils/auth';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  formLabel: {
    paddingTop: '20px',
    paddingRight: '10px'
  },
  textField: {
    margin: 0,
    width: '100%',
  },
  gridContainer: {
    width: '100%'
  },
};

const mapStateToProps = state => {
  return { 
    isLoggedIn: state.login.loggedIn,
    apiLabel: state.conn.label 
  }
};

const mapDispatchToProps = (dispatch) => ({
});

class Backtesting extends Component {
  constructor() {
    super();
    this.state = {
      name: 'New backtesting ' + moment().format('Y-MM-DD HH:mm:ss'),
      production_model: "forecast",
      price_model: 'stochastic',
      maximum_power: 200,
      price_time_offset: 15,
      max_afternoon_horizon: 24,
      ib_limits_calibration_period: 7,
      ib_limits_calibration_skip: 2,
      calc_offset: 30,
      trades_limit: 200,
      intraday_limit: 25,
      start_hour: moment().tz('Europe/Paris').add(1, 'hours').format('HH') + ':00',
      intraday_treatment: 'opt-use',
      delivery_date: moment().format('Y-MM-DD'),
      windpark: { name: 'AXPO DE', capacity: 0 }
    };
    this.handleChange = this.handleChange.bind(this);
    this.start = this.start.bind(this);

    this.build_parameters = this.build_parameters.bind(this);
    this.send_optimisation = this.send_backtesting.bind(this);
    this.get_windpark_data = this.get_windpark_data.bind(this);
    this.update_windpark = this.update_windpark.bind(this);
    this.get_sessions = this.get_sessions.bind(this);
  }


  build_parameters() {
    var json = {
      first_date: this.state.first_date,
      last_date: this.state.last_date,
      name: this.state.name,
      job_parameters: {
        afternoon_run: this.state.afternoon_run,
        alpha: 0.95,
        beta: 0,
        bidding_mode: 'single',
        calc_offset: this.state.calc_offset,
        date: this.state.delivery_date,
        dt: 1,
        enable_shorts: false,
        fix_rng_seed: 0,
        forecast_error_variance: null,
        french_intraday_hour: parseInt(this.state.start_hour.substring(0, 3)),
        ib_limits_calibration_period: this.state.ib_limits_calibration_period,
        ib_limits_calibration_skip: this.state.ib_limits_calibration_skip,
        intraday_limit: this.state.intraday_limit,
        intraday_treatment: this.state.intraday_treatment,
        market_model_days: 180,
        market_start_hour: 0,
        max_afternoon_horizon: this.state.max_afternoon_horizon,
        mode: this.state.production_model,
        n_da_am_wind_scenarios: 10,
        n_ib_price_scenarios: 100,
        n_lambdaD_scenarios: 100,
        n_MAvsMD_scenarios: 100,
        n_redc_da_am_wind_scenarios: 3,
        n_redc_ib_price_scenarios: 3,
        n_redc_lambdaD_scenarios: 4,
        n_redc_MAvsMD_scenarios: 4,
        n_redc_wind_scenarios: 3,
        n_wind_scenarios: 10,
        Pmax: this.state.maximum_power,
        price_mode: this.state.price_model,
        price_time_offset: this.state.price_time_offset,
        price_variances: null,
        production_model_days: 30,
        refit_market: true,
        refit_weather: true,
        time_span: 24,
        trades_limit: this.state.trades_limit,
        use_da_optimisation: false,
        use_predicated_value: false,
        use_volume_distribution: false
      }
    };
    return json;
  }


  update_sessions() {
    var that = this;
    const defPark = getDefaultPark();
    const [url, headers] = buildAPIRequest('/api/btsessions/' + defPark.id + '/1/100');
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then(function (response) {
        if (response.ok) {
          response.json().then(data => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            data.updated_at = moment().unix();
            localForage.setItem(`btsessions_${that.props.apiLabel}`, data, function (err) {
              // if err is non-null, we got an error
              if (err) {
                console.log(err);
              }
            });
            var jsonObj = { 'btsessions': data.data }

            var newstate = Object.assign({}, that.state, jsonObj);
            that.setState(newstate);
          })
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => { console.log(error) })
  }

  get_sessions() {
    var that = this;
    localForage.getItem(`btsessions_${that.props.apiLabel}`, function (err, value) {
      if (value != null) {

        if (value.updated_at < moment().unix() - 1000) {
          that.update_sessions();
        }
        var jsonObj = { 'btsessions': value.data }
        var newstate = Object.assign({}, that.state, jsonObj);
        that.setState(newstate);
      }
      else {
        that.update_sessions()
      }
    });
  }

  update_windpark() {
    var that = this;
    const defPark = getDefaultPark();
    const [url, headers] = buildAPIRequest('/api/windparks/' + defPark.id)
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then(function (response) {
        if (response.ok) {
          response.json().then(data => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            data.updated_at = moment().unix();
            localForage.setItem(`windpark_data_${that.props.apiLabel}`, data, function (err) {
              // if err is non-null, we got an error
              if (err) {
                console.log(err);
              }
            });
            if (!data.data) {
              //console.log("No windpark found")
              return
            }
            if (!data.data.optimization_job) {
              //console.log("No optimization job found")
              return
            }
            var jsonObj = {
              'windpark': data.data,
              afternoon_run: data.data.optimization_job.afternoon_run,
              production_model: data.data.optimization_job.mode,
              price_model: data.data.optimization_job.price_mode,
              maximum_power: data.data.optimization_job.Pmax,
              price_time_offset: data.data.optimization_job.price_time_offset,
              max_afternoon_horizon: data.data.optimization_job.max_afternoon_horizon,
              ib_limits_calibration_period: data.data.optimization_job.ib_limits_calibration_period,
              ib_limits_calibration_skip: data.data.optimization_job.ib_limits_calibration_skip,
              calc_offset: data.data.optimization_job.calc_offset,
              trades_limit: data.data.optimization_job.trades_limit,
              intraday_limit: data.data.optimization_job.intraday_limit,
              intraday_treatment: data.data.optimization_job.intraday_treatment
            }

            var newstate = Object.assign({}, that.state, jsonObj);
            that.setState(newstate);
          })
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => { console.log(error) })
  }

  get_windpark_data() {
    var that = this;
    localForage.getItem(`windpark_data_${that.props.apiLabel}`, function (err, value) {
      if (value != null) {

        if (value.updated_at < moment().unix() - 100000) {
          that.update_windpark();
        }
        if (!value.data) {
          console.log("No windpark found")
          return
        }
        if (!value.data.optimization_job) {
          //console.log("No optimization job found")
          return
        }
        var jsonObj = {
          'windpark': value.data,
          afternoon_run: value.data.optimization_job.afternoon_run,
          production_model: value.data.optimization_job.mode,
          price_model: value.data.optimization_job.price_mode,
          maximum_power: value.data.optimization_job.Pmax,
          price_time_offset: value.data.optimization_job.price_time_offset,
          max_afternoon_horizon: value.data.optimization_job.max_afternoon_horizon,
          ib_limits_calibration_period: value.data.optimization_job.ib_limits_calibration_period,
          ib_limits_calibration_skip: value.data.optimization_job.ib_limits_calibration_skip,
          calc_offset: value.data.optimization_job.calc_offset,
          trades_limit: value.data.optimization_job.trades_limit,
          intraday_limit: value.data.optimization_job.intraday_limit,
          intraday_treatment: value.data.optimization_job.intraday_treatment
        }
        var newstate = Object.assign({}, that.state, jsonObj);
        that.setState(newstate);
      }
      else {
        that.update_windpark()
      }
    });
  }


  componentDidMount() {
    this.get_windpark_data();
    this.get_sessions();
  }


  send_backtesting(parameters) {
    const defPark = getDefaultPark();
    const [url, headers] = buildAPIRequest('/api/windparks/' + defPark.id + '/start_backtesting');
    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(parameters), ...security_fetch_params
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(data => {
            //console.log(data);
            alertify.notify('Optimisation job submitted', 'success', 5)
          })
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => { console.log(error) })
  }

  start() {
    var parameters = this.build_parameters();
    this.send_optimisation(parameters)
  }

  handleChange(event) {
    var jsonObj = {};
    jsonObj[event.target.name] = event.target.value;
    var newstate = Object.assign({}, this.state, jsonObj);
    this.setState(newstate);
  }

  render() {
    const { classes } = this.props;
    const tableData = this.state.btsessions === undefined ? [] : this.state.btsessions.map(x => [x.id.toString(), x.name, x.first_date, x.last_date, '']);
    if (this.props?.isLoggedIn) {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card className={classes.marginBottom}>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Create New Backtesting Session</h4>
                  <p className={classes.cardCategoryWhite}>Parameters will take effect from next run</p>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={4}>
                    <Grid item xs={6} lg={3}>
                      <TextField
                        id="first_date"
                        label="First date"
                        type="date"
                        name='first_date'
                        value={this.state.first_date}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <TextField
                        id="last_date"
                        label="Last date"
                        type="date"
                        name='last_date'
                        value={this.state.last_date}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="name"
                        label="Name"
                        className={classes.textField}
                        value={this.state.name}
                        onChange={this.handleChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                </CardBody>
                <CardFooter>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Button color="primary" onClick={this.start}>Start</Button>
                    </Grid>
                  </Grid>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Existing Backtesting Session</h4>
                </CardHeader>
                <CardBody>
                  <Sessions
                    sessions={this.state.btsessions}
                    tableHead={['Name', 'First Date', 'Last Date', 'Actions']}
                    tableData={tableData}
                  />
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <LoginPage />)
    }
  }
}


const BacktestingConnect = connect(mapStateToProps, mapDispatchToProps)(Backtesting);
export default withStyles(styles)(BacktestingConnect);
