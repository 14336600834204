import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import store from "../configureStore";
import { get_plreport_data } from "../actions/index";
window.store = store;
window.get_plreport_data = get_plreport_data;


const indexRoutes = [{ path: "/", component: Dashboard}];

export default indexRoutes;
