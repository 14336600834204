import { RECEIVED_RTRADER_DATA } from "constants/action-types";

import { LOADING_RTRADER_DATA } from "constants/action-types";

import {LOADING_RTRADER_MARKETDEPTH} from "constants/action-types";

import {LOADING_RTRADER_MARKETTRADES} from "constants/action-types";

import { RECEIVED_RTRADER_MARKETDEPTH } from "constants/action-types";

import { RECEIVED_RTRADER_MARKETTRADES } from "constants/action-types";
import moment from 'moment';
import 'moment-timezone';

const initialState = {
  delivery_hours: [], //['2019-07-25 14:00:00', '2019-07-25 11:00:00'],
  orders: [],
  trades: [],
  marketdepth: {},
  markettrades: {},
  loading: true,
  marketdepth_loading: false,
  markettrades_loading: false,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_RTRADER_DATA) {
    return {
      ...state,
      loading: true,
    };
  } else if (action.type === RECEIVED_RTRADER_DATA) {
    let data = action.rtrader;
    if (data.delivery_hours) {
      for (let i = -100; i < (24 - moment.tz(moment(), 'Europe/Paris').hour()); i++) {
        let h = moment.tz(moment().startOf('hour'), 'UTC')
          .add(i, 'hours').format('ddd, DD MMM YYYY HH:mm:ss') + ' GMT';

        let found = false;
        for(let j = 0; j < data.delivery_hours.length; j++){
          if (data.delivery_hours[j] === h) {
            found = true;
            break;
           }
         }

        if (!found) {
          data.delivery_hours.unshift(h);
        }
      }

      if (moment.tz(moment(), 'Europe/Paris').hour() >= 15) {
        for (let i = 0; i < 24; i++) {
          let h = moment.tz(moment.tz(moment(), 'Europe/Paris')
            .startOf('day').add(1, 'days').add(i, 'hours'), 'UTC')
            .format('ddd, DD MMM YYYY HH:mm:ss') + ' GMT';

          let found = false;
          for (let j = 0; j < data.delivery_hours.length; j++) {
            if (data.delivery_hours[j] === h) {
              found = true;
              break;
            }
          }

          if (!found) {
            data.delivery_hours.unshift(h);
          }
        }
      }

      return {
        ...state,
        delivery_hours: data.delivery_hours,
        orders:[],
        trades: [],
        loading: false,
      };
    } else {
      if (data.orders || data.trades) {
        return {
          ...state,
          orders: data.orders,
          trades: data.trades,
          loading: false,
        };
      }

      return state;
    }
  } else if (action.type === LOADING_RTRADER_MARKETDEPTH) {
    return {
      ...state,
      marketdepth_loading: true,
    };
  } else if (action.type === LOADING_RTRADER_MARKETTRADES) {
    return {
      ...state,
      markettrades_loading: true,
    };
  } else if (action.type === RECEIVED_RTRADER_MARKETDEPTH) {
    const { rtrader } = action;
    const { marketdepth } = state;
    marketdepth[rtrader.delivery] = rtrader;

    return {
      ...state,
      marketdepth,
      marketdepth_loading: false,
    };
  } else if (action.type === RECEIVED_RTRADER_MARKETTRADES) {
    const { rtrader } = action;
    const { markettrades } = state;
    markettrades[rtrader.delivery] = rtrader;

    return {
      ...state,
      markettrades,
      markettrades_loading: false,
    };
  }

  return state;
};
