import {
  RECEIVED_PNL_ANALYSIS_DATA,
  LOADING_PNL_ANALYSIS_DATA,
  PRELOADING_PNL_ANALYSIS_DATA,
  PRELOADED_PNL_ANALYSIS_DATA,
  LOADING_PARKS_DATA,
  RECEIVED_PNL_ANALYS_PARKS_DATA,
  ERROR_PRELOADING_PNL_ANALYSIS_DATA,
  RECEIVED_TOP_STRATEGIES_DATA
} from 'constants/action-types';

const initialState = {
  loading: false,
  preloading: false,
  strategiesData: [],
  data: [],
  parks: [],
  // counter to get the number of all preloading periods.
  // It's needed to change "preloading" state only when all requests completed.
  preloadCount: 0,
  error: null,
  filter: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
  case LOADING_PNL_ANALYSIS_DATA:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case PRELOADING_PNL_ANALYSIS_DATA:
    return {
      ...state,
      preloadCount: state.preloadCount + 1,
      preloading: true,
    };
  case PRELOADED_PNL_ANALYSIS_DATA:
    const preloadCount = state.preloadCount - 1;
    return {
      ...state,
      preloadCount,
      preloading: !(preloadCount === 0),
      fullData: action.fullData,
    };
  case ERROR_PRELOADING_PNL_ANALYSIS_DATA:
    return {
      ...state,
      error: action.error,
      preloadCount: 0,
      preloading: false,
    };
  case RECEIVED_PNL_ANALYSIS_DATA:
    return {
      ...state,
      data: action.data,
      loading: false,
    };
  case RECEIVED_TOP_STRATEGIES_DATA:
    return {
      ...state,
      strategiesData: action.data,
      loading: false,
    }
  case LOADING_PARKS_DATA:
    return {
      ...state,
      loading: true,
    };
  case RECEIVED_PNL_ANALYS_PARKS_DATA:
    return {
      ...state,
      parks: action.data.parks.data,
      filter: action.data.filter,
      loading: false,
    };
  default:
    return state;
  }
};
