import Icon from '@material-ui/core/Icon';
import withStyles from '@material-ui/core/styles/withStyles';
import LocalOffer from '@material-ui/icons/LocalOffer';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getMarketData } from 'utils/getDataMethods';

import { buildAPIRequest, security_fetch_params } from 'actions/index';
import { logout } from 'utils/auth';
import moment from 'moment';
import localForage from 'localforage';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  overflow: 'scroll',
  height: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DashboardCard = ({
  classes,
  title,
  // data,
  updatesNotAvailable,
  variable,
  check,
  market,
  apiLabel,
  markets,
  type,
  varType,
}) => {
  const INTERVAL = 3600;

  const [missDataTimeState, setMissDataTimeState] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dataVar, setDataVar] = useState({});
  const [data, setData] = useState({
    color: 'gray',
    l24: 'N/A',
    lu: null,
    msg: 'Waiting for API response ...',
    missDataTime: [],
    issueMsg: '',
  });

  const getCardData = async () => {
    const localForgeVarString = `datadashboard_${market}_${variable}_${apiLabel}`;
    const data = await localForage.getItem(localForgeVarString);
    let jsonParam = '';
    if (varType === 'specific') {
      jsonParam = `{"specific_audit_selectors":{"${variable}":["${market}"]}}`;
    } else if (varType === 'rte') {
      jsonParam = `{"general_audit_selectors": {"${variable}": {"RTE/RTE Imbalance": ["${market}"]}}}`;
    } else if (varType === 'general') {
      jsonParam = `{"general_audit_selectors":{"${variable}":null}}`;
    }
    if (!data || data.updated_at < moment().unix() - INTERVAL) {
      const [url, headers] = buildAPIRequest(
        `/api/reports/datadashboard?market=${market}&curve=${jsonParam}`
      );
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers,
          ...security_fetch_params,
        });
        if (response.status === 401) {
          logout();
          return;
        } else if (!response.ok) {
          console.log(`An error has occurred: ${response.status}`);
        } else if (response.ok) {
          const resJson = await response.json();
          if (resJson.error) {
            alertify.error("Response error");
            console.log(resJson.error);
            return
          }
          resJson.updated_at = moment().unix();
          if (resJson.ainfo && Object.keys(resJson?.ainfo).length) {
            await localForage.setItem(localForgeVarString, resJson, (err) => {
              err && console.log(err);
            });
            setDataVar(resJson);
          }
        }
      } catch (error) {
        console.error('Error:', error);
        return null;
      }
    } else {
      setDataVar(data);
    }
  };

  const getCheckSum = async (marketId) => {
    // get checksum variables
    const localforgeVarChacksumVar = `checksum_var_${marketId}_${apiLabel}`;
    const dataVariables = await localForage.getItem(localforgeVarChacksumVar);
    let checksumVariables;
    if (
      !dataVariables ||
      dataVariables.updated_at < moment().unix() - INTERVAL
    ) {
      const [url, headers] = buildAPIRequest(
        `/api/markets/${marketId}/checksum_variables`
      );
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
        ...security_fetch_params,
      });
      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        console.log(`An error has occurred: ${response.status}`);
      }
      const resJson = await response.json();
      if (resJson.error) {
        alertify.error("Response error");
        console.log(resJson.error);
        return
      }
      resJson.updated_at = moment().unix();
      await localForage.setItem(localforgeVarChacksumVar, resJson, (err) => {
        err && console.log(err);
      });
      checksumVariables = [...resJson];
    } else {
      checksumVariables = [...dataVariables];
    }
    if (!checksumVariables) return;

    //get checksum
    const checksumDate = moment().add(-3, 'days').format('YYYY-MM-DD');
    const localforgeVarChacksum = `checksum_${marketId}_${checksumDate}_${apiLabel}`;
    const dataChecksum = await localForage.getItem(localforgeVarChacksum);
    let checksumData;
    if (!dataChecksum || dataChecksum.updated_at < moment().unix() - INTERVAL) {
      const [url, headers] = buildAPIRequest(
        `/api/markets/${marketId}/checksum`
      );
      const checkSumBody = {
        date: checksumDate,
        variables: checksumVariables,
        compare: true,
      };
      let checksumResp;
      try {
        checksumResp = await fetch(url, {
          method: 'POST',
          headers: headers,
          ...security_fetch_params,
          body: JSON.stringify(checkSumBody),
        });
      } catch (e) {
        console.log(e);
        if (e.toString().includes('NetworkError')) {
          alertify.error(
            'Data cannot be checked now, please try again later',
            5
          );
          return;
        }
      }

      if (checksumResp?.status === 401) {
        logout();
        return;
      } else if (!checksumResp?.ok) {
        console.log(`An error has occurred: ${checksumResp.status}`);
      }
      const checksumRespJson = await checksumResp?.json();
      checksumRespJson.updated_at = moment().unix();
      if (checksumRespJson.error) {
        alertify.error("Response error");
        console.log(checksumRespJson.error);
        return
      }

      localForage.setItem(localforgeVarChacksum, checksumRespJson, (err) => {
        // if err is non-null, we got an error
        if (err) {
          console.log(err);
        }
      });
      checksumData = JSON.parse(JSON.stringify(checksumRespJson));
    } else {
      checksumData = JSON.parse(JSON.stringify(dataChecksum));
    }
    if (!checksumData) return;

    const checkSumColor = 'success';
    const noDataVariables = [];
    const notEquelVariables = [];
    const equelVariables = [];
    checksumData.checksums.forEach((x) => {
      if (x.compare === 'not_exist') {
        noDataVariables.push(x.variable);
      } else if (x.compare === 'not_equal') {
        notEquelVariables.push(x.variable);
      } else if (x.compare === 'equal') {
        equelVariables.push(x.variable);
      }
    });
    checksumData.checksums.forEach((x) => {});
    let issueMessage = noDataVariables.length
      ? `Data was missing for the previous day, checks cannot be done today: ${noDataVariables.join(
          ', '
        )}. `
      : '';
    issueMessage += notEquelVariables.length
      ? `Checksums are not equal, data has changed over the past 2 days: ${notEquelVariables.join(
          ', '
        )}. `
      : '';
    issueMessage += equelVariables.length
      ? `Variables that checksums equel with current checksum: ${equelVariables.join(
          ', '
        )}. `
      : '';
    setData({
      ...data,
      issueMsg: issueMessage,
      color: checkSumColor,
      lu: moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const getVarData = async (varname, count) => {
    const marketsObj = {};
    markets.forEach((x) => {
      marketsObj[x.country_iso3166a2] = x;
    });
    const marketId = marketsObj[market].id;
    const from = moment().tz('UTC').subtract(48, 'hours');
    const to = moment().tz('UTC');

    const data = await getMarketData(marketId, varname, from, to, apiLabel);
    const timeStampsShoudBe = Array.from(Array(count).keys()).map(
      (x) =>
        data.data[0][data.data[0].length - 1][0] - ((x * 3600000) / count) * 24
    );
    const objData = {};
    data.data[0].forEach((x) => {
      objData[x[0]] = x[1];
    });

    const missDataTime = timeStampsShoudBe
      .filter((x) => !Object.keys(objData).includes(x.toString()))
      .map((x) => moment(x).tz('UTC').format('YYYY-MM-DD HH:mm:ss'));
    return missDataTime;
  };

  useEffect(() => {
    if (type !== 'checksum') {
      getCardData();
    } else {
      const marketId = markets?.find(
        (x) => x.country_iso3166a2 === market
      )?.id;
      marketId && getCheckSum(marketId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let color = 'gray';
    let msg = 'Waiting for API response ...';
    let l24 = null;
    let lu = 'N/A';
    let missDataTime = [];
    if (dataVar && dataVar.ainfo) {
      const dataVariable = dataVar.ainfo[variable][market];

      try {
        const key = variable;
        const ainfo = dataVariable;
        let count = parseInt(ainfo.coverage.count);
        const hmod24 = count % 24;
        const last24hours_complement = hmod24 === 0 ? 0 : 24 - hmod24;

        if (last24hours_complement === 0) {
          if (ainfo.freshness.score_source === 1.0) {
            color = 'success';
            msg = 'Data is up to date';
          } else {
            color = 'warning';
            msg = 'Updates are late';
          }
        } else {
          color = 'danger';
          if (check)
            missDataTime = getVarData(key, count + last24hours_complement);
          msg = 'Problem with recent updates';
        }

        l24 = `${count}/${count + last24hours_complement}`;
        // expect %Y-%m-%dT%H:%M:%S
        lu = ainfo.freshness.source_ts.replace('T', ' ');
      } catch (e) {
        alertify.error(`API error: ${e}`, 5);
        console.log(e);
      }
      setData({
        color,
        msg,
        l24,
        lu,
        missDataTime,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVar]);

  const handleOpen = () => {
    if (
      !Array.isArray(missDataTime) ||
      data.color === 'warning' ||
      (data.color === 'success' && data.issueMsg)
    ) {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (!Array.isArray(missDataTime) && missDataTime !== undefined) {
      missDataTime.then((res) => {
        setMissDataTimeState(res);
      });
    }
  });
  const { color, l24, lu, msg, missDataTime } = data;
  return (
    <div>
      <Card onClick={handleOpen}>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon>info_outline</Icon>
          </CardIcon>
          <p className={classes.cardCategoryHead}>{title}</p>
          <p className={classes.cardCategory}>
            Last 24 hours updates: {updatesNotAvailable ? 'N/A' : l24}
          </p>
          <h4 className={classes.cardTitle}>{lu} UTC</h4>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <LocalOffer />
            {msg}
          </div>
        </CardFooter>
      </Card>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Problems
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {missDataTimeState.length ? (
                `Some data points are missing: ${missDataTimeState.join(', ')}`
              ) : data.issueMsg ? (
                data.issueMsg
              ) : color === 'warning' ? (
                'Data is more delayed than expected'
              ) : color === 'danger' &&
                !missDataTimeState.length &&
                !data.issueMsg ? (
                'Data could have some issues, but the cause is unclear'
              ) : (
                <></>
              )}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

DashboardCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  updatesNotAvailable: PropTypes.bool,
};

DashboardCard.defaultProps = {
  updatesNotAvailable: false,
};

export default withStyles(dashboardStyle)(DashboardCard);
