import { LOGGED_IN, UINFO, TARGETS } from "constants/action-types";

const loggedIn = JSON.parse(sessionStorage.getItem('loggedIn'));

const initialState = {
  loggedIn: loggedIn ?? false,
  uinfo: null,
  targets: null,
};

export default (state = initialState, action) => {
  if (action.type === LOGGED_IN) {
    return {
      ...state,
      loggedIn: action.payload,
    };
  } else if (action.type === UINFO) {
    return {
      ...state,
      uinfo: action.payload,
    };
  }else if (action.type === TARGETS) {
    return {
      ...state,
      targets: action.payload,
    };
  }

  return state;
};
